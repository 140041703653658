/**
 *
 * ResourceItem
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import _classNames from 'classnames';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { setLeavingModal } from 'containers/Main/actions';
import {
  makeSelectClientDetails,
  makeSelectIsResourceRatingEnabled,
} from 'containers/Main/selectors';
import Rating from 'components/Rating';
import TooltipBadge from 'components/TooltipBadge';
import useAlgoliaLocale from 'components/useAlgoliaLocale';
import useSiteCopySelector from 'components/useSiteCopySelector';
import { analyzeClientResource } from 'utils/analyzeClientResource';
import { getEmbedlyImageUrl } from 'utils/embedlyUtils';
import { getAlgoliaLocalizedField } from 'utils/localeUtils';
import { fallbackImage } from 'utils/stringUtils';
import LinkItem from './LinkItem';
import { getExclusiveBadge } from '../utils';
import { NewBadge } from '../../NavBarMenu/components/NewBadge';
import useSiteConfigSelector from '../../useSiteConfigSelector';

const useStyles = makeStyles({
  link: {
    display: 'block',
    textDecoration: 'none',
    position: 'relative',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    textAlign: 'left',
  },
  image: {
    height: 77,
    width: 77,
    marginRight: 12,
    objectFit: 'contain',
  },
  type: {
    fontSize: 11,
    lineHeight: '14px',
    display: '-webkit-box',
    '-webkit-line-clamp': '1',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: 14 /* Fallback for non-webkit */,
  },
  typeWithNewLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    height: 20,
    '& span': {
      marginBottom: 3,
    },
  },
  title: {
    fontSize: 14,
    lineHeight: '18px',
    fontFamily: 'MadaBold',
    fontWeight: 700,
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: 36 /* Fallback for non-webkit */,
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 89px)',
    // height: 72,
  },
  userRatingLabel: {
    fontSize: 12,
    lineHeight: '15px',
  },
  placeholder: {
    width: '100%',
    height: 27,
  },
  imageWrapper: {
    position: 'relative',
  },
  audioSeriesImage: {
    border: '1px solid #E0E0E0',
  },
  newBadge: {
    borderRadius: 2,
    minHeight: 12,
    minWidth: 23,
    padding: 0,
  },
});

const stateSelector = createStructuredSelector({
  clientDetails: makeSelectClientDetails(),
  isResourceRatingEnabled: makeSelectIsResourceRatingEnabled(),
});

const ResourceItem = ({
  data,
  onClick,
  variantELocale,
  siteCopyInOtherLang,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [siteCopy, resourceItemSiteCopy] = useSiteCopySelector([
    'search',
    'resource-item-page',
  ]);
  const [siteConfig] = useSiteConfigSelector(['Features']);
  const { clientDetails, isResourceRatingEnabled } = useSelector(stateSelector);
  const locale = useAlgoliaLocale();

  if (_isEmpty(data)) return null;

  const {
    type,
    url,
    nameTitle,
    resourcesImages,
    imageUrl: primaryImageUrl,
    userRating,
    audioOnly,
  } = data;

  const imageUrl = primaryImageUrl || resourcesImages?.[0];
  const showNewInAccountMenu = _get(
    siteConfig,
    'config.showNewInAccountMenu',
    false,
  );

  const {
    badge,
    badgeTitle,
    showClientBadge,
    hasCustomBadge,
  } = getExclusiveBadge(data, clientDetails);

  const isAudioSeries = type === 'Series' && audioOnly;
  const showNewLabel =
    isAudioSeries &&
    showNewInAccountMenu &&
    (type === 'Practices' || type === 'Courses');

  if (type === 'Links')
    return (
      <LinkItem data={data} onClick={onClick} variantELocale={variantELocale} />
    );

  const typeLabel = (isAudioSeries
    ? _get(resourceItemSiteCopy, [
        'pageCopy',
        'resourceCardTypeMapping',
        'Audio Series',
      ])
    : getAlgoliaLocalizedField(data, 'type', variantELocale || locale)
  ).toUpperCase();

  const handleClick = dispatchExitOverlay => {
    if (dispatchExitOverlay) {
      const { isInternal } = analyzeClientResource(data, clientDetails);
      if (isInternal) {
        history.push(url);
      } else {
        const finalUrl = url.startsWith('http') ? url : `https://${url}`;
        dispatch(
          setLeavingModal({
            visible: true,
            title: getAlgoliaLocalizedField(data, 'nameTitle', locale),
            url: finalUrl,
            organization: clientDetails.name,
            resourceType: 'Client Exclusive',
          }),
        );
      }
    }
    onClick({
      type,
      name: nameTitle,
      entryId: data.objectID,
    });
  };

  const content = () => (
    <div className={classes.wrapper}>
      <div className={classes.imageWrapper}>
        <img
          src={getEmbedlyImageUrl(imageUrl) || fallbackImage}
          alt={`${nameTitle} Resource`}
          className={_classNames(classes.image, {
            [classes.audioSeriesImage]: isAudioSeries,
          })}
          onError={e => {
            if (e.target.src !== imageUrl) {
              e.target.src = imageUrl;
            } else {
              e.target.src = fallbackImage;
            }
          }}
        />
        {showClientBadge && !!badge && (
          <TooltipBadge
            title={badgeTitle}
            image={badge}
            size={18}
            customBadge={hasCustomBadge}
            position="topRight"
          />
        )}
      </div>
      <div className={classes.textWrapper}>
        <Typography
          color="textPrimary"
          className={_classNames(classes.type, {
            [classes.typeWithNewLabel]: showNewLabel,
          })}
        >
          {typeLabel}
          {showNewLabel && <NewBadge className={classes.newBadge} />}
        </Typography>
        <Typography color="textPrimary" className={classes.title}>
          {getAlgoliaLocalizedField(
            data,
            'nameTitle',
            variantELocale || locale,
          )}
        </Typography>
        {[
          'People',
          'Organizations',
          'Blogs',
          'Services',
          'Series',
          'Practices',
          'Courses',
        ].includes(type) || userRating === 3.75 ? (
          <div className={classes.placeholder} />
        ) : (
          isResourceRatingEnabled && (
            <>
              <Typography
                color="textPrimary"
                className={classes.userRatingLabel}
              >
                {_get(
                  siteCopyInOtherLang || siteCopy,
                  'pageCopy.userRatingLabel',
                )}
              </Typography>
              <Rating name="User rating" value={userRating} isBig={false} />
            </>
          )
        )}
      </div>
    </div>
  );

  if (type === 'Client Exclusive')
    return (
      <ButtonBase
        classes={{ root: classes.button }}
        disableRipple
        onClick={() => handleClick(true)}
      >
        {content()}
      </ButtonBase>
    );

  const finalUrl = `${url}?lang=${variantELocale || locale}`;

  return (
    <Link to={finalUrl} className={classes.link} onClick={handleClick}>
      {content()}
    </Link>
  );
};

ResourceItem.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
};

export default ResourceItem;
