/*
 *
 * Resources actions
 *
 */
import { createAction } from 'redux-actions';
import {
  PROCESSING,
  ALGOLIA_PROCESSING,
  ALGOLIA_NEXT_PAGE_PROCESSING,
  ALGOLIA_TOPICS_PROCESSING,
  GET_SAVED_RESOURCES,
  GET_SAVED_RESOURCES_RESULT,
  GET_ALGOLIA_RESOURCES,
  GET_ALGOLIA_RESOURCES_RESULT,
  GET_ALGOLIA_TOPIC_FACETS,
  GET_ALGOLIA_TOPIC_FACETS_RESULT,
  SAVE_RESOURCE,
  SAVE_RESOURCE_RESULT,
  RATE_RESOURCE,
  RATE_RESOURCE_RESULT,
  REVIEW_RESOURCE,
  REVIEW_RESOURCE_RESULT,
  GET_RESOURCE_REVIEW,
  GET_RESOURCE_REVIEW_RESULT,
  VOTE_HELPFUL_RESOURCE,
  VOTE_HELPFUL_RESOURCE_RESULT,
  GET_HELPFUL_RATINGS,
  GET_HELPFUL_RATINGS_RESULTS,
  GET_RECOMMENDED_RESOURCES,
  GET_RECOMMENDED_RESOURCES_RESULT,
  GET_USER_REVIEWS,
  GET_USER_REVIEWS_SUCCESS,
  FETCHING_USER_REVIEWS,
  SUBMITTING_FEEDBACK,
  GET_USER_HISTORY,
  GET_USER_HISTORY_RESULT,
  UPDATE_RESOURCE_PROGRESS,
  UPDATE_RESOURCE_PROGRESS_SUCCESS,
  UPDATE_RESOURCE_PROGRESS_ERROR,
  GET_RESOURCE_PROGRESS,
  GET_RESOURCE_PROGRESS_SUCCESS,
  GET_RESOURCE_PROGRESS_ERROR,
} from './constants';

export const processing = createAction(PROCESSING);
export const algoliaProcessing = createAction(ALGOLIA_PROCESSING);
export const algoliaNextPageProcessing = createAction(
  ALGOLIA_NEXT_PAGE_PROCESSING,
);
export const algoliaTopicsProcessing = createAction(ALGOLIA_TOPICS_PROCESSING);
export const getSavedResources = createAction(GET_SAVED_RESOURCES);
export const getSavedResourcesResult = createAction(GET_SAVED_RESOURCES_RESULT);
export const getAlgoliaResources = createAction(GET_ALGOLIA_RESOURCES);
export const getAlgoliaResourcesResult = createAction(
  GET_ALGOLIA_RESOURCES_RESULT,
);
export const getAlgoliaTopicFacets = createAction(GET_ALGOLIA_TOPIC_FACETS);
export const getAlgoliaTopicFacetsResult = createAction(
  GET_ALGOLIA_TOPIC_FACETS_RESULT,
);
export const saveResource = createAction(SAVE_RESOURCE);
export const saveResourceResult = createAction(SAVE_RESOURCE_RESULT);
export const rateResource = createAction(RATE_RESOURCE);
export const rateResourceResult = createAction(RATE_RESOURCE_RESULT);
export const reviewResource = createAction(REVIEW_RESOURCE);
export const reviewResourceResult = createAction(REVIEW_RESOURCE_RESULT);
export const getResourceReview = createAction(GET_RESOURCE_REVIEW);
export const getResourceReviewResult = createAction(GET_RESOURCE_REVIEW_RESULT);
export const voteHelpfulResource = createAction(VOTE_HELPFUL_RESOURCE);
export const voteHelpfulResourceResult = createAction(
  VOTE_HELPFUL_RESOURCE_RESULT,
);
export const getHelpfulRatings = createAction(GET_HELPFUL_RATINGS);
export const getHelpfulRatingsResult = createAction(
  GET_HELPFUL_RATINGS_RESULTS,
);
export const getRecommendedResources = createAction(GET_RECOMMENDED_RESOURCES);
export const getRecommendedResourcesResult = createAction(
  GET_RECOMMENDED_RESOURCES_RESULT,
);
export const getUserReviews = createAction(GET_USER_REVIEWS);
export const getUserReviewsSuccess = createAction(GET_USER_REVIEWS_SUCCESS);
export const getUserHistory = createAction(GET_USER_HISTORY);
export const getUserHistoryResult = createAction(GET_USER_HISTORY_RESULT);
export const fetchingUserReviews = createAction(FETCHING_USER_REVIEWS);
export const submittingFeedback = createAction(SUBMITTING_FEEDBACK);
export const updateResourceProgress = createAction(UPDATE_RESOURCE_PROGRESS);
export const updateResourceProgressSuccess = createAction(
  UPDATE_RESOURCE_PROGRESS_SUCCESS,
);
export const updateResourceProgressError = createAction(
  UPDATE_RESOURCE_PROGRESS_ERROR,
);
export const getResourcesProgress = createAction(GET_RESOURCE_PROGRESS);
export const getResourcesProgressSuccess = createAction(
  GET_RESOURCE_PROGRESS_SUCCESS,
);
export const getResourcesProgressError = createAction(
  GET_RESOURCE_PROGRESS_ERROR,
);
