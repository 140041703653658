/**
 *
 * SearchBar
 *
 */

import React, { useEffect, useReducer, useRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import CancelIcon from '@material-ui/icons/Cancel';
import algolia from 'utils/algoliaService';
import { useSelector } from 'react-redux';
import MixPanel from 'utils/mixpanelService';
import _classNames from 'classnames';
import { makeSelectProfileFilters } from 'containers/Auth/selectors';
import {
  makeSelectAudienceTagsRelations,
  makeSelectClientDetails,
  makeSelectSearchConfigInOtherLang,
} from 'containers/Main/selectors';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _partition from 'lodash/partition';
import IconButton from '@material-ui/core/IconButton';
import EmbedSearchIcon from 'components/BackHeader/images/search.svg';
import {
  getClientAudienceFilters,
  filterClientExclusiveResources,
} from 'containers/Resources/utils';
import useAlgoliaLocale from 'components/useAlgoliaLocale';
import useSiteCopySelector from 'components/useSiteCopySelector';
import Button from '@material-ui/core/Button';
import { DEFAULT_PRESENTATION } from 'utils/constants';
import useSiteConfigSelector from 'components/useSiteConfigSelector';
import { getTranslatedQuery } from 'utils/translationService';
import { getClientArticleSettings } from 'utils/clientSettings';
import Overlay from './components/Overlay';
import SearchIcon from './images/white-search.svg';
import CloseIcon from './images/close-search.svg';
import {
  buildSearchAlgoliaQueries,
  getFiltersString,
  addInternalTyping,
  sortSearchPreviewResources,
} from './utils';
import useQueryParams from './hooks/useQueryParams';

const useStyles = makeStyles(theme => ({
  searchBox: {
    position: 'relative',
    background: '#ffffff',
    color: '#000',
    height: ({ mobile }) => (mobile ? 35 : 45),
    borderRadius: 3,
    border: `1px solid #E3E3E3`,
  },
  flatBox: {
    border: 'none',
    borderBottom: '1px solid #E3E3E3',
    borderRadius: 0,
    marginBottom: 0,
  },
  cancelIcon: {
    position: 'absolute',
    height: '100%',
    right: 62,
    top: 0,
    color: '#D6D6D6',
    width: 16,
    borderRadius: 0,
    cursor: 'pointer',
    '& svg': {
      scale: '0.66',
    },
    '& path': {
      pointerEvents: 'none',
    },
  },
  iconContainer: {
    position: 'absolute',
    height: '100%',
    right: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    background: theme.palette.primary.main,
    width: ({ mobile }) => (mobile ? 43 : 54),
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
  },
  searchBtn: {
    maxHeight: ({ mobile }) => (mobile ? 35 : undefined),
    minWidth: ({ mobile }) => (mobile ? 43 : 54),
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
  },
  searchIcon: {
    height: ({ mobile }) => (mobile ? 24 : undefined),
    width: ({ mobile }) => (mobile ? 24 : undefined),
  },
  progress: {
    height: '20px !important',
    width: '20px !important',
    color: 'white',
  },
  input: {
    flex: '1',
    width: '100%',
    height: '100%',
    paddingLeft: 16,
    paddingRight: 82,
    fontWeight: 300,
    fontSize: ({ mobile }) => (mobile ? '14px !important' : '16px !important'),
    cursor: 'text',
    border: 'none',
    borderRadius: 4,
    '-webkit-appearance': 'none',
    fontFamily: 'MadaRegular',
    color: '#4B4B4B',
    backgroundColor: 'transparent',
  },
  embedSearch: {
    fontSize: 16,
    background: 'transparent',
    borderBottom: 'none',
    height: 36,
    '& img': {
      marginLeft: 10,
      height: 16,
      width: 19,
    },
    '&:hover': {
      background: 'none',
    },
    '& input': {
      color: '#848484',
      fontSize: '14px !important',
      minWidth: 0,
      paddingRight: 20,
    },
  },
  embedIconContainer: {
    background: 'transparent',
    width: 35,
    ' & button:hover': {
      background: 'none',
    },
  },
  embedInput: {
    paddingRight: 37,
    paddingLeft: 0,
    minWidth: 200,
  },
}));

const fetchSuggestions = async ({
  query,
  filters,
  clientDetails,
  excludeResourceTypes,
  profileFilters,
  locale = 'en-US',
}) => {
  if (query) {
    const finalQuery =
      locale !== 'en-US' ? await getTranslatedQuery(query, locale) : query;
    try {
      const numWords = query.slice(' ').length;
      const minProximity = numWords < 4 ? 1 : 3;

      const optionalFilters = [];
      if (!_isEmpty(clientDetails)) {
        optionalFilters.push(`clients:${clientDetails.shortName}<score=2>`);
        if (!_isEmpty(_get(clientDetails, 'clientGroup'))) {
          optionalFilters.push(
            `clients:${_get(clientDetails, 'clientGroup.shortName')}<score=1>`,
          );
        }
      }

      const requiredTagsFilters = _isEmpty(profileFilters.insuranceTags)
        ? ' AND requiredTags:"none"'
        : ` AND (requiredTags:"none" OR ${profileFilters.insuranceTags
            .map(tag => `requiredTags:"${tag.id}"`)
            .join(' OR ')})`;

      const excludedTagsFilters = _isEmpty(profileFilters.tags)
        ? ''
        : ` AND ${profileFilters.tags
            .map(tag => `NOT excludedTags:"${tag.id}"`)
            .join(' AND ')}`;

      let stateFilters = '';
      if (
        !_isEmpty(_get(profileFilters, 'state')) &&
        profileFilters.state !== 'all'
      ) {
        stateFilters = ` AND (state:"all" OR state:"${profileFilters.state}")`;
      }

      const excludedTopics = _get(
        clientDetails,
        'excludeTopicCollection.items',
        [],
      ).map(topic => topic.sys.id);
      const clientAudienceQuery = getClientAudienceFilters(
        profileFilters,
        'audience',
      );

      const { alwaysShowInformationalLink } = getClientArticleSettings(
        clientDetails,
      );
      const queries = buildSearchAlgoliaQueries({
        query: finalQuery,
        filters,
        optionalFilters,
        excludeResourceTypes,
        excludedTopics,
        audienceFilters: clientAudienceQuery,
        minProximity,
        requiredTagsFilters,
        excludedTagsFilters,
        stateFilters,
        alwaysShowInformationalLink,
      });

      const content = await algolia.multipleQueries(queries);

      const topics = excludeResourceTypes.includes('Topics')
        ? []
        : content.results[0].hits;
      const assessments = excludeResourceTypes.includes('Assessments')
        ? []
        : filterClientExclusiveResources({
            resources: content.results[1].hits,
            clientShortName: clientDetails.shortName,
            clientGroupShortName: _get(clientDetails, 'clientGroup.shortName'),
          });
      const blogs = excludeResourceTypes.includes('Insights')
        ? []
        : content.results[2].hits;
      const series = content.results[4] ? content.results[4].hits : [];
      const remainingResources = content.results[3].hits;
      const courses = remainingResources.filter(
        item => item.type === 'Courses',
      );
      const practices = remainingResources.filter(
        item => item.type === 'Practices',
      );

      const otherResources = remainingResources.filter(
        item => item.type !== 'Courses' && item.type !== 'Practices',
      );

      const [clientRemainingResources, publicRemainingResources] = _partition(
        otherResources,
        item => _get(item, 'clients', []).includes(clientDetails.shortName),
      );

      const resources = [
        ...courses.map(resource => addInternalTyping(resource, 'courses')),
        ...clientRemainingResources.map(resource =>
          addInternalTyping(resource, 'clientResources'),
        ),
        ...practices.map(resource => addInternalTyping(resource, 'practices')),
        ...series.map(resource => addInternalTyping(resource, 'series')),
        ...blogs.map(resource => addInternalTyping(resource, 'blogs')),
        ...publicRemainingResources.map(resource =>
          addInternalTyping(resource, 'publicResources'),
        ),
      ].slice(0, 18);

      // Group the resource according to internal typing
      const searchResourcesPreviewOrder = _get(
        clientDetails,
        'metadata.searchResourcesPreviewOrder',
        {},
      );

      const sortedResourceArray = sortSearchPreviewResources(
        resources,
        searchResourcesPreviewOrder,
      );

      const crisisFlag = !_isEmpty(
        content.results[0]?.userData?.find(
          element => Object.keys(element)?.[0] === 'crisisFlag',
        ),
      );

      const suggestions = {
        topics,
        assessments,
        resources: sortedResourceArray,
        crisisFlag,
      };

      return suggestions;
    } catch (err) {
      return {};
    }
  }
  return {};
};

const INITIAL_STATE = {
  processing: false,
  query: '',
  data: {},
  focused: false,
  offsetTop: 0,
};

const ON_QUERY_CHANGE = 'on-query-change';
const ON_CHANGE = 'on-change';
const ON_ENTER_PRESSED = 'on-enter-pressed';
const ON_FETCH_END = 'on-fetch-end';
const ON_CLICK_INSIDE = 'on-click-inside';
const ON_CLICK_AWAY = 'on-click-away';
const RESET = 'reset';

const reducer = (state, action) => {
  switch (action.type) {
    case ON_CHANGE:
      return {
        ...state,
        focused: true,
        processing: true,
        query: action.payload,
      };
    case ON_ENTER_PRESSED:
      return {
        ...state,
        data: {},
        focused: false,
        processing: false,
      };
    case ON_FETCH_END:
      return {
        ...state,
        data: action.payload,
        processing: false,
      };
    case ON_CLICK_INSIDE:
      return {
        ...state,
        focused: true,
        offsetTop: action.payload,
      };
    case ON_CLICK_AWAY:
      return {
        ...state,
        focused: false,
      };
    case ON_QUERY_CHANGE:
      return {
        ...state,
        query: action.payload,
      };

    case RESET:
      return INITIAL_STATE;
    default:
      throw new Error();
  }
};

const SearchBar = ({
  close,
  isFlat,
  isEmbedded,
  isVariantE,
  callback,
  variantELocale,
  otherLanguage,
  mobile = false,
  id,
  section,
}) => {
  const history = useHistory();
  const querySearch = useQueryParams();
  const classes = useStyles({ mobile });
  const locale = useAlgoliaLocale();
  const containerRef = useRef(null);
  const inputRef = useRef(null);
  const searchButtonRef = useRef(null);
  const clearRef = useRef(null);
  const timeout = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [state, dispatch] = useReducer(reducer, {
    ...INITIAL_STATE,
    query: querySearch.get('query') || '',
  });
  const [siteConfig] = useSiteConfigSelector(['Features']);

  const profileFilters = useSelector(makeSelectProfileFilters());
  const clientDetails = useSelector(makeSelectClientDetails());
  const audienceTagsRelations = useSelector(makeSelectAudienceTagsRelations());
  const excludeResourceTypes =
    _get(clientDetails, 'excludeResourceTypes') || [];
  const filters = useMemo(() => {
    const filtersString = getFiltersString(clientDetails, siteConfig, locale);
    return filtersString;
  }, [clientDetails, locale]);

  const { data, focused, processing, query, offsetTop } = state;

  const [searchConfig] = useSiteCopySelector(['search']);

  const searchConfigInOtherLang = useSelector(
    makeSelectSearchConfigInOtherLang(),
  );

  const withTrackingData = eventData => ({
    ...(eventData || {}),
    ...(section ? { section } : {}),
  });

  const onSuggestionPillClick = text => {
    MixPanel.track(
      'User Search - Click Search Tag',
      withTrackingData({
        tag: text,
      }),
    );
    onChange(text);
  };

  const onResourceClick = clickData => {
    MixPanel.track(
      'User Search - Select Result',
      withTrackingData({
        searchTerm: query,
        ...clickData,
      }),
    );
    dispatch({ type: RESET });
  };

  const onLinkClick = linkType => {
    MixPanel.track(
      'User Search - Click Link',
      withTrackingData({
        searchTerm: query,
        type: linkType,
      }),
    );
    dispatch({ type: RESET });
  };

  const onFocus = event => {
    if (!anchorEl) setAnchorEl(event.currentTarget);
    let ot = 0;
    if (containerRef) {
      const viewportRect = containerRef.current.getBoundingClientRect();
      ot = viewportRect.top;
    }
    if (callback) callback(true);
    dispatch({ type: ON_CLICK_INSIDE, payload: ot });
    MixPanel.track('User Search - Focused', withTrackingData());
  };

  const onChange = e => {
    const queryValue = typeof e === 'string' ? e : _get(e, 'target.value', '');
    dispatch({ type: ON_CHANGE, payload: queryValue });

    clearTimeout(timeout.current);

    timeout.current = setTimeout(async () => {
      if (typeof e !== 'string')
        MixPanel.track(
          'User Search - Query',
          withTrackingData({
            searchTerm: queryValue,
          }),
        );

      const suggestions = await fetchSuggestions({
        query: queryValue,
        filters,
        clientDetails,
        excludeResourceTypes,
        profileFilters,
        locale: variantELocale || locale,
        audienceTagsRelations,
      });
      dispatch({ type: ON_FETCH_END, payload: suggestions });
    }, 300);
  };

  const onKeyDown = e => {
    if (query !== '' && e.key === 'Enter') {
      MixPanel.track(
        'User Search - Enter Pressed',
        withTrackingData({
          searchTerm: query,
        }),
      );
      if (e.target) e.target.blur();
      dispatch({ type: ON_ENTER_PRESSED });
      if (isFlat) {
        close();
      }
      if (variantELocale) {
        history.push(
          `/resources?query=${query}&presentation=${DEFAULT_PRESENTATION}&lang=${variantELocale}`,
        );
      } else {
        history.push(
          `/resources?query=${query}&presentation=${DEFAULT_PRESENTATION}`,
        );
      }
    }
  };

  const onClickAway = e => {
    if (callback) callback(false);
    if (e?.target?.id !== 'search-input' && e?.target?.id !== 'search-clear')
      dispatch({ type: ON_CLICK_AWAY });
  };

  const onClearClicked = () => {
    onChange('');

    MixPanel.track(
      'User Search - Clear Clicked',
      withTrackingData({
        searchTerm: query,
      }),
    );

    if (inputRef.current) inputRef.current.focus();
  };

  const onSearchClick = () => {
    MixPanel.track(
      'User Search - Search Clicked',
      withTrackingData({
        searchTerm: query,
      }),
    );
    if (query === '') {
      if (inputRef.current) inputRef.current.focus();
    } else {
      if (inputRef.current) inputRef.current.blur();
      dispatch({ type: ON_ENTER_PRESSED });
      if (variantELocale) {
        history.push(
          `/resources?query=${query}&presentation=${DEFAULT_PRESENTATION}&lang=${variantELocale}`,
        );
      } else {
        history.push(
          `/resources?query=${query}&presentation=${DEFAULT_PRESENTATION}`,
        );
      }
    }
  };

  const closeSearch = () => {
    close();
  };
  const showOverlay = isVariantE ? focused && !_isEmpty(data) : focused;

  useEffect(() => {
    const queryValue = querySearch.get('query');
    if (queryValue) dispatch({ type: ON_QUERY_CHANGE, payload: queryValue });
  }, [querySearch]);

  const isClientActive = _get(clientDetails, 'activeClient') ?? true;
  if (!isClientActive) return null;

  return (
    <div
      className={_classNames(classes.searchBox, {
        [classes.embedSearch]: isEmbedded,
        [classes.flatBox]: isFlat || isEmbedded,
      })}
      ref={containerRef}
    >
      <input
        aria-label="Search Resources"
        placeholder={
          isEmbedded
            ? 'CredibleMind Search'
            : _get(
                otherLanguage ? searchConfigInOtherLang : searchConfig,
                'callToAction',
                'Search topics, resources and more...',
              )
        }
        value={query}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        ref={inputRef}
        id={id}
        className={_classNames(classes.input, isEmbedded && classes.embedInput)}
        autoComplete="off"
      />
      {showOverlay && (
        <Overlay
          anchorEl={anchorEl}
          data={data}
          focused={focused}
          offsetTop={offsetTop}
          onChange={onChange}
          section={section}
          onClickAway={onClickAway}
          onLinkClick={onLinkClick}
          onResourceClick={onResourceClick}
          onSuggestionPillClick={onSuggestionPillClick}
          query={query}
          searchConfig={otherLanguage ? searchConfigInOtherLang : searchConfig}
          isEmbedded={isEmbedded}
          isVariantE={isVariantE}
          variantELocale={variantELocale}
          focusableElements={[inputRef, clearRef, searchButtonRef]}
          otherLanguage={otherLanguage}
        />
      )}
      {query !== '' && focused && (
        <IconButton
          onClick={onClearClicked}
          ref={clearRef}
          disableRipple
          className={classes.cancelIcon}
          aria-label="Clear"
          id="search-clear"
        >
          <CancelIcon />
        </IconButton>
      )}
      <div
        className={_classNames(
          classes.iconContainer,
          isEmbedded && classes.embedIconContainer,
        )}
      >
        {processing ? (
          <CircularProgress className={classes.progress} />
        ) : (
          <>
            {isFlat || isEmbedded ? (
              <IconButton
                onClick={closeSearch}
                disableRipple
                aria-label="Close"
              >
                <img
                  src={isEmbedded ? EmbedSearchIcon : CloseIcon}
                  alt="Close"
                  className={classes.close}
                />
              </IconButton>
            ) : (
              <Button
                color="primary"
                variant="contained"
                disableElevation
                onClick={onSearchClick}
                ref={searchButtonRef}
                tabIndex={0}
                className={classes.searchBtn}
              >
                <img
                  src={SearchIcon}
                  alt="Search Icon"
                  className={classes.searchIcon}
                />
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  callback: PropTypes.func,
  close: PropTypes.func,
  isEmbedded: PropTypes.bool,
  isFlat: PropTypes.bool,
  isVariantE: PropTypes.bool,
  otherLanguage: PropTypes.bool,
  variantELocale: PropTypes.string,
  mobile: PropTypes.bool,
  id: PropTypes.string,
};

SearchBar.defaultProps = {
  isVariantE: false,
  id: 'search-input',
};

export default SearchBar;
