import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ButtonBase,
  List,
  makeStyles,
  Modal,
  Typography,
  ListItem,
} from '@material-ui/core';
import _get from 'lodash/get';
import useWindowSize from 'components/useWindowSize';
import { setLanguage } from 'containers/Main/actions';
import { getLocalData, setLocalData } from 'utils/localDataStore';
import useSiteCopySelector from 'components/useSiteCopySelector';

export const LanguagePopupContainer = () => {
  const { width } = useWindowSize();
  const clientDetails = useSelector(state => state.main.clientDetails);
  const isEnabled = _get(clientDetails, 'metadata.mobileSingleLanguage', false);
  const isMobile = width <= 600;

  if (!isMobile || !isEnabled) {
    return null;
  }

  return <LanguagePopup />;
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#fff',
    padding: '40px 16px',

    '& h6': {
      marginBottom: theme.spacing(1),
    },
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    borderRadius: '8px',
    width: 248,
    border: '1px solid #E0E0E0',
    padding: '12px 10px',
    ...theme.typography.pRegular,
  },
}));

export const LanguagePopup = () => {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const clientDetails = useSelector(state => state.main.clientDetails);
  const clientLanguages = _get(clientDetails, 'languageCollection.items', []);
  const auth = useSelector(state => state.firebase.auth);
  const anonymousId = useSelector(state => state.auth.anonymousId);
  const prevAnonymousId = useRef(anonymousId);
  const [footerSiteCopy] = useSiteCopySelector(['footer']);

  useEffect(() => {
    if (!auth.isLoaded) {
      return;
    }

    setShow(!getLocalData('popup-language'));
  }, [auth]);

  useEffect(() => {
    // handle case when reset session clicked in clinic mode
    // see endSession in AtTheClinicButton
    if (prevAnonymousId.current !== anonymousId) {
      setShow(true);
      prevAnonymousId.current = anonymousId;
    }
  }, [anonymousId]);

  if (!show) {
    return null;
  }

  const setSelectedLanguage = shortcode => {
    dispatch(setLanguage(shortcode));
    setShow(false);
    setLocalData('popup-language', shortcode);
  };

  return (
    <Modal open className={classes.modal}>
      <div className={classes.container}>
        <Typography variant="h6" color="textPrimary">
          {_get(footerSiteCopy, 'pageCopy.selectLanguage')}
        </Typography>
        <List className={classes.list}>
          {clientLanguages.map(({ shortcode, title }) => (
            <ListItem
              component="li"
              key={shortcode}
              onClick={() => setSelectedLanguage(shortcode)}
            >
              <ButtonBase className={classes.button}>{title}</ButtonBase>
            </ListItem>
          ))}
        </List>
      </div>
    </Modal>
  );
};
