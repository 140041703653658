import React from 'react';
import { useLocation } from 'react-router-dom';
import Mixpanel from 'utils/mixpanelService';
import { makeStyles } from '@material-ui/core/styles';
import { isBot } from 'utils/stringUtils';
import LinkedInIcon from './images/Linkedin.svg';
import InstagramIcon from './images/Instagram.svg';
import TwitterIcon from './images/Twitter.svg';
import FacebookIcon from './images/Facebook.svg';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  icon: {
    marginLeft: 4,
    marginRight: 4,
    height: 18,
    outline: 'none',
    cursor: 'pointer',
  },
}));

const IconLinks = [
  {
    link: 'https://www.facebook.com/CredibleMind',
    icon: FacebookIcon,
    social: 'Facebook',
  },
  {
    link: 'https://instagram.com/crediblemind',
    icon: InstagramIcon,
    social: 'Instagram',
  },
  {
    link: 'https://twitter.com/crediblemind',
    icon: TwitterIcon,
    social: 'Twitter',
  },
  {
    link: 'https://linkedin.com/company/crediblemind',
    icon: LinkedInIcon,
    social: 'LinkedIn',
  },
];
function SocialLinks() {
  const location = useLocation();
  const classes = useStyles();
  const redirect = social => {
    const pageName = location.pathname.split('/')[1];
    const finalPageName = pageName ? `${pageName}Page` : 'homePage';
    Mixpanel.track('Social Link - Clicked', {
      exitPage: social,
      page: finalPageName,
    });
  };
  const bot = isBot();
  return (
    <div className={classes.root}>
      {IconLinks.map(({ link, icon, social }) => (
        <a
          rel="noopener noreferrer"
          href={link}
          target="_blank"
          aria-label={`Open ${social} in a new tab`}
          onClick={() => redirect(social)}
          key={link}
        >
          {!bot && (
            <img src={icon} alt="Social Links" className={classes.icon} />
          )}
          {bot && social}
        </a>
      ))}
    </div>
  );
}

export default SocialLinks;
