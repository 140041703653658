import React, { useCallback, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useModalFocusTrap from 'components/Hooks/useModalFocusTrap';
import { useSelector } from 'react-redux';
import { getAllSeries } from 'containers/Series/selectors';
import { getAllCourses } from 'containers/Course/selectors';
import { getAllPractices } from 'containers/Practices/selectors';
import LearningLabItem from './LearningLabItem';
import NavBarContext from '../../../navBarContext';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 16,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('600')]: {
      display: 'flex',
    },
  },
  leftContent: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 400,
    width: '100%',
  },
}));

function LearningLab({ onClose, onFocusNext }) {
  const { sendMixpanelEvent } = useContext(NavBarContext);
  const { refCallback } = useModalFocusTrap(onClose, onFocusNext);

  const courses = useSelector(getAllCourses);
  const practices = useSelector(getAllPractices);
  const series = useSelector(getAllSeries);

  const classes = useStyles();

  const clickHandler = useCallback(
    (name, element) => {
      sendMixpanelEvent('Menu Item - Clicked', {
        type: 'Learning Lab',
        name,
        element,
      });
    },
    [sendMixpanelEvent],
  );

  return (
    <div
      className={classes.root}
      ref={refCallback}
      role="menu"
      aria-label="Learning Lab Menu"
    >
      <div className={classes.leftContent}>
        {series.length > 0 && (
          <LearningLabItem
            type="series"
            iconType="Series"
            onClick={() => clickHandler('Series', 'text')}
          />
        )}
        {courses.length > 0 && (
          <LearningLabItem
            type="courses"
            iconType="Courses"
            onClick={() => clickHandler('Courses', 'text')}
          />
        )}
        {practices.length > 0 && (
          <LearningLabItem
            type="practices"
            iconType="Practices"
            onClick={() => clickHandler('Practices', 'text')}
          />
        )}
      </div>
    </div>
  );
}

export default LearningLab;
