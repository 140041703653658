/**
 *
 * Main.js
 *
 */

import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { Helmet } from 'react-helmet-async';
import qs from 'qs';
import { createStructuredSelector } from 'reselect';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _get from 'lodash/get';
import { ReactQueryDevtools } from 'react-query-devtools';
import Measure from 'react-measure';
import Hidden from '@material-ui/core/Hidden';
import { incrementSignUpCount } from 'containers/Auth/actions';
import { makeSelectAuth, makeSelectProfile } from 'containers/Auth/selectors';
import { getTakenSeries } from 'containers/Series/actions';
import { getTakenCourses } from 'containers/Course/actions';
import StickyHeader from 'components/StickyHeader';
import NavBar from 'components/NavBarMenu';
import Routes from 'components/Routes';
import withPageBranding from 'components/PageBranding';
import ErrorBoundary from 'components/ErrorBoundary';
// import EmailValidationBanner from 'components/EmailValidationBanner';
import TopBanner from 'components/TopBanner';
import Ribbon from 'components/Ribbon';
import AICCBackHeader from 'components/AICCBackHeader';
import BackHeader from 'components/BackHeader';
import ContentBlock from 'components/ContentBlock';
import { CONTENT_BLOCK_POSITIONS } from 'components/ContentBlock/utils';
import RegionBanner from 'components/RegionBanner';
import useAccountVerification from 'components/Hooks/useAccountVerification';
import useAICC from 'components/Hooks/useAICC';
import useAppcues from 'components/Hooks/useAppcues';
import useAppEmbedMode from 'components/Hooks/useAppEmbedMode';
import useClinicMode from 'components/Hooks/useClinicMode';
import useHCSCEffect from 'components/Hooks/useHCSCEffect';
import useTruthyState from 'components/Hooks/useTruthyState';
import useFirestoreListener from 'components/Hooks/useFirestoreListener';
import useFreshworksWidget from 'components/Hooks/useFreshworksWidget';
import useHomepagePro from 'components/Hooks/useHomepagePro';
import useInsuranceModal from 'components/Hooks/useInsuranceModal';
import usePageTracking from 'components/Hooks/usePageTracking';
import usePersonalEmailModal from 'components/Hooks/usePersonalEmailModal';
import useProfileFilters from 'components/Hooks/useProfileFilters';
import useCustomFavicon from 'components/Hooks/useCustomFavicon';
import NPSSurvey from 'components/NPSSurvey';
import AccountExtraInfoModal from 'components/AccountExtraInfoModal';
import LanguageBanner from 'components/LanguageBanner';
import LeavingAppDialog from 'components/LeavingAppDialog';
import withPageLanguage from 'components/PageLanguage';
import OnboardingQuestionsDialog from 'components/Personalizations/OnboardingQuestionsDialog';
import { LanguageDisclaimer } from 'components/LanguageDisclaimer/LanguageDisclaimer';
import LoadingIndicator from 'components/LoadingIndicator';
import { useCustomTokenSignIn } from 'components/Hooks/useCustomTokenSignIn';
import useCheckModeParam from 'components/Hooks/useCheckModeParam';
import CustomOnboardingDialog from 'components/Personalizations/CustomOnboardingDialog';
import OnboardingModal from 'containers/OnboardingModal';
import useCheckViewParam from 'components/Hooks/useCheckViewParam';
import useCheckNumCardsParam from 'components/Hooks/useCheckNumCardsParam';
import useTokenTimeout from 'components/Hooks/useTokenTimeout';
import useSessionTimeout from 'components/Hooks/useSessionTimeout';
import useSessionEnd from 'components/Hooks/useSessionEnd';
import useEligibilityProvider from 'components/Hooks/useEligibilityProvider';
import retry from 'utils/retry';
import { isEmbedded, isBareEmbedded, windowScrollTo } from 'utils/embedded';
import { getLocalData, setLocalData } from 'utils/localDataStore';
import { generateKey, getSubdomain, isBot } from 'utils/stringUtils';
import { isCurrentPath } from 'utils/history';
import NavBarHeaderWrapper from 'components/NavbarHeaderWrapper';
import useLanguage from 'components/Hooks/useLanguage';
import useInCrisis from 'containers/InCrisis/useInCrisis';
import { IdleTimeoutProvider } from 'containers/InactivityTimeout/IdleTimeoutContext';
import { MobileAudioPlayerProvider } from 'containers/MobileAudioPlayer/MobileAudioPlayerContext';
import { MobileAudioPlayer } from 'containers/MobileAudioPlayer/MobileAudioPlayer';
import {
  getResourcesProgress,
  getSavedResources,
} from 'containers/Resources/actions';
import {
  getTakenPractices,
  getViewedPractices,
} from 'containers/Practices/actions';
import InactivityTimeout from 'containers/InactivityTimeout';
import { getOnboardingModals } from 'containers/OnboardingModal/actions';
import { LanguagePopupContainer } from 'containers/LanguagePopup/LanguagePopup';
import useSiteConfigSelector from 'components/useSiteConfigSelector';
import { initializeABTests } from 'utils/abTestsUtils';
import globalSaga from './globalSaga';
import {
  getAudienceTagsRelations,
  getPageIntros,
  getReferrals,
  getSiteCopy,
  getSiteConfig,
  setLanguage,
  showLanguageBanner,
  getLocales,
  getContentBlocks,
  setTextDirection,
  getHomeReferrals,
  setClientDetailsLoaded,
} from './actions';
import {
  makeSelectClientDetails,
  makeSelectClientDetailsFetching,
  makeSelectClientDetailsLoaded,
  makeSelectLanguage,
  makeSelectShowHomepagePro,
  makeSelectSiteCopy,
  makeSelectSiteConfig,
  makeSelectLocales,
  makeSelectClinicMode,
} from './selectors';
import {
  isNpsSurvey,
  languageCodeMapping,
  navigatorLanguageToContentfulMapping,
} from './utils';

const { Slide, toast } = !isBot() ? require('react-toastify') : {};

const UserConsent = lazy(() => retry(() => import('components/UserConsent')));
const TermsOfUse = lazy(() => retry(() => import('containers/TermsOfUse')));
const PrivacyPolicy = lazy(() =>
  retry(() => import('containers/PrivacyPolicy')),
);
const About = lazy(() => retry(() => import('containers/About')));
const AuthModal = lazy(() => retry(() => import('components/AuthModal')));
const AuthCallToActionModal = lazy(() =>
  retry(() => import('components/AuthCallToActionModal')),
);
const FooterWrapper = lazy(() =>
  retry(() => import('components/FooterWrapper')),
);
// const Sponsors = lazy(() => retry(() => import('components/SponsorFooter')));
// const Footer = lazy(() => retry(() => import('components/Footer')));
const RegionSelector = lazy(() =>
  retry(() => import('components/RegionSelector')),
);

if (toast) {
  // eslint-disable-next-line global-require
  require('react-toastify/dist/ReactToastify.css');
  toast.configure({
    hideProgressBar: true,
    transition: Slide,
  });
}

initializeABTests();

const stateSelector = createStructuredSelector({
  clientDetails: makeSelectClientDetails(),
  clientDetailsFetching: makeSelectClientDetailsFetching(),
  clientDetailsLoaded: makeSelectClientDetailsLoaded(),
  auth: makeSelectAuth(),
  showHomepagePro: makeSelectShowHomepagePro(),
  siteCopy: makeSelectSiteCopy(),
  siteConfig: makeSelectSiteConfig(),
  language: makeSelectLanguage(),
  locales: makeSelectLocales(),
  profile: makeSelectProfile(),
  clinicMode: makeSelectClinicMode(),
});

function Main() {
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [hasMultipleLanguage, setHasMultipleLanguage] = useState(false);
  const [hasChangedRootLang, setHasChangedRootLang] = useState(false);
  const initialRootLang = useRef(document.documentElement.lang);
  const brandName = getLocalData('brand') || 'none';
  const [isBranded, setIsBranded] = useState(brandName !== 'none');
  const { pathname, search } = window.location;
  const subdomain = getSubdomain();
  const isHomePage = pathname === '/';
  const {
    auth,
    clientDetails,
    clientDetailsFetching,
    clientDetailsLoaded,
    clinicMode,
    language,
    locales,
    profile,
    showHomepagePro,
    siteConfig,
    siteCopy,
  } = useSelector(stateSelector);
  const { textDirection } = useLanguage();
  const [featuresSiteConfig] = useSiteConfigSelector(['Features']);

  // measure components
  const [measure, setMeasure] = useState(null);

  // check mode param for public device
  useCheckModeParam();

  // check view param
  useCheckViewParam();

  // check num card parm
  useCheckNumCardsParam();

  const bot = isBot();
  const dispatch = useDispatch();
  const clientShortName = _get(clientDetails, 'shortName');
  const isEmbed = isEmbedded();
  const isAdmin = pathname.includes('/admin') && !isEmbed;

  useTokenTimeout();
  useSessionTimeout();
  useSessionEnd();

  const isBareEmbed = isBareEmbedded();
  const isClientAdmin = pathname.includes('/client-admin');

  const queryParams = qs.parse(search.slice(1));

  useAccountVerification();
  useHomepagePro();
  useClinicMode();
  useCustomFavicon();
  useProfileFilters();
  usePersonalEmailModal();
  useInsuranceModal();
  useFirestoreListener();
  useFreshworksWidget();
  useEligibilityProvider();
  useHCSCEffect();

  const isCustomSignInProcessing = useCustomTokenSignIn();

  const [isInactivityDialogOpen, setIsInactivityDialogOpen] = useState(false);

  const openInactivityDialog = () => {
    setIsInactivityDialogOpen(true);
  };

  const closeInactivityDialog = () => {
    setIsInactivityDialogOpen(false);
  };

  // init trackers
  usePageTracking();

  // useAssessmentReminder();

  // scroll top on mount
  useEffect(() => {
    windowScrollTo(0, 0);
  }, []);

  useEffect(() => {
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes') {
          const { lang } = mutation.target;
          if (lang !== initialRootLang.current) setHasChangedRootLang(true);
          else setHasChangedRootLang(false);
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    dispatch(setTextDirection(textDirection));
  }, [textDirection, dispatch]);

  useEffect(() => {
    if (
      !_isEmpty(clientDetails) &&
      !clientDetailsFetching &&
      !clientDetailsLoaded
    ) {
      dispatch(setClientDetailsLoaded(true));
    }
  }, [clientDetails, clientDetailsFetching, clientDetailsLoaded, dispatch]);

  useEffect(() => {
    if (
      !initialLoaded &&
      isLoaded(auth) &&
      !_isEmpty(siteCopy) &&
      !_isEmpty(siteConfig) &&
      !_isNil(clinicMode) &&
      clientDetailsLoaded
    ) {
      const loaderEl = document.getElementById('full-page-loader');
      if (loaderEl) loaderEl.parentNode.removeChild(loaderEl);

      setInitialLoaded(true);
    }
  }, [auth, clinicMode, siteCopy, siteConfig, clientDetailsLoaded]);

  useEffect(() => {
    if (auth.isLoaded && !auth.isEmpty) {
      dispatch(incrementSignUpCount());
    }
  }, [auth, dispatch]);

  useEffect(() => {
    /** get locales */
    dispatch(getLocales());

    // set temporary user id in cookies if not set yet, this will be used for recombee interaction when user is not logged in
    let userId = getLocalData('userIdTemp');
    if (_isEmpty(userId)) {
      userId = `anonymous-${generateKey()}`;
      setLocalData('userIdTemp', userId, {
        expires: 365,
      });
    }

    const regex = new RegExp(
      /\/(apps|articles|blogs|books|lists|faqs|organizations|people|podcasts|programs|topics|videos)\/[\S]+/g,
    );
    if (!regex.test(window.location)) {
      window.prerenderReady = true;
    }
  }, []);

  useEffect(() => {
    if (auth.isLoaded && clientDetailsLoaded) {
      dispatch(getTakenSeries());
      dispatch(getTakenCourses());
      dispatch(getTakenPractices());
      dispatch(getViewedPractices());
      dispatch(getSavedResources());
      dispatch(getResourcesProgress());
    }
  }, [auth, clientDetailsLoaded]);

  const useNewOnboardingModals = _get(
    featuresSiteConfig,
    'config.newOnboardingModals',
  );

  useEffect(() => {
    if (auth.isLoaded && clientDetailsLoaded && useNewOnboardingModals) {
      dispatch(getOnboardingModals());
    }
  }, [auth, clientDetailsLoaded, useNewOnboardingModals]);

  useEffect(() => {
    //   if (subdomain && !bot && subdomain !== 'development') {
    //     setLocalData('brand', subdomain);
    //     dispatch(getClientDetails(subdomain));
    //   }
    //   // if (hasClientDetails) {
    //   //   if (brand && brand !== 'none') Mixpanel.init(brand);
    //   // }
    const firstTimer = getLocalData('firstTimeUser');
    if (!firstTimer) setLocalData('firstTimeUser', true);
    else setLocalData('firstTimeUser', false);
  }, []);

  useEffect(() => {
    if (!_isEmpty(locales)) {
      /** Get Home Banner/Hero content */
      dispatch(getSiteCopy());
      dispatch(getSiteConfig());
      dispatch(getPageIntros());
      dispatch(getHomeReferrals());
      /** Get Audience and Tags Relations */
      dispatch(getAudienceTagsRelations());
      dispatch(getContentBlocks());
    }
  }, [dispatch, locales, language]);

  const wasAssessmentsPage = useTruthyState(() =>
    pathname.includes('/assessments'),
  );

  useEffect(() => {
    if (wasAssessmentsPage && !_isEmpty(locales)) {
      dispatch(getReferrals());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, locales, language, wasAssessmentsPage]);

  useEffect(() => {
    if (
      typeof _get(window, 'xprops.setIframeHeight', '') === 'function' &&
      !_isEmpty(measure) &&
      isEmbed
    ) {
      window.xprops.setIframeHeight(_get(measure, 'dimensions.height', 0));
    }
  }, [measure, isEmbed]);

  useEffect(() => {
    if (
      !_isEmpty(clientShortName) &&
      !_isEmpty(clientDetails) &&
      !clientDetailsFetching
    ) {
      setIsBranded(true);
      setLocalData('brand', clientDetails.shortName);
      const languages = _get(clientDetails, 'languageCollection.items', []);
      const languagesLen = languages.length;
      setHasMultipleLanguage(languagesLen > 1);
      if (languagesLen) {
        if (!initialLoaded) {
          const clientLanguages = _get(
            clientDetails,
            'languageCollection.items',
            [],
          ).map(
            item => languageCodeMapping[(item?.shortcode)] || item?.shortcode,
          );

          const browserLanguage =
            window.navigator.userLanguage || window.navigator.language || '';
          const finalBrowserLanguage = Object.keys(
            navigatorLanguageToContentfulMapping,
          ).includes(browserLanguage)
            ? navigatorLanguageToContentfulMapping[browserLanguage]
            : browserLanguage.split('-')[0];
          if (!isEmbed && _isEmpty(language)) {
            if (
              (languageCodeMapping[finalBrowserLanguage] ||
                finalBrowserLanguage) !== language &&
              clientLanguages.includes(
                languageCodeMapping[finalBrowserLanguage] ||
                  finalBrowserLanguage,
              )
            ) {
              dispatch(setLanguage(finalBrowserLanguage));
            } else {
              dispatch(setLanguage(language || _get(languages, '0.shortcode')));
            }
          }
        } else {
          dispatch(setLanguage(language || _get(languages, '0.shortcode')));
        }
        dispatch(
          showLanguageBanner(
            !hasChangedRootLang &&
              languagesLen > 1 &&
              !showHomepagePro &&
              queryParams.showcorp !== '1',
          ),
        );
      } else {
        dispatch(setLanguage(''));
        dispatch(showLanguageBanner(false));
      }
    }

    if (
      clientDetails !== null &&
      _isEmpty(clientDetails) &&
      !clientDetailsFetching
    ) {
      setIsBranded(false);
      setLocalData('brand', 'none');
      setHasMultipleLanguage(false);
      dispatch(showLanguageBanner(false));
    }
  }, [clientDetails, clientDetailsFetching, hasChangedRootLang]);

  useAICC();
  useAppEmbedMode();
  useAppcues();

  const { showInCrisisInHeader, renderInCrisisButton } = useInCrisis();

  if (isCustomSignInProcessing) return <LoadingIndicator />;

  const notACorporateSite = !showHomepagePro && queryParams.showcorp !== '1';
  const showTopBannerAndRibbon =
    notACorporateSite &&
    isHomePage &&
    _isEmpty(clientDetails) &&
    _isEmpty(clientShortName) &&
    !clientDetailsFetching;
  if (!initialLoaded) return null;
  if (subdomain && _isEmpty(clientDetails)) return null;

  const useAppEmbedStyle =
    isEmbed || _get(clientDetails, 'metadata.useAppEmbedStyle', false);

  const customFontStylesheetsUrls = _get(
    clientDetails,
    'customFontsCollection.items',
    [],
  ).map(item => item.stylesheet.url);

  return (
    <>
      {customFontStylesheetsUrls.length > 0 && (
        <Helmet>
          {customFontStylesheetsUrls.map(url => (
            <link href={url} key={url} rel="stylesheet" />
          ))}
        </Helmet>
      )}
      <Measure
        bounds
        onResize={contentRect => {
          setMeasure({ dimensions: _get(contentRect, 'bounds') });
        }}
      >
        {({ measureRef }) => (
          <div ref={measureRef}>
            <IdleTimeoutProvider
              closePrompt={closeInactivityDialog}
              onPrompt={openInactivityDialog}
            >
              <MobileAudioPlayerProvider>
                <ErrorBoundary>
                  {!isEmbed && (
                    <Suspense fallback={<div />}>
                      <UserConsent />
                    </Suspense>
                  )}
                  {!isBareEmbed && (
                    <>
                      <BackHeader />
                      <AICCBackHeader />
                    </>
                  )}
                  {!isCurrentPath('/2fa') && (
                    <StickyHeader>
                      {!isBareEmbed && (
                        <>
                          {!isClientAdmin && (
                            <ContentBlock
                              type="common-page"
                              position={
                                CONTENT_BLOCK_POSITIONS.WINDOW_TOP_STICKY
                              }
                              fullWidth
                            />
                          )}
                          {/* <EmailValidationBanner /> */}
                          {!useAppEmbedStyle &&
                            notACorporateSite &&
                            !isClientAdmin &&
                            !isAdmin && (
                              <>
                                {showTopBannerAndRibbon ? (
                                  <>
                                    {showInCrisisInHeader && (
                                      <Hidden xsDown>
                                        <NavBarHeaderWrapper
                                          rightContent={renderInCrisisButton()}
                                        />
                                      </Hidden>
                                    )}
                                    <Ribbon />
                                    <Hidden xsDown>
                                      <TopBanner />
                                    </Hidden>
                                  </>
                                ) : (
                                  <Hidden xsDown>
                                    {hasMultipleLanguage ? (
                                      <LanguageBanner
                                        hasMultipleLanguage={
                                          hasMultipleLanguage
                                        }
                                      />
                                    ) : (
                                      <RegionBanner />
                                    )}
                                  </Hidden>
                                )}
                              </>
                            )}
                        </>
                      )}
                      {!isClientAdmin && <NavBar />}
                    </StickyHeader>
                  )}
                  {!isClientAdmin && !isBareEmbed && (
                    <ContentBlock
                      type="common-page"
                      rounded={false}
                      position={CONTENT_BLOCK_POSITIONS.BELOW_MENU_SCROLLS}
                    />
                  )}
                  <Routes />
                  <LeavingAppDialog />
                  <Suspense fallback={<div />}>
                    <TermsOfUse />
                    <PrivacyPolicy />
                    {!isBareEmbed && <FooterWrapper isBranded={isBranded} />}

                    <LanguageDisclaimer clientDetails={clientDetails} />

                    {isNpsSurvey(
                      showHomepagePro,
                      queryParams,
                      isClientAdmin,
                      profile,
                    ) && <NPSSurvey />}
                    {!bot && (
                      <>
                        <OnboardingQuestionsDialog
                          clientShortName={clientShortName}
                        />
                        {_isEmpty(
                          featuresSiteConfig,
                        ) ? null : useNewOnboardingModals ? (
                          <OnboardingModal
                            hasMultipleLanguage={hasMultipleLanguage}
                          />
                        ) : (
                          <CustomOnboardingDialog
                            hasMultipleLanguage={hasMultipleLanguage}
                          />
                        )}
                        <AuthModal />
                        <AccountExtraInfoModal />
                        <AuthCallToActionModal />
                        {isBranded && !_isEmpty(clientShortName) && (
                          <RegionSelector />
                        )}
                      </>
                    )}
                    {/* <MyLocation /> */}
                    <About />
                  </Suspense>
                  <InactivityTimeout
                    closeDialog={closeInactivityDialog}
                    isDialogOpen={isInactivityDialogOpen}
                  />
                  <MobileAudioPlayer />
                  <LanguagePopupContainer />
                </ErrorBoundary>
              </MobileAudioPlayerProvider>
            </IdleTimeoutProvider>
          </div>
        )}
      </Measure>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
}

export default compose(
  ...Object.values(globalSaga),
  withPageBranding,
  withPageLanguage,
)(Main);
