import React, { useContext } from 'react';
import _get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { getCustomIcon } from 'components/Resources/utils';
import { Link } from 'react-router-dom';
import NavBarContext from '../../../navBarContext';

const useStyles = makeStyles({
  container: {
    padding: 12,
    borderRadius: 8,
    gap: 24,
    color: '#4B4B4B',
    fill: '#4B4B4B',
    display: 'flex',
    '&:hover': {
      color: '#01619B',
      fill: '#01619B',
      background: '#EEF9FF',
    },
  },
  iconWrapper: {
    '& svg': {
      height: 16,
      width: 16,
    },
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  header: {
    fontSize: 17,
    lineHeight: '24px',
  },
  description: {
    fontSize: 14,
    lineHeight: '22px',
  },
});

function LearningLabItem({ type, iconType, onClick, onHover }) {
  const { siteCopy } = useContext(NavBarContext);
  const classes = useStyles();

  return (
    <Box
      className={classes.container}
      component={Link}
      to={`/learning-lab#${type}`}
      onClick={onClick}
      onMouseEnter={onHover}
    >
      <Box className={classes.iconWrapper}>
        {getCustomIcon(iconType, 'inherit')}
      </Box>
      <Box className={classes.textWrapper}>
        <Typography variant="h6" color="inherit" className={classes.header}>
          {_get(siteCopy, [
            'pageCopy',
            'submenu-learning-lab',
            `${type}-label`,
          ])}
        </Typography>
        <Typography color="textPrimary" className={classes.description}>
          {_get(siteCopy, [
            'pageCopy',
            'submenu-learning-lab',
            `${type}-description`,
          ])}
        </Typography>
      </Box>
    </Box>
  );
}

export default LearningLabItem;
