import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import ButtonBase from '@material-ui/core/ButtonBase';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { getIsClientAdmin } from 'containers/Auth/utils';
import { getUserDisplayName } from 'utils/stringUtils';
import Mixpanel from 'utils/mixpanelService';
import NavBarContext from '../../navBarContext';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: 15,
    paddingRight: 15,
    borderBottom: props => `1px solid ${props.theme.borderColor}`,
  },
  username: {
    color: props => props.theme.headerColor,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Mada',
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: '1.2',
    marginBottom: 10,
  },
  link: {
    color: props => props.theme.linkColor,
    fontFamily: 'Mada',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: '1.3',
    textAlign: 'left',
  },
  avatar: {
    height: 64,
    width: 64,
    border: '2px solid #FFFFFF',
    borderRadius: 32,
    marginLeft: 20,
    color: '#FFFFFF',
  },
});

const UserInformation = ({ theme }) => {
  const classes = useStyles({ theme });
  const history = useHistory();
  const { clientDetails, logout, profile, siteCopy } = useContext(
    NavBarContext,
  );

  const { avatarUrl } = profile;
  const displayName = getUserDisplayName(profile) || '';
  const finalDisplayName =
    displayName.length > 16
      ? `${displayName.substring(0, 13)}...`
      : displayName;

  const handleEditProfile = () => {
    history.push('/account');
    Mixpanel.track('User Links - Clicked', {
      type: 'account',
      path: 'profileMenu',
    });
  };
  const handleAdminTools = () => {
    history.push('/admin');
  };
  const handleAdminPortal = () => {
    history.push('/client-admin');
    Mixpanel.track('User Links - Clicked', {
      type: 'client-admin',
      path: 'profileMenu',
    });
  };
  const handleLogout = () => {
    Mixpanel.track('User Links - Clicked', {
      type: 'logout',
      path: 'profileMenu',
    });
    logout();
  };

  const showAdminToolsOption =
    profile.role === 'admin' ||
    profile.role === 'contentAdmin' ||
    profile.role === 'salesAdmin';
  const hasClientAdminAccess =
    !_isEmpty(clientDetails) &&
    (getIsClientAdmin(profile, clientDetails) ||
      ['admin', 'contentAdmin'].includes(_get(profile, 'role')));

  return (
    <Box className={classes.root}>
      <Box minWidth={185}>
        <Typography className={classes.username}>{finalDisplayName}</Typography>
        <Grid container>
          {showAdminToolsOption && (
            <Grid item xs={6}>
              <ButtonBase
                onClick={handleAdminTools}
                className={classes.link}
                role="link"
                disableRipple
              >
                {_get(
                  siteCopy,
                  ['pageCopy', 'profile-sidebar', 'adminTools'],
                  'Admin Tools',
                )}
              </ButtonBase>
            </Grid>
          )}
          {hasClientAdminAccess && (
            <Grid item xs={6}>
              <ButtonBase
                onClick={handleAdminPortal}
                role="link"
                className={classes.link}
                disableRipple
              >
                {_get(
                  siteCopy,
                  ['pageCopy', 'profile-sidebar', 'adminPortal'],
                  'Admin Portal',
                )}
              </ButtonBase>
            </Grid>
          )}
          <Grid item xs={6}>
            <ButtonBase
              onClick={handleEditProfile}
              role="link"
              className={classes.link}
              disableRipple
            >
              {_get(
                siteCopy,
                ['pageCopy', 'profile-sidebar', 'editProfile'],
                'Edit Profile',
              )}
            </ButtonBase>
          </Grid>
          <Grid item xs={6}>
            <ButtonBase
              onClick={handleLogout}
              className={classes.link}
              disableRipple
            >
              {_get(
                siteCopy,
                ['pageCopy', 'profile-sidebar', 'logout'],
                'Logout',
              )}
            </ButtonBase>
          </Grid>
        </Grid>
      </Box>
      {avatarUrl ? (
        <Avatar alt="User Avatar" src={avatarUrl} className={classes.avatar} />
      ) : (
        <AccountCircle className={classes.avatar} />
      )}
    </Box>
  );
};

export default UserInformation;
