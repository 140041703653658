import Config from 'utils/getEnvConfig';
import axios from 'axios';
import _get from 'lodash/get';
import { getLocalData } from 'utils/localDataStore';

const {
  LAMBDA: {
    MAILCHIMP: {
      ASSESSMENT_TAKEN,
      GET_SUBSCRIPTION_URL,
      DO_SUBSCRIPTION_URL,
      UNSUBSCRIBE_URL,
      API_KEY,
    },
  },
} = Config;

const getUserStatus = email =>
  axios.get(`${GET_SUBSCRIPTION_URL}${email}`, {
    headers: {
      'x-api-key': API_KEY,
    },
  });

const subscribe = ({
  email,
  subscription,
  firstName,
  lastName,
  isSamlProvider,
}) => {
  const brand = getLocalData('brand') || 'none';
  return axios
    .post(
      DO_SUBSCRIPTION_URL,
      {
        email,
        brand,
        contentList: subscription,
        firstName,
        lastName,
        saml: isSamlProvider,
      },
      {
        headers: {
          'x-api-key': API_KEY,
        },
      },
    )
    .then(
      response =>
        new Promise((resolve, reject) => {
          if (response.status >= 200 && response.status < 300) {
            resolve(response);
          } else {
            reject(response);
          }
        }),
    )
    .catch(
      error =>
        new Promise((_, reject) => {
          reject(_get(error, 'response'));
        }),
    );
};

const updateSubscription = (
  email,
  contentList,
  firstName,
  lastName,
  oldEmail,
) => {
  const brand = getLocalData('brand') || 'none';
  return axios
    .put(
      DO_SUBSCRIPTION_URL,
      {
        email,
        brand,
        contentList,
        firstName,
        lastName,
        oldEmail,
      },
      {
        headers: {
          'x-api-key': API_KEY,
        },
      },
    )
    .then(
      response =>
        new Promise((resolve, reject) => {
          if (response.status >= 200 && response.status < 300) {
            resolve(response);
          } else {
            reject(response);
          }
        }),
    )
    .catch(
      error =>
        new Promise((_, reject) => {
          reject(_get(error, 'response'));
        }),
    );
};

const patchSubscriptionBrand = email => {
  const brand = getLocalData('brand') || 'none';
  return axios
    .patch(
      DO_SUBSCRIPTION_URL,
      {
        email,
        brand,
      },
      {
        headers: {
          'x-api-key': API_KEY,
        },
      },
    )
    .then(
      response =>
        new Promise((resolve, reject) => {
          if (
            (response.status >= 200 && response.status < 300) ||
            response.status === 404
          ) {
            resolve(response);
          } else {
            reject(response);
          }
        }),
    )
    .catch(
      error =>
        new Promise((resolve, reject) => {
          if (_get(error, 'response.status') === 404) {
            resolve();
          } else {
            reject(_get(error, 'response'));
          }
        }),
    );
};

const unsubscribe = email =>
  axios.post(
    UNSUBSCRIBE_URL,
    {
      email,
    },
    {
      headers: {
        'x-api-key': API_KEY,
      },
    },
  );

const patchAssessmentTaken = (email, slug) =>
  axios.patch(
    ASSESSMENT_TAKEN,
    {
      email,
      slug,
    },
    {
      headers: {
        'x-api-key': API_KEY,
      },
    },
  );

export default {
  getUserStatus,
  subscribe,
  updateSubscription,
  patchSubscriptionBrand,
  patchAssessmentTaken,
  unsubscribe,
};
