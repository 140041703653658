import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { getImageFile } from 'utils/stringUtils';
import StyledLink from 'components/StyledLink';

const useStyles = makeStyles(theme => ({
  lists: {
    display: 'flex',
    flexWrap: 'nowrap',
    maxWidth: '100%',
  },
  image: {
    height: 147,
    [theme.breakpoints.down('380')]: {
      height: 130,
    },
    [theme.breakpoints.up('600')]: {
      width: 147,
    },
  },
  listItem: {
    marginRight: 20,
    width: 147,
    [theme.breakpoints.down('380')]: {
      marginRight: 5,
      width: 130,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
  listTitle: {
    fontFamily: 'MadaBold',
    fontSize: 14,
    marginTop: 10,
    color: theme.palette.text.primary,
  },
  link: {
    display: 'block',
  },
}));

function TopicCard({ data, clickHandler }) {
  const classes = useStyles();
  if (!data) return null;
  return (
    <>
      <div className={classes.lists} data-test-id="topic-card-list">
        {data.map(topic => {
          const imageSrc = getImageFile(topic);
          const { slug, title } = topic;
          return (
            <div key={slug} className={classes.listItem}>
              <StyledLink
                tabIndex={0}
                link={`/topics/${slug}`}
                onClick={() => clickHandler(title, 'image')}
                className={classes.link}
              >
                <img src={imageSrc} alt={title} className={classes.image} />
                <Typography className={classes.listTitle}>{title}</Typography>
              </StyledLink>
            </div>
          );
        })}
      </div>
    </>
  );
}

TopicCard.propTypes = {
  data: PropTypes.array,
  clickHandler: PropTypes.func,
};

export default TopicCard;
