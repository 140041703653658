/*
 *
 * Assessment constants
 *
 */

export const PROCESSING = 'app/Assessment/PROCESSING';
export const ANSWERS_PROCESSING = 'app/Assessment/ANSWERS_PROCESSING';
export const GET_ASSESSMENT = 'app/Assessment/GET_ASSESSMENT';
export const GET_ASSESSMENT_SUCCESS = 'app/Assessment/GET_ASSESSMENT_SUCCESS';
export const GET_ASSESSMENT_FAIL = 'app/Assessment/GET_ASSESSMENT_FAIL';
export const GET_ANSWERS = 'app/Assessment/GET_ANSWERS';
export const GET_ANSWERS_SUCCESS = 'app/Assessment/GET_ANSWERS_SUCCESS';
export const GET_ANSWERS_FAIL = 'app/Assessment/GET_ANSWERS_FAIL';
export const GET_ANSWERS_BY_ACCESS_CODE =
  'app/Assessment/GET_ANSWERS_BY_ACCESS_CODE';
export const GET_RESULTS_BY_ADMIN_CODE =
  'app/Assessment/GET_RESULTS_BY_ADMIN_CODE';
export const SET_ANSWERS_SOURCE = 'app/Assessment/SET_ANSWERS_SOURCE';
export const PUT_ASSESSMENT = 'app/Assessment/PUT_ASSESSMENT';
export const PUT_ASSESSMENT_RESULT = 'app/Assessment/PUT_ASSESSMENT_RESULT';
export const GET_ASSESSMENT_RESULT = 'app/Assessment/GET_ASSESSMENT_RESULT';
export const GET_ASSESSMENT_RESULT_SUCCESS =
  'app/Assessment/GET_ASSESSMENT_RESULT_SUCCESS';
export const GET_ASSESSMENT_RESULT_FAIL =
  'app/Assessment/GET_ASSESSMENT_RESULT_FAIL';
export const GET_ASSESSMENT_REPORT = 'app/Assessment/GET_ASSESSMENT_REPORT';
export const GET_ASSESSMENT_REPORT_RESULT =
  'app/Assessment/GET_ASSESSMENT_REPORT_RESULT';
export const SEND_ASSESSMENT_RESULTS_BY_EMAIL =
  'app/Assessment/SEND_ASSESSMENT_RESULTS_BY_EMAIL';
export const SEND_ASSESSMENT_RESULTS_BY_EMAIL_RESULT =
  'app/Assessment/SEND_ASSESSMENT_RESULTS_BY_EMAIL_RESULT';
export const SET_TAKEN_IN_SESSION = 'app/Assessment/SET_TAKEN_IN_SESSION';
export const MOVE_ANONYMOUS_ASSESSMENTS =
  'app/Assessment/MOVE_ANONYMOUS_ASSESSMENTS';
export const MOVE_ANONYMOUS_ASSESSMENTS_RESULT =
  'app/Assessment/MOVE_ANONYMOUS_ASSESSMENTS_RESULT';
export const SET_SMS_DIALOG = 'app/Assessment/SET_SMS_DIALOG';
export const SET_ACCESS_CODE_COPIED = 'app/Assessment/SET_ACCESS_CODE_COPIED';
export const GET_ANSWERS_WITH_RESULTS =
  'app/Assessment/GET_ANSWERS_WITH_RESULTS';
export const GET_COURSE_ASSESSMENT_RESULTS_SUCCESS =
  'app/Assessment/GET_COURSE_ASSESSMENT_RESULTS_SUCCESS';

const whiteColor = '#FFFFFF';
const grayColor = '#4B4B4B';

export const ASSESSMENT_COLORS = {
  aqua: {
    main: '#04594E',
    font: whiteColor,
  },
  blue: {
    main: '#066080',
    font: whiteColor,
  },
  gold: {
    main: '#674600',
    font: whiteColor,
  },
  grey: {
    main: '#414141',
    font: whiteColor,
  },
  pink: { main: '#F8EDEB', font: grayColor },
  yellow: { main: '#FDEFC8', font: grayColor },
  iceblue: {
    main: '#ECF2FE',
    font: grayColor,
  },
  skyblue: {
    main: '#E4F4F5',
    font: grayColor,
  },
};

export const contextRegex = /context\[[^\s\[\]]+\]/g;
export const anyRegex = /any\(([^)]+), ([^)]+), ([0-9]+)\)/g;
export const containsRegex = /contains\(\[([^\s,]+)\],[\s]*\[([^\s]+[,( )*]*)+\]\)/g;
export const variableRegex = /\[[^+\s,]+\]/g;
export const numberRegex = /\d+/g;
export const visibleIfRegex = /\{[a-zA-Z0-9]+[.[a-zA-Z0-9]+]*\}/g;
export const visibleIfContainsRegex = /{([0-9a-zA-Z]+)} contains ([('|")[0-9a-zA-Z]+('|")])/g;
export const visibleIfContainsWoBracketRegex = /{([0-9a-zA-Z]+)} contains (('|")[0-9a-zA-Z]+('|"))/g;
export const assessmentResultIdRegex = /([0-9a-zA-Z]{22})/g;
export const parenthesisRegex = /\([a-zA-Z0-9 =><{}[\]"'.+-]*\)/g;
