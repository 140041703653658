/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-curly-brace-presence */
/**
 *
 * EmptyState
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import Grid from '@material-ui/core/Grid';
import AppContainer from 'components/AppContainer';
import Typography from '@material-ui/core/Typography';
import SearchBar from 'components/SearchBar';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Mixpanel from 'utils/mixpanelService';
import { isEmbedded } from 'utils/embedded';
import _get from 'lodash/get';

const styles = theme => ({
  mainText: {
    fontSize: 175,
    fontWeight: 600,
    paddingRight: 20,
    paddingLeft: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 105,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 100,
    },
  },
  message: {
    color: 'rgba(0,0,0,.3)',
    fontSize: 77,
    paddingTop: 70,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 50,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 40,
      padding: '40px 0px',
    },
  },
  returnMessage: {
    fontSize: 20,
    color: 'rgba(0,0,0,.3)',
  },
  searchBar: {
    marginTop: 60,
    '& > div': {
      border: 'none',
      borderBottom: '1px solid rgba(0,0,0,.15)',
      borderRadius: 0,
    },
    '& input': {
      fontSize: '50px !important',
      paddingLeft: '0 !important',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px !important',
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '20px !important',
      },
    },
    '& img': {
      width: '25px !important',
      height: '25px !important',
    },
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
});
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable-next-line react/jsx-curly-brace-presence */
function EmptyState(props) {
  const { classes, history, location } = props;
  const is404Page = location.pathname === '/404';

  useEffect(() => {
    if (is404Page) {
      return undefined;
    }

    const interval = setTimeout(() => {
      Mixpanel.track('404 - Not Found', {
        path: location.pathname,
        type: location.pathname.split('/')[1],
      });
    }, 100);

    return () => clearTimeout(interval);
  }, []);

  const handleReturnToHomepage = () => {
    if (
      typeof _get(window, 'xprops.onRouteChange', '') === 'function' &&
      isEmbedded()
    ) {
      window.xprops.onRouteChange('');
    } else {
      history.push('/');
    }
  };

  return (
    <>
      <Helmet>
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      <AppContainer justify="center" alignItems="center">
        <Grid item xs={12} className="page-not-found">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={5}>
              <Typography
                variant="h1"
                className={classes.mainText}
                color="textPrimary"
                align="center"
              >
                404
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Typography variant="h2" className={classes.message}>
                {`We couldn't find this page.`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.searchBar}>
            <SearchBar
              history={history}
              id="404-search-input"
              section="not found"
            />
          </Grid>
          {is404Page ? (
            <Grid item xs={12}>
              <p className={classes.returnMessage}>
                Return to the
                <a onClick={handleReturnToHomepage} className={classes.link}>
                  {' '}
                  homepage
                </a>{' '}
                to find topics and resources.
              </p>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <p className={classes.returnMessage}>
                <a href="" className={classes.link}>
                  Refresh{' '}
                </a>
                this page if you think this item exists or return to the
                <a onClick={handleReturnToHomepage} className={classes.link}>
                  {' '}
                  homepage
                </a>{' '}
                to find topics and resources.
              </p>
            </Grid>
          )}
        </Grid>
      </AppContainer>
    </>
  );
}

EmptyState.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
};
export default compose(
  withRouter,
  withStyles(styles),
)(React.memo(EmptyState));
