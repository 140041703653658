import ctf from 'utils/contentfulService';
import { get } from 'utils/api';
import Config from 'utils/getEnvConfig';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _some from 'lodash/some';
import { getContenfulFilters } from 'containers/Main/utils';
import { filterClientExclusiveResources } from 'containers/Resources/utils';
import { topicRequiredFields } from 'containers/TopicCenter/services/getTopicBySlug';
import TopicCenter from 'containers/TopicCenter/Loadable';
import Video from 'containers/Video/Loadable';
import App from 'containers/App/Loadable';
import Podcast from 'containers/Podcast/Loadable';
import Organization from 'containers/Organization/Loadable';
import Service from 'containers/Service/Loadable';
import Article from 'containers/Article/Loadable';
import FAQ from 'containers/FAQ/Loadable';
import EditorList from 'containers/EditorList/Loadable';
import Book from 'containers/Book/Loadable';
import Person from 'containers/Person/Loadable';
import Program from 'containers/Program/Loadable';
import Series from 'containers/Series/Loadable';
import Course from 'containers/Course/Loadable';
import Practices from 'containers/Practices/Loadable';
import { getTypeUrl } from 'components/ResourceItemSelector/utils';
import {
  articleTypesInClientExcludedResources,
  filterArticleTypesExcluded,
} from 'components/Hooks/useExcludedArticleTypes';
import { formatSeriesContentfulEntries } from 'containers/Series/utils';
import { formatCourseContentfulEntries } from 'containers/Course/utils';
import { formatPracticeContentfulEntries } from 'containers/Practices/utils';
import { getClientArticleSettings, ARTICLE_TYPES } from 'utils/clientSettings';

const getClientInfo = clientDetails => {
  const clientId = _get(clientDetails, 'sys.id');
  const clientShortName = _get(clientDetails, 'shortName');
  const clientGroupShortName = _get(clientDetails, 'clientGroup.shortName');
  const clientExcludedTopic =
    _get(clientDetails, 'excludeTopicCollection.items') || [];
  const clientExcludedResourceTypes =
    _get(clientDetails, 'excludeResourceTypes') || [];
  const averageRatingCutoff = _get(clientDetails, 'averageRatingCutoff');
  const expertRatingCutoff = _get(clientDetails, 'expertRatingCutoff');
  const userRatingCutoff = _get(clientDetails, 'userRatingCutoff');
  const excludedAssessments = _get(
    clientDetails,
    'excludeAssessmentCollection.items',
  );
  const clientAudience = _get(clientDetails, 'audience');
  const metadata = _get(clientDetails, 'metadata', {});
  return {
    clientId,
    clientShortName,
    clientGroupShortName,
    clientExcludedTopic,
    clientExcludedResourceTypes,
    averageRatingCutoff,
    expertRatingCutoff,
    userRatingCutoff,
    excludedAssessments,
    clientAudience,
    metadata,
  };
};

export const getDataSlug = (data, type) => {
  if (type === 'podcasts') {
    return _get(data, 'ctf.items.0.fields.slug');
  }
  if (type === 'faqs') {
    return _get(data, 'faqInfo.items.0.fields.slug');
  }

  return _get(data, 'items.0.fields.slug');
};

const filterEntry = ({ item, clientInfo, audienceTagsRelations }) => {
  if (_get(item, 'sys.contentType.sys.id') === 'organization') return true;

  if (
    _isEmpty(
      filterArticleTypesExcluded(
        item,
        new Set(clientInfo.clientExcludedResourceTypes),
        clientInfo,
      ),
    )
  )
    return false;

  const clients = _get(item, 'fields.client', []).map(client =>
    _get(client, 'fields.shortName'),
  );
  if (!_isEmpty(clients)) {
    if (
      clients.includes(clientInfo.clientShortName) ||
      clients.includes(clientInfo.clientGroupShortName)
    )
      return true;
    return false;
  }

  const clientsExclude = _get(item, 'fields.clientExclude', []).map(client =>
    _get(client, 'fields.shortName'),
  );
  if (clientsExclude.includes(clientInfo.clientShortName)) return false;

  if (!_isEmpty(clientInfo.clientAudience)) {
    const audienceRelations = _get(audienceTagsRelations, [
      clientInfo.clientAudience.name,
      0,
    ]);

    const audienceExclude = _get(audienceRelations, 'exclude', []).map(
      el => el.id,
    );
    const itemAudiences = _get(item, 'fields.audienceType', []).map(audience =>
      _get(audience, 'sys.id'),
    );
    if (
      _get(audienceRelations, 'exclusive') === 'Yes' &&
      !itemAudiences.includes(clientInfo.clientAudience.sys.id)
    )
      return false;

    if (
      _get(audienceRelations, 'exclusive') !== 'Yes' &&
      _some(itemAudiences, audience => audienceExclude.includes(audience))
    )
      return false;
  }

  if (
    ['articles', 'faqs', 'services', 'lists'].includes(
      _get(item, 'sys.contentType.sys.id'),
    )
  )
    return true;

  if (
    _get(item, 'fields.calculatedExpertRating', 5) <
    clientInfo.expertRatingCutoff
  )
    return false;
  if (
    _get(item, 'fields.calculatedAverageRating', 5) <
    clientInfo.averageRatingCutoff
  )
    return false;
  if (
    _get(item, 'fields.calculatedUserRating', 5) < clientInfo.userRatingCutoff
  )
    return false;

  return true;
};

export const mapEntryWithTranslationLink = item => {
  const description = _get(item, 'fields.translationLink.fields.description');
  const readMore = _get(item, 'fields.translationLink.fields.readMore');
  return {
    ...item,
    fields: {
      ...item.fields,
      ...(description ? { description } : {}),
      ...(readMore ? { readMore } : {}),
    },
  };
};

export const defaultResourceQueryFn = async (
  _key,
  {
    ids,
    contentType,
    clientDetails,
    path,
    routeParams,
    isTopic,
    reviewStatus,
    filters,
    localeFilters,
    audienceTagsRelations,
  },
) => {
  const [, resourceTypePath] = path.split('/');
  const clientInfo = getClientInfo(clientDetails);

  const { slug } = routeParams;
  const { clientExcludedResourceTypes, clientExcludedTopic } = clientInfo;
  if (contentType === 'topics') {
    const clientExcludedTopicSlugs = clientExcludedTopic.map(topic =>
      _get(topic, 'slug'),
    );
    if (clientExcludedTopicSlugs.includes(slug)) return {};
  }
  // decide after data is fetched if excluded types is an article type e.g. Insights, News, Articles
  if (
    _some(
      clientExcludedResourceTypes,
      type =>
        getTypeUrl(type).includes(resourceTypePath) &&
        !articleTypesInClientExcludedResources.has(type),
    )
  ) {
    return {};
  }

  let contentfulFilters;
  if (filters) {
    contentfulFilters = filters;
  } else {
    contentfulFilters = getContenfulFilters(
      clientExcludedTopic,
      0,
      0,
      0,
      isTopic,
    );
  }

  const payload = {
    content_type: contentType,
    include: 2,
    ...reviewStatus,
    ...contentfulFilters,
    ...localeFilters,
  };

  const getEntriesQuery = filter => ctf.getEntries({ ...filter, ...payload });
  const queries = [];

  // ? We need a checker if the query with ID is the resource intended to get
  if (!_isEmpty(ids))
    queries.push(getEntriesQuery({ 'sys.id[in]': ids.join(',') }));
  if (slug) queries.push(getEntriesQuery({ 'fields.slug': slug }));

  const results = await Promise.allSettled(queries);
  const response = getQueryResult({ results, ids });
  const filteredItems = response?.items?.filter(item =>
    filterEntry({ item, clientInfo, audienceTagsRelations }),
  );

  // set filtered items description to translationLink fields description if available
  const updatedItems = filteredItems.map(mapEntryWithTranslationLink);
  return {
    ...response,
    items: updatedItems,
  };
};

export const defaultIsDataEqualFn = (oldData, newData) => {
  const oldDataId = _get(oldData, 'items.0.sys.id');
  const oldDataPublish = _get(oldData, 'items.0.sys.updatedAt');
  const oldDataLocale = _get(oldData, 'items.0.sys.locale');
  const newDataId = _get(newData, 'items.0.sys.id');
  const newDataPublish = _get(newData, 'items.0.sys.updatedAt');
  const newDataLocale = _get(newData, 'items.0.sys.locale');

  return (
    oldDataId &&
    newDataId &&
    oldDataId === newDataId &&
    oldDataPublish &&
    newDataPublish &&
    newDataPublish === oldDataPublish &&
    oldDataLocale &&
    newDataLocale &&
    oldDataLocale === newDataLocale
  );
};

export const podcastQueryFn = async (key, params) => {
  const response = await defaultResourceQueryFn(key, params);

  const item = _get(response, 'items.0');
  if (item && item.fields && item.fields.listenNotesId && item.fields.type) {
    const endpoint = item.fields.type === 'Episode' ? 'episodes' : 'podcasts';
    const { API_URL, API_KEY } = Config.LISTEN_NOTES;
    const apiUrl = `${API_URL}/${endpoint}/${item.fields.listenNotesId}`;
    try {
      const listenNotesResponse = await get(apiUrl, {
        headers: { 'X-ListenAPI-Key': API_KEY },
      });

      return {
        ctf: response,
        listenNotes: listenNotesResponse.data,
      };
    } catch (e) {
      return {
        ctf: response,
      };
    }
  }

  return {
    ctf: response,
  };
};

export const organizationQueryFn = async (key, params) =>
  defaultResourceQueryFn(key, { ...params, filters: {} });

const coursesQueryFn = async (key, params) => {
  const response = await defaultResourceQueryFn(key, {
    ...params,
    filters: { include: 4 },
  });

  return formatCourseContentfulEntries(response);
};

const practicesQueryFn = async (key, params) => {
  const response = await defaultResourceQueryFn(key, {
    ...params,
    filters: { include: 4 },
  });

  return formatPracticeContentfulEntries(response);
};

export const personQueryFn = async (key, params) => {
  const clientExcludedTopic = _get(
    params.clientDetails,
    'excludeTopicCollection.items',
  );
  const filters = getContenfulFilters(clientExcludedTopic, '', '', '', true);

  return defaultResourceQueryFn(key, { ...params, filters });
};

export const serviceQueryFn = async (key, params) =>
  defaultResourceQueryFn(key, { ...params, filters: {} });

export const topicsQueryFn = async (key, params) => {
  const filters = {
    select: topicRequiredFields,
  };

  const response = await defaultResourceQueryFn(key, {
    ...params,
    filters,
    reviewStatus: {},
  });

  return response;
};

export const faqQueryFn = async (key, params) => {
  const { reviewStatus, localeFilters, clientDetails } = params;
  const { clientExcludedTopic } = getClientInfo(clientDetails);
  const filters = getContenfulFilters(clientExcludedTopic, '', '', '', false);

  const [faqInfo, resourceList] = await Promise.all([
    defaultResourceQueryFn(key, {
      ...params,
      filters: {
        'fields.type': 'FAQ',
        ...filters,
      },
    }),
    ctf.getEntries({
      content_type: 'list',
      'fields.type': 'Global',
      'fields.slug': 'get-the-facts',
      include: 2,
      ...reviewStatus,
      ...filters,
      ...localeFilters,
    }),
  ]);

  return { faqInfo, resourceList };
};

export const listQueryFn = async (key, params) => {
  const { clientDetails, localeFilters, routeParams } = params;
  const { topic } = routeParams;
  const {
    clientId,
    clientExcludedTopic,
    averageRatingCutoff,
    expertRatingCutoff,
    userRatingCutoff,
    excludedAssessments,
    clientShortName,
  } = getClientInfo(clientDetails);

  const filters = getContenfulFilters(
    clientExcludedTopic,
    averageRatingCutoff,
    expertRatingCutoff,
    userRatingCutoff,
    false,
    true,
  );

  const response = await defaultResourceQueryFn(key, {
    ...params,
    filters,
  });

  const entry = response.items[0];
  const isGlobal = _get(entry, 'fields.type') === 'Global';
  if (isGlobal && !topic) {
    return {};
  }

  if (entry) {
    if (_has(entry, 'fields.client')) {
      const entryClientIds = (_get(entry, 'fields.client') || []).map(
        client => client.sys.id,
      );
      if (!entryClientIds.includes(clientId)) return null;
    }
    if (topic) {
      const topicResponse = await ctf.getEntries({
        content_type: 'topics',
        'fields.slug': topic,
        ...localeFilters,
      });
      const topicItem = _get(topicResponse, 'items.0');
      if (topicItem) {
        entry.fields.topics = [topicItem];
      } else {
        return null;
      }
    }

    let finalResources = filterClientExclusiveResources({
      resources: _get(entry, 'fields.resources', []),
      clientShortName,
      clientGroupShortName: _get(clientDetails, 'clientGroup.shortName'),
    });
    if (!_isEmpty(excludedAssessments)) {
      const excludedAssessmentIDs = excludedAssessments.map(
        assessment => assessment.sys.id,
      );
      finalResources = finalResources.filter(
        field => !excludedAssessmentIDs.includes(_get(field, 'sys.id')),
      );
    }
    if (!_isEmpty(clientExcludedTopic)) {
      const excludedTopicsSlugs = clientExcludedTopic.map(item => item.slug);

      if (excludedTopicsSlugs.length > 0) {
        finalResources = finalResources.filter(resource => {
          const resourceTopics = _get(resource, 'fields.topics', []).map(item =>
            _get(item, 'fields.slug'),
          );
          return !_some(resourceTopics, item =>
            excludedTopicsSlugs.includes(item),
          );
        });
      }
    }
    entry.fields.resources = finalResources;
  }

  return response;
};

const articlesQueryFn = async (
  key,
  {
    contentType,
    reviewStatus,
    localeFilters,
    routeParams,
    clientDetails,
    path,
    audienceTagsRelations,
  },
) => {
  const { slug } = routeParams;
  const payload = {
    content_type: contentType,
    include: 2,
    'fields.slug': slug,
    ...reviewStatus,
    ...localeFilters,
  };

  const response = await ctf.getEntries(payload);
  let { items } = response;
  const data = items[0];

  const [, resourceTypePath] = path.split('/');
  if (_get(items[0], 'fields.type') !== 'Embedded Content') {
    const clientInfo = getClientInfo(clientDetails);
    const { clientExcludedResourceTypes, clientExcludedTopic } = clientInfo;
    const isAnyTopicExcluded = clientExcludedTopic.some(topic =>
      _get(data, 'fields.topics', []).some(
        articleTopic =>
          _get(articleTopic.fields, 'slug') === _get(topic, 'slug'),
      ),
    );
    // Adjust the resource path for articles to avoid returning 404 if only certain article types are excluded.
    // This is necessary because we use /articles for news but differentiate by type in client excluded types.
    let finalResourcePath = resourceTypePath;
    if (resourceTypePath === 'articles') {
      const type = _get(data, 'fields.type', '').toLowerCase();
      const { alwaysShowInformationalLink } = getClientArticleSettings(
        clientInfo,
      );

      // add more exception types here if needed, even if articles are exclude, client want to still show some based on type
      const excludedArticlesExceptionTypes = alwaysShowInformationalLink
        ? [ARTICLE_TYPES.INFORMATION_LINK.toLowerCase()]
        : [];
      finalResourcePath =
        type === 'news'
          ? 'news'
          : excludedArticlesExceptionTypes.includes(type)
          ? '_exceptions_'
          : resourceTypePath;
    }

    if (
      isAnyTopicExcluded ||
      clientExcludedResourceTypes.some(type =>
        getTypeUrl(type).includes(finalResourcePath),
      )
    )
      return {};

    items = response.items.filter(item =>
      filterEntry({ item, clientInfo, audienceTagsRelations }),
    );
  }
  return {
    ...response,
    items: items.map(mapEntryWithTranslationLink),
  };
};

const seriesQueryFn = async (key, params) => {
  const response = await defaultResourceQueryFn(key, {
    ...params,
    reviewStatus: {
      'fields.reviewStatus[in]': 'Accepted,Peer Review,Save for Later',
    },
  });

  return formatSeriesContentfulEntries(response);
};

const faqIsDataEqualFn = (oldData, newData) => {
  const oldFaqInfo = _get(oldData, 'faqInfo');
  const newFaqInfo = _get(newData, 'faqInfo');
  return defaultIsDataEqualFn(oldFaqInfo, newFaqInfo);
};

const listIsDataEqualFn = (oldData, newData) => {
  const oldListData = _get(oldData, 'items.0');
  const newListData = _get(newData, 'items.0');

  if (!defaultIsDataEqualFn(oldListData, newListData)) return false;

  return (
    _get(newListData, 'fields.type') !== 'Global' ||
    _get(oldListData, 'fields.topics.0.fields.title') ===
      _get(newListData, 'fields.topics.0.fields.title')
  );
};

const podcastIsDataEqualFn = (oldData, newData) => {
  const oldPodcastInfo = _get(oldData, 'ctf');
  const newPodcastInfo = _get(newData, 'ctf');
  return defaultIsDataEqualFn(oldPodcastInfo, newPodcastInfo);
};

// ? This will filter the result of the 2 queries
const getQueryResult = ({ results, ids = [] }) => {
  const fulfilledResult = results.find(result => result.status === 'fulfilled');
  if (!_isEmpty(ids) && fulfilledResult) {
    const items = _get(fulfilledResult, 'value.items', []);
    return items.length > 0
      ? _get(fulfilledResult, 'value', {})
      : _get(results, '1.value', {});
  }
  return _get(fulfilledResult, 'value', {});
};

export const resourcesMap = {
  topics: {
    contentType: 'topics',
    Component: TopicCenter,
    queryFn: topicsQueryFn,
  },
  videos: {
    contentType: 'video',
    Component: Video,
  },
  apps: {
    contentType: 'application',
    Component: App,
  },
  podcasts: {
    contentType: 'podcast',
    Component: Podcast,
    queryFn: podcastQueryFn,
    isDataEqual: podcastIsDataEqualFn,
  },
  books: {
    contentType: 'book',
    Component: Book,
  },
  organizations: {
    contentType: 'organization',
    Component: Organization,
    queryFn: organizationQueryFn,
  },
  services: {
    contentType: 'services',
    Component: Service,
    queryFn: serviceQueryFn,
  },
  articles: {
    contentType: 'activity',
    Component: Article,
    queryFn: articlesQueryFn,
  },
  blogs: {
    contentType: 'activity',
    Component: Article,
  },
  faqs: {
    contentType: 'source',
    Component: FAQ,
    queryFn: faqQueryFn,
    isDataEqual: faqIsDataEqualFn,
  },
  lists: {
    contentType: 'list',
    Component: EditorList,
    queryFn: listQueryFn,
    isDataEqual: listIsDataEqualFn,
  },
  people: {
    contentType: 'person',
    Component: Person,
    queryFn: personQueryFn,
  },
  programs: {
    contentType: 'onlineProgram',
    Component: Program,
  },
  insights: {
    contentType: 'activity',
    Component: Article,
  },
  series: {
    contentType: 'series',
    Component: Series,
    queryFn: seriesQueryFn,
  },
  courses: {
    contentType: 'courses',
    Component: Course,
    queryFn: coursesQueryFn,
  },
  practices: {
    contentType: 'practice',
    Component: Practices,
    queryFn: practicesQueryFn,
  },
};
