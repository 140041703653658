import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '../images/searchIcon.svg';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    width: 'fit-content',
    margin: 4,
    borderRadius: 24,
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    padding: '2px 16px',
  },
  label: {
    fontSize: 14,
    lineHeight: '22px',
    color: theme.palette.primary.main,
  },
  icon: {
    height: 14,
    width: 14,
    marginRight: 8,
  },
}));

export function SuggestionPill({ hasIcon, onClick, text }) {
  const classes = useStyles();

  return (
    <ButtonBase
      onClick={onClick}
      aria-label={`Search ${text}`}
      className={classes.container}
    >
      {hasIcon && <img src={SearchIcon} alt="" className={classes.icon} />}
      <Typography className={classes.label}>{text}</Typography>
    </ButtonBase>
  );
}

SuggestionPill.propTypes = {
  hasIcon: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

export default SuggestionPill;
