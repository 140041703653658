/**
 *
 * ReadMoreLess
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import _isUndefined from 'lodash/isUndefined';
import parse from 'html-react-parser';
import Colors from 'utils/colors';
import ButtonBase from '@material-ui/core/ButtonBase';
import Mixpanel from 'utils/mixpanelService';

const styles = theme => ({
  readLessText: {
    color: Colors.link,
    cursor: 'pointer',
  },
  readMoreText: {
    color: Colors.link,
    cursor: 'pointer',
    marginLeft: 10,
  },
  textWrapper: {
    color: theme.palette.primary.contrastText,
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.46429em',
    textAlign: props => props.textAlign || 'left',
    fontFamily: 'MadaRegular',
  },
  text: {
    fontSize: 17,
    lineHeight: 1.35,
    '&>p': {
      display: 'inline',
      wordBreak: 'break-word',
    },
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    '& .embedded-link': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    '& .embedded-link-inactive': {
      color: 'rgba(0, 0, 0, 0.87)',
      textDecoration: 'none',
      cursor: 'default',
      pointerEvents: 'none',
    },
    '& li br': {
      display: 'none',
    },
    '& img': {
      maxWidth: '100%',
    },
  },
  readMoreBtn: {
    width: 100,
    height: 30,
    borderRadius: 2,
    border: '1px solid #E3E3E3',
    color: '#0862C0',
    ...theme.typography.button,
    fontSize: '0.65rem',
    float: 'right',
  },
});

class ReadMoreLess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      charLimit: props.charLimit,
    };
    this.initialState = this.state;
    this.showLongText = this.showLongText.bind(this);
    this.showShortText = this.showShortText.bind(this);
  }

  getCleanHTML = children => children.substr(0, children.length - 10);

  getReadMoreContent() {
    const { charLimit } = this.state;
    const {
      children,
      readMoreText,
      readLessText,
      classes,
      hideReadText,
      withButton,
      buttonHandler,
    } = this.props;

    if (_isUndefined(charLimit) || charLimit === 0) {
      if (withButton) {
        const finalChildren = this.getCleanHTML(children);
        return (
          <span className={classes.text}>
            {parse(finalChildren)}
            <ButtonBase onClick={buttonHandler} className={classes.readMoreBtn}>
              READ MORE
            </ButtonBase>
          </span>
        );
      }
      return <span className={classes.text}>{parse(children)} </span>;
    }
    if (children.length > charLimit) {
      const cutHtmlStart = children.substr(0, charLimit);
      const cutHtmlEnd = cutHtmlStart.substr(-5); // clean last five chars so tags won't display
      const cleanHtmlEnd = cutHtmlEnd.replace(/<(?:.|\n)*?>/gm, '');
      const combinedCleanedHtml = `${cutHtmlStart.substr(
        0,
        cutHtmlStart.length - 5,
      )}${cleanHtmlEnd}`;
      return (
        <span className={classes.text}>
          {parse(combinedCleanedHtml)}...
          {!hideReadText && (
            <span
              className={classes.readMoreText}
              role="presentation"
              onClick={this.showLongText}
            >
              {readMoreText}
            </span>
          )}
        </span>
      );
    }
    if (children.length < charLimit) {
      if (withButton) {
        const finalChildren = this.getCleanHTML(children);
        return (
          <span className={classes.text}>
            {parse(finalChildren)}
            <ButtonBase onClick={buttonHandler} className={classes.readMoreBtn}>
              READ LESS
            </ButtonBase>
          </span>
        );
      }
      return <span className={classes.text}>{parse(children)}</span>;
    }
    return (
      <span className={classes.text}>
        {parse(children)}
        {!hideReadText && (
          <span
            className={classes.readLessText}
            role="presentation"
            onClick={this.showShortText}
          >
            {readLessText}
          </span>
        )}
      </span>
    );
  }

  showLongText() {
    if (this.props.redirect) {
      this.props.history.push(this.props.redirect);
    } else {
      const { children, name, contentType, subtype, blog } = this.props;
      if (contentType) {
        Mixpanel.track('Resource Page - Read More', {
          name,
          type: contentType,
          subtype,
          blog,
        });
      }

      this.setState({ charLimit: children.length });
      this.getReadMoreContent();
    }
  }

  showShortText() {
    this.setState(this.initialState);
    this.getReadMoreContent();
  }

  render() {
    return (
      <div className={this.props.classes.textWrapper}>
        {this.getReadMoreContent()}
      </div>
    );
  }
}

ReadMoreLess.propTypes = {
  charLimit: PropTypes.number,
  readMoreText: PropTypes.string,
  readLessText: PropTypes.string,
  children: PropTypes.string.isRequired,
  classes: PropTypes.object,
  redirect: PropTypes.string,
  history: PropTypes.object,
  // eslint-disable-next-line react/no-unused-prop-types
  textAlign: PropTypes.string,
  name: PropTypes.string,
  contentType: PropTypes.string,
  hideReadText: PropTypes.bool,
  withButton: PropTypes.bool,
  blog: PropTypes.bool,
  subtype: PropTypes.string,
  buttonHandler: PropTypes.func,
};
export default compose(
  withStyles(styles),
  withRouter,
)(ReadMoreLess);
