import React, { createContext, useState, useContext, useRef } from 'react';
import Mixpanel from 'utils/mixpanelService';
import { useLocation } from 'react-router-dom';

const MobileAudioPlayerContext = createContext();

export const MobileAudioPlayerProvider = ({ children }) => {
  const location = useLocation();
  const [currentPodcast, setCurrentPodcast] = useState(null);
  const [currentSeries, setCurrentSeries] = useState(null);
  const [podcastsProgress, setPodcastsProgress] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const handleUpdate = useRef();

  const playPodcast = (
    { series, podcast, resourcesProgress, onProgressUpdate },
    fromLockScreen,
  ) => {
    const pageName = location.pathname.split('/')[1];
    const finalPageName = pageName ? `${pageName}Page` : 'homePage';
    const view = fromLockScreen ? 'lock screen' : isExpanded ? 'full' : 'bar';

    setCurrentPodcast(podcast);
    setCurrentSeries(series);
    if (resourcesProgress) {
      setPodcastsProgress(resourcesProgress);
    }
    if (onProgressUpdate) {
      handleUpdate.current = onProgressUpdate;
    }
    Mixpanel.track('Resource - Play', {
      name: podcast?.fields?.title,
      format: 'audio',
      view,
      page: finalPageName,
      entryId: podcast?.sys?.id,
      path: 'series',
    });
  };

  const closePodcast = () => {
    setCurrentPodcast(null);
    setCurrentSeries(null);
    setPodcastsProgress(null);
    handleUpdate.current = null;
  };

  return (
    <MobileAudioPlayerContext.Provider
      value={{
        series: currentSeries,
        podcast: currentPodcast,
        podcastsProgress,
        setPodcastsProgress,
        handleUpdate: handleUpdate.current,
        isPlaying,
        isExpanded,
        setIsExpanded,
        setIsPlaying,
        playPodcast,
        closePodcast,
      }}
    >
      {children}
    </MobileAudioPlayerContext.Provider>
  );
};

export const useMobileAudioPlayer = () => useContext(MobileAudioPlayerContext);
