import React from 'react';

export const WatchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    stroke="currentColor"
  >
    <g clipPath="url(#clip0_26652_11425)">
      <path
        d="M0.55896 1.05833C0.559005 0.973131 0.580819 0.88936 0.622332 0.814963C0.663845 0.740565 0.723679 0.678009 0.796157 0.63323C0.868635 0.58845 0.951354 0.562933 1.03646 0.5591C1.12157 0.555267 1.20625 0.573244 1.28246 0.611327L11.1645 5.55233C11.2474 5.5939 11.3171 5.65772 11.3659 5.73666C11.4146 5.81561 11.4404 5.90655 11.4404 5.99933C11.4404 6.0921 11.4146 6.18305 11.3659 6.26199C11.3171 6.34093 11.2474 6.40476 11.1645 6.44633L1.28246 11.3873C1.20625 11.4254 1.12157 11.4434 1.03646 11.4396C0.951354 11.4357 0.868635 11.4102 0.796157 11.3654C0.723679 11.3206 0.663845 11.2581 0.622332 11.1837C0.580819 11.1093 0.559005 11.0255 0.55896 10.9403V1.05833Z"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_26652_11425">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
