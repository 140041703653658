import update from 'immutability-helper';
import _flatten from 'lodash/flatten';
import _get from 'lodash/get';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _some from 'lodash/some';
import { getResultsVisibility } from 'containers/Assessment/utils';
import { getLocalData } from 'utils/localDataStore';

export const parseConfig = (siteConfig, field, clientDetails) => {
  const config = update(
    update(_get(siteConfig, ['config', 'profile', field], {}), {
      $merge: _get(
        siteConfig,
        [
          'config',
          _get(clientDetails, 'clientGroup.shortName'),
          'profile',
          field,
        ],
        {},
      ),
    }),
    {
      $merge: _get(
        siteConfig,
        ['config', clientDetails.shortName, 'profile', field],
        {},
      ),
    },
  );

  return config;
};

export const parseAdminTagConfig = (siteConfig, clientDetails, userId) => {
  const customConfigUserIds = update(
    _get(
      siteConfig,
      [
        'config',
        _get(clientDetails, 'clientGroup.shortName'),
        'admin',
        'custom',
        'userIds',
      ],
      [],
    ),
    {
      $push: _get(
        siteConfig,
        ['config', clientDetails.shortName, 'admin', 'custom', 'userIds'],
        [],
      ),
    },
  );

  if (customConfigUserIds.includes(userId)) {
    const config = update(
      _get(
        siteConfig,
        [
          'config',
          _get(clientDetails, 'clientGroup.shortName'),
          'admin',
          'custom',
          'tag',
        ],
        [],
      ),
      {
        $push: _get(
          siteConfig,
          ['config', clientDetails.shortName, 'admin', 'custom', 'tag'],
          [],
        ),
      },
    );

    return config;
  }

  const config = update(
    _get(
      siteConfig,
      [
        'config',
        _get(clientDetails, 'clientGroup.shortName'),
        'admin',
        'default',
        'tag',
      ],
      [],
    ),
    {
      $push: _get(
        siteConfig,
        ['config', clientDetails.shortName, 'admin', 'default', 'tag'],
        [],
      ),
    },
  );

  return config;
};

export const getFiltersFromProfile = ({
  profile,
  isClientAdmin,
  insuranceOptions,
  enforcedInsuranceOptions,
  audienceTagsRelations,
  profileConfig,
  insuranceLabel,
  locale,
  isStateActive,
  clientAudience,
  adminConfig,
}) => {
  if (profile.isEmpty) {
    const audiences = clientAudience
      ? [
          {
            label: _get(clientAudience, 'name'),
            value: _get(clientAudience, 'name'),
            id: _get(clientAudience, 'sys.id'),
            exclusive:
              _get(audienceTagsRelations, [
                _get(clientAudience, 'name'),
                0,
                'exclusive',
              ]) === 'Yes',
            exclude: _get(
              audienceTagsRelations,
              [_get(clientAudience, 'name'), 0, 'exclude'],
              [],
            ),
            clientAudience: true,
          },
        ]
      : [];
    const [
      excludedAudiences,
      excludedAudiencesIds,
    ] = getExclusionDataFromProfile(audiences, audienceTagsRelations);
    const insuranceTags = getDataFromInsurance(
      insuranceOptions,
      enforcedInsuranceOptions,
      {
        insurance: getLocalData('insurance') || [],
      },
      audienceTagsRelations,
      insuranceLabel,
    );

    return {
      audiences,
      tags: [],
      insuranceTags,
      excludedAudiences,
      excludedAudiencesIds,
      excludedTags: [],
      excludedTagsIds: [],
      state: isStateActive ? 'all' : null,
    };
  }

  const audiences = getDataFromProfile(
    'audience',
    profileConfig,
    profile,
    locale,
    audienceTagsRelations,
  );
  if (clientAudience) {
    audiences.push({
      label: _get(clientAudience, 'name'),
      value: _get(clientAudience, 'name'),
      id: _get(clientAudience, 'sys.id'),
      exclusive:
        _get(audienceTagsRelations, [
          _get(clientAudience, 'name'),
          0,
          'exclusive',
        ]) === 'Yes',
      exclude: _get(
        audienceTagsRelations,
        [_get(clientAudience, 'name'), 0, 'exclude'],
        [],
      ),
      clientAudience: true,
    });
  }
  const tags = getDataFromProfile(
    'tag',
    profileConfig,
    profile,
    locale,
    audienceTagsRelations,
  );
  const insuranceTags = getDataFromInsurance(
    insuranceOptions,
    enforcedInsuranceOptions,
    profile,
    audienceTagsRelations,
    insuranceLabel,
  );

  const [excludedAudiences, excludedAudiencesIds] = getExclusionDataFromProfile(
    audiences.filter(item => item.clientAudience || item.shouldFilter),
    audienceTagsRelations,
  );
  const [excludedTags, excludedTagsIds] = getExclusionDataFromProfile(
    tags,
    audienceTagsRelations,
  );

  let state = null;
  if (isStateActive && profile.stateOfResidence) {
    state = profile.stateOfResidence.toUpperCase();
  }

  if (
    profile.role === 'admin' ||
    profile.role === 'contentAdmin' ||
    isClientAdmin
  ) {
    const adminTags = adminConfig
      .map(id => {
        const [tag] = _get(
          audienceTagsRelations,
          Object.keys(audienceTagsRelations).find(
            title => _get(audienceTagsRelations, [title, 0, 'id']) === id,
          ),
          [],
        );
        if (tag) {
          return {
            label: _get(tag, 'title'),
            value: _get(tag, 'title'),
            id: _get(tag, 'id'),
          };
        }
        return null;
      })
      .filter(Boolean);
    tags.push(...adminTags);
  }

  return {
    audiences,
    tags,
    insuranceTags,
    excludedAudiences,
    excludedAudiencesIds,
    excludedTags,
    excludedTagsIds,
    state,
  };
};

const getDataFromProfile = (
  type,
  config,
  profile,
  locale,
  audienceTagsRelations,
) => {
  const data = [];

  Object.keys(_get(config, type, {})).forEach(key => {
    const value = _get(profile, key);
    if (Array.isArray(_get(config, [type, key, 'items']))) {
      const matches = _get(config, [type, key, 'items']).filter(item =>
        getResultsVisibility(item.condition, profile),
      );
      matches.forEach(item => {
        data.push({
          label: _get(config, [type, key, 'label', locale], '').replace(
            '<value>',
            _get(item, ['label', locale]),
          ),
          value: _get(item, ['label', locale]),
          shouldFilter: _get(config, [type, key, 'shouldFilter'], false),
        });
      });
    } else if (
      Array.isArray(value) &&
      _some(value, item =>
        Object.keys(_get(config, [type, key, 'items'], {})).includes(item),
      )
    ) {
      value.forEach(item => {
        if (
          Object.keys(_get(config, [type, key, 'items'], {})).includes(item)
        ) {
          if (_get(config, [type, key, 'items', item, locale])) {
            data.push({
              label: _get(config, [type, key, 'label', locale], '').replace(
                '<value>',
                _get(config, [type, key, 'items', item, locale]),
              ),
              value: _get(config, [type, key, 'items', item, locale]),
              shouldFilter: _get(config, [type, key, 'shouldFilter'], false),
            });
          } else {
            value.forEach(tagValue => {
              const [tag] = _get(
                audienceTagsRelations,
                Object.keys(audienceTagsRelations).find(
                  title =>
                    _get(audienceTagsRelations, [title, 0, 'id']) ===
                    _get(config, [type, key, 'items', tagValue, 'id']),
                ),
                [],
              );
              if (tag) {
                data.push({
                  value: _get(tag, 'title'),
                  id: _get(tag, 'id'),
                  shouldFilter: _get(
                    config,
                    [type, key, 'shouldFilter'],
                    false,
                  ),
                });
              }
            });
          }
        }
      });
    } else if (
      Object.keys(_get(config, [type, key, 'items'], {})).includes(value)
    ) {
      if (
        _has(config, [type, key, 'items', value, 'filters']) &&
        typeof _get(config, [type, key, 'items', value, 'filters']) === 'object'
      ) {
        const filtersResult = Object.keys(
          _get(config, [type, key, 'items', value, 'filters']),
        )
          .map(filterKey =>
            _get(config, [
              type,
              key,
              'items',
              value,
              'filters',
              filterKey,
            ]).includes(_get(profile, filterKey)),
          )
          .reduce(
            (accumulator, currentValue) => accumulator && currentValue,
            true,
          );
        if (filtersResult) {
          const tag = _get(
            audienceTagsRelations,
            Object.keys(audienceTagsRelations).find(
              title =>
                _get(audienceTagsRelations, [title, 0, 'id']) ===
                _get(config, [type, key, 'items', value, 'id']),
            ),
          );
          if (!_isEmpty(tag)) {
            data.push({
              value: _get(tag, '0.title'),
              id: _get(tag, '0.id'),
              shouldFilter: _get(config, [type, key, 'shouldFilter'], false),
            });
          }
        }
      } else if (_get(config, [type, key, 'items', value, 'id'])) {
        const tag = _get(
          audienceTagsRelations,
          Object.keys(audienceTagsRelations).find(
            title =>
              _get(audienceTagsRelations, [title, 0, 'id']) ===
              _get(config, [type, key, 'items', value, 'id']),
          ),
        );
        if (!_isEmpty(tag)) {
          data.push({
            value: _get(tag, '0.title'),
            id: _get(tag, '0.id'),
            shouldFilter: _get(config, [type, key, 'shouldFilter'], false),
          });
        }
      } else {
        const [tag] = _get(
          audienceTagsRelations,
          _get(config, [type, key, 'items', value, locale]),
          [],
        );
        data.push({
          label: _get(config, [type, key, 'label', locale], '').replace(
            '<value>',
            _get(config, [type, key, 'items', value, locale]),
          ),
          value: _get(config, [type, key, 'items', value, locale]),
          id: _get(tag, 'id'),
          shouldFilter: _get(config, [type, key, 'shouldFilter'], false),
        });
      }
    }
  });

  return data;
};

const getDataFromInsurance = (
  options,
  enforcedOptions,
  profile,
  audienceTagsRelations,
  insuranceLabel,
) => {
  const data = [];
  const parsedRelations = _flatten(Object.values(audienceTagsRelations));

  if (!_isEmpty(enforcedOptions)) {
    enforcedOptions.forEach(option => {
      const [relation] = parsedRelations.filter(item =>
        option.tags.includes(item.id),
      );
      if (relation) {
        data.push({
          label: insuranceLabel.replace('<value>', relation.title),
          value: relation.title,
          id: relation.id,
        });
      }
    });
  }

  if (_isEmpty(_get(profile, 'insurance', []))) {
    const [defaultOption] = options.filter(item => item.default);
    if (defaultOption) {
      const [relation] = parsedRelations.filter(item =>
        defaultOption.tags.includes(item.id),
      );
      if (relation) {
        data.push({
          label: insuranceLabel.replace('<value>', relation.title),
          value: relation.title,
          id: relation.id,
        });
      }
    }
  } else {
    const matchedOptions = options.filter(item =>
      _get(profile, 'insurance', []).includes(item.value),
    );

    if (insuranceLabel) {
      matchedOptions.forEach(option => {
        const [relation] = parsedRelations.filter(item =>
          option.tags.includes(item.id),
        );
        if (relation) {
          data.push({
            label: insuranceLabel.replace('<value>', relation.title),
            value: relation.title,
            id: relation.id,
          });
        }
      });
    }
  }

  return data;
};

export const getProfileFiltersFromAnswers = ({
  audiences,
  tags,
  audienceTagsRelations,
  insuranceOptions,
  enforcedInsuranceOptions,
  insuranceLabel,
  state,
}) => {
  const insuranceTags = getDataFromInsurance(
    insuranceOptions,
    enforcedInsuranceOptions,
    {
      insurance: getLocalData('insurance') || [],
    },
    audienceTagsRelations,
    insuranceLabel,
  );

  const [excludedAudiences] = getExclusionDataFromProfile(
    audiences,
    audienceTagsRelations,
  );
  const [excludedTags] = getExclusionDataFromProfile(
    tags,
    audienceTagsRelations,
  );

  return {
    audiences,
    tags,
    insuranceTags,
    excludedAudiences,
    excludedTags,
    state: typeof state === 'string' ? state.toUpperCase() : state,
  };
};

const getExclusionDataFromProfile = (data, relations) => {
  const exclusionData = _flatten(
    data
      .map(item =>
        _flatten(
          _get(relations, item.value, []).map(el => _get(el, 'exclude', [])),
        ),
      )
      .filter(item => !_isEmpty(item)),
  )
    .filter(item => !data.map(el => el.value).includes(item.name))
    .map(item => item.name);
  const exclusionDataIds = _flatten(
    data
      .map(item =>
        _flatten(
          _get(relations, item.value, []).map(el => _get(el, 'exclude', [])),
        ),
      )
      .filter(item => !_isEmpty(item)),
  )
    .filter(item => !data.map(el => el.value).includes(item.name))
    .map(item => item.id);

  return [exclusionData, exclusionDataIds];
};
