import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import format from 'date-fns/format';
import _get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import useSiteCopySelector from 'components/useSiteCopySelector';
import useSiteConfigSelector from 'components/useSiteConfigSelector';
import Mixpanel from 'utils/mixpanelService';
import { replacePlaceholders } from 'utils/stringUtils';
import NavBarContext from '../../navBarContext';
import AssessmentItem from './AccountSidebarItem';
import { getFirstAvailableAssessment } from './utils';

const useStyles = makeStyles({
  root: {
    paddingTop: 25,
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    color: props => props.theme.headerColor,
    fontFamily: 'Poppins',
    fontWeight: 700,
    fontSize: 18,
    lineHeight: '1.5',
  },
  counter: {
    color: '#000000',
    fontFamily: 'Mada',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '1',
    height: 18,
    width: 18,
    borderRadius: 9,
    backgroundColor: '#EE9E00',
    marginLeft: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 15,
  },
  callToAction: {
    fontFamily: 'Mada',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '15.6px',
    color: props => props.theme.headerColor,
    textAlign: 'center',
  },
  viewAllWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
  },
  viewAll: {
    textDecoration: 'underline',
    fontFamily: 'Mada',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '15.6px',
    color: props => props.theme.linkColor,
  },
});

const AssessmentsSection = ({ onHide, theme, takenAssessments, count }) => {
  const classes = useStyles({ theme });
  const { siteCopy } = useContext(NavBarContext);
  const [resourceItemPageSiteCopy] = useSiteCopySelector([
    'resource-item-page',
  ]);
  const [siteConfig] = useSiteConfigSelector(['Profile Sidebar']);
  const clientDetails = useSelector(state => state.main.clientDetails);
  const assessments = useSelector(state => state.assessments.data);

  const excludedAssessments = _get(
    clientDetails,
    'excludeAssessmentCollection.items',
    [],
  );

  const firstAvailableAssessment = getFirstAvailableAssessment({
    navbarSiteCopy: siteCopy,
    resourceItemPageSiteCopy,
    assessments,
    excludedAssessments,
  });

  const handleViewAll = () => {
    onHide();
    Mixpanel.track('Profile - View All - Clicked', {
      section: 'assessments',
    });
  };

  const hasAssessmentsTaken = takenAssessments.length > 0;

  return (
    <Box className={classes.root}>
      <Box className={classes.headerWrapper}>
        <Typography className={classes.header}>
          {_get(siteCopy, ['pageCopy', 'profile-sidebar', 'assessmentsHeader'])}
        </Typography>
        {count > 0 && (
          <Typography className={classes.counter}>{count}</Typography>
        )}
      </Box>
      <Box className={classes.itemsWrapper}>
        {hasAssessmentsTaken
          ? takenAssessments
              .slice(0, _get(siteConfig, 'config.assessments.displayCount', 3))
              .map(item => (
                <AssessmentItem
                  key={item.fields.slug}
                  type="assessment"
                  theme={theme}
                  title={item.fields.name}
                  customNames={item.fields.customNames}
                  subtitle={replacePlaceholders(
                    _get(siteCopy, [
                      'pageCopy',
                      'profile-sidebar',
                      'assessmentsLastCompleted',
                    ]),
                    {
                      '#date': format(item.timestamp, 'MMM dd, yyyy'),
                    },
                  )}
                  url={`/assessments/${item.fields.slug}/results`}
                  retakeUrl={`/assessments/${item.fields.slug}`}
                  retake={item.shouldRetake}
                  onClick={() => {
                    onHide();
                    Mixpanel.track('Resource Card - Clicked', {
                      type: 'Assessments',
                      section: 'assessments',
                      slug: item.fields.slug,
                      name: item.fields.name,
                      path: 'profile sidebar',
                    });
                  }}
                  onRetakeClick={() => {
                    onHide();
                    Mixpanel.track('Retake Assessment', {
                      path: 'profileMenu',
                      slug: item.fields.slug,
                      name: item.fields.name,
                    });
                  }}
                  hideIcon
                />
              ))
          : firstAvailableAssessment && (
              <>
                <Typography className={classes.callToAction}>
                  {_get(siteCopy, [
                    'pageCopy',
                    'profile-sidebar',
                    'assessmentsCTA',
                  ])}
                </Typography>
                <AssessmentItem
                  type="assessment"
                  theme={theme}
                  title={firstAvailableAssessment.title}
                  subtitle={firstAvailableAssessment.subtitle}
                  url={firstAvailableAssessment.url}
                  hideIcon
                  onClick={onHide}
                />
              </>
            )}
      </Box>
      <Box className={classes.viewAllWrapper}>
        <Link
          to="/assessments"
          onClick={handleViewAll}
          disableRipple
          aria-label="View all assessments"
        >
          <Typography className={classes.viewAll}>
            {_get(siteCopy, ['pageCopy', 'profile-sidebar', 'viewAllLabel'])}
          </Typography>
        </Link>
      </Box>
    </Box>
  );
};

AssessmentsSection.propTypes = {
  onHide: PropTypes.func,
  theme: PropTypes.object,
  takenAssessments: PropTypes.array,
};

export default AssessmentsSection;
