import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _get from 'lodash/get';
import useSiteCopySelector from '../useSiteCopySelector';

const useStyles = makeStyles({
  container: {
    position: 'relative',
  },
  link: {
    position: 'absolute',
    top: '-40px',
    left: '-130px',
    color: '#01619B',
    textDecoration: 'none',
    zIndex: 1000,
    whiteSpace: 'nowrap',

    textTransform: 'uppercase',

    padding: '0 6px',
    fontSize: 14,
    borderRadius: 4,

    '&:focus': {
      top: '-11px',
    },
  },
});

const SkipToContentLink = ({ setFocus }) => {
  const classes = useStyles();
  const [footerSiteCopy] = useSiteCopySelector(['footer']);
  return (
    <div className={classes.container}>
      <a
        className={classes.link}
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
        href="#main"
      >
        {_get(footerSiteCopy, 'pageCopy.skipToContent', 'Skip to content')}
      </a>
    </div>
  );
};

export default SkipToContentLink;
