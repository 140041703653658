import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import { getClientArticleSettings, ARTICLE_TYPES } from 'utils/clientSettings';

export const articleTypesInClientExcludedResources = new Set([
  'News',
  'Articles',
  'Insights',
]);
const articleTypesFromCTFAndAlgolia = new Set(['activity', 'article']);

const filterFn = (
  clientExcludedResourceTypes,
  alwaysShowInformationalLink,
) => item => {
  const finalType =
    _get(item, '__typename', '').toLowerCase() ||
    _get(item, 'sys.contentType.sys.id');
  if (!articleTypesFromCTFAndAlgolia.has(finalType)) return true;

  const itemType = _get(item, 'fields.type');
  if (
    alwaysShowInformationalLink &&
    itemType === ARTICLE_TYPES.INFORMATION_LINK
  )
    return true;

  if (_get(item, 'fields.blog'))
    return !clientExcludedResourceTypes.has('Insights');
  if (itemType === 'News') return !clientExcludedResourceTypes.has('News');
  return !clientExcludedResourceTypes.has('Articles');
};

export const filterArticleTypesExcluded = (
  items,
  clientExcludedResourceTypes,
  clientDetails,
) => {
  if (_isEmpty(clientExcludedResourceTypes) || _isEmpty(items)) return items;

  const { alwaysShowInformationalLink } = getClientArticleSettings(
    clientDetails,
  );
  // eslint-disable-next-line no-param-reassign
  if (!Array.isArray(items)) items = [items];
  return items.filter(
    filterFn(clientExcludedResourceTypes, alwaysShowInformationalLink),
  );
};

export const filterClientArticlesInResources = (items, clientDetails) => {
  if (!clientDetails?.metadata?.hideClientArticlesInResources) {
    return items;
  }

  return items.filter(filterFn(new Set(['Articles']), false));
};

const useClientExcludedResourceType = () => {
  const clientDetails = useSelector(makeSelectClientDetails());
  const clientExcludedResourceTypes = new Set(
    _get(clientDetails, 'excludeResourceTypes', []),
  );

  const hasExcludedArticleType = [...clientExcludedResourceTypes].some(type =>
    articleTypesInClientExcludedResources.has(type),
  );

  const isArticleTypesExcluded = items =>
    filterArticleTypesExcluded(
      items,
      clientExcludedResourceTypes,
      clientDetails,
    );

  return { isArticleTypesExcluded, hasExcludedArticleType };
};

export default useClientExcludedResourceType;
