/* eslint-disable no-underscore-dangle */
/**
 *
 * Overlay
 *
 */

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import _classNames from 'classnames';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import useContentfulLocale from 'components/useContentfulLocale';
import { useSelector } from 'react-redux';
import { makeSelectSiteCopyInOtherLang } from 'containers/Main/selectors';
import { DEFAULT_PRESENTATION } from 'utils/constants';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import SuggestionPill from './SuggestionPill';
import TopicItem from './TopicItem';
import CrisisBanner from './CrisisBanner';
import AssessmentItem from './AssessmentItem';
import ResourceItem from './ResourceItem';
import useFocusTrap from '../hooks/useFocusTrap';

const useStyles = makeStyles(theme => ({
  searchPopover: {
    zIndex: 9999,
    position: 'fixed !important',
    '&[x-placement="bottom"]': {
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        transform: props =>
          `translate3d(0, ${props.offsetTop + 63}px, 0px) !important`,
      },
      [theme.breakpoints.up('md')]: {
        width: '80%',
        transform: props =>
          `translate3d(10vw, ${props.offsetTop + 63}px, 0px) !important`,
      },
      [theme.breakpoints.down('xs')]: {
        transform: 'translate3d(0, 80px, 0) !important',
      },
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  variantEPopper: {
    zIndex: 9999,
    position: 'fixed !important',
    '&[x-placement="bottom"]': {
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        transform: props =>
          `translate3d(0, ${props.offsetTop + 63}px, 0px) !important`,
      },
      [theme.breakpoints.up('md')]: {
        width: '80%',
        transform: props =>
          `translate3d(10vw, ${props.offsetTop + 63}px, 0px) !important`,
      },
      [theme.breakpoints.down('xs')]: {
        transform: props =>
          `translate3d(0, ${props.offsetTop + 63}px, 0px) !important`,
      },
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },

  searchMobilePopover: {
    marginTop: 0,
    [theme.breakpoints.down('600')]: {
      marginTop: 53,
    },
    minWidth: 250,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
    background: '#FFFFFF',
    borderRadius: 4,
    overflowY: 'hidden',
    overflowX: 'hidden',
    boxShadow: '0 12px 40px 0 rgba(134,130,130,0.4)',
    [theme.breakpoints.down('xs')]: {
      boxShadow: 'none',
      width: '100%',
      height: 'calc(100vh - 80px)',
      flexGrow: '1',
    },
  },
  header: {
    position: 'relative',
    alignItems: 'center',
    padding: '16px 40px',
    marginBottom: 0,
    marginTop: 0,
    [theme.breakpoints.down('xs')]: {
      padding: '16px 5%',
      height: 'fit-content',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: '-100px',
    right: '20px',
    padding: 0,
    outline: 'none !important',
    '&:focus': {
      top: '8px',
    },
  },
  resultsLabel: {
    fontFamily: 'MadaBold',
    fontWeight: 700,
    fontSize: 19,
    lineHeight: '22px',
    '& > span': {
      color: theme.palette.text.secondary,
    },
  },
  searchSuggestions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      height: 'fit-content',
      overflowY: 'hidden',
    },
  },
  searchSuggestionsOnly: {
    justifyContent: 'flex-start',
  },
  searchSuggestionsLabel: {
    fontSize: 16,
    lineHeight: '22px',
    marginRight: 8,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: 8,
    },
  },
  resultsContainer: {
    padding: '16px 32px',
    overflowY: 'scroll',
    margin: 0,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '16px 5%',
      flex: '1',
    },
    [theme.breakpoints.up('sm')]: {
      maxHeight: props => `calc(80vh - ${props.offsetTop + 63}px)`,
    },
  },
  listHeader: {
    ...theme.typography.h7,
    fontSize: 13,
    color: '#5C5C5C',
    marginBottom: 12,
  },
  linkContainer: {
    display: 'flex',
    padding: '8px 0',
    justifyContent: 'center',
    backgroundColor: '#F8F9F9',
    [theme.breakpoints.down('xs')]: {
      padding: '16px 0',
    },
  },
  link: {
    textDecoration: 'none',
    fontSize: 13,
    lineHeight: '21px',
    '& > svg': {
      fontSize: 16,
    },
  },
  resultsLabelContainer: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

const Overlay = ({
  anchorEl,
  data,
  focused,
  offsetTop,
  onChange,
  onClickAway,
  onLinkClick,
  onResourceClick,
  onSuggestionPillClick,
  query,
  searchConfig,
  isEmbedded,
  section,
  isVariantE,
  variantELocale,
  otherLanguage,
  focusableElements,
}) => {
  const finalOffset = isEmbedded ? -23 : offsetTop;
  const classes = useStyles({ offsetTop: finalOffset });
  const ctfLocaleFilter = useContentfulLocale();
  const siteCopyInOtherLang = useSelector(makeSelectSiteCopyInOtherLang());

  const focusNextElement = () => {
    const lastElement = focusableElements?.[focusableElements?.length - 1];
    if (!lastElement?.current) return;

    const allFocusableElements = Array.from(
      document.querySelectorAll(
        'a[href], button, input, select, textarea, [tabindex]:not([tabindex="-1"])',
      ),
    );

    const currentIndex = allFocusableElements.indexOf(lastElement.current);

    if (currentIndex !== -1 && currentIndex + 1 < allFocusableElements.length) {
      allFocusableElements[currentIndex + 1].focus();
    }
  };

  const handleEscape = () => {
    onClickAway();
    focusNextElement();
  };

  const tooltipRef = useFocusTrap({
    focusableElements,
    onEscape: handleEscape,
  });

  const renderCloseButton = () => (
    <IconButton
      className={classes.closeIcon}
      onClick={handleEscape}
      aria-label="Close"
    >
      <CloseIcon />
    </IconButton>
  );

  const searchSuggestionsContent = (
    <Grid container spacing={2} className={classes.header}>
      {renderCloseButton()}
      <Grid
        item
        xs
        className={_classNames(
          classes.searchSuggestions,
          classes.searchSuggestionsOnly,
        )}
      >
        <Typography
          color="textPrimary"
          className={classes.searchSuggestionsLabel}
        >
          {_get(searchConfig, 'pageHeader', 'Top Searches')}
        </Typography>
        {_get(searchConfig, 'pageCopy.search', []).map(text => (
          <SuggestionPill
            hasIcon
            key={`suggestion-${text}`}
            text={text}
            onClick={() => onSuggestionPillClick(text)}
          />
        ))}
      </Grid>
    </Grid>
  );

  const content = useMemo(() => {
    const hasTopics = !_isEmpty(data.topics);
    const hasAssessments = !_isEmpty(data.assessments);
    const hasResources = !_isEmpty(data.resources);
    const hasCrisisFlag = data.crisisFlag;

    let topicsCol = 12;
    let assessmentsCol = 12;
    let resourcesCol = 12;
    if (hasTopics && hasAssessments && hasResources) {
      topicsCol = 3;
      assessmentsCol = 3;
      resourcesCol = 6;
    } else if (!hasTopics && hasAssessments && hasResources) {
      assessmentsCol = 3;
      resourcesCol = 9;
    } else if (hasTopics && !hasAssessments && hasResources) {
      topicsCol = 3;
      resourcesCol = 9;
    } else if (hasTopics && hasAssessments && !hasResources) {
      topicsCol = 9;
      assessmentsCol = 3;
    }

    return _isEmpty(data) ? null : (
      <>
        <Grid container spacing={2} className={classes.header}>
          {renderCloseButton()}
          <Grid item className={classes.resultsLabelContainer}>
            <Typography color="textPrimary" className={classes.resultsLabel}>
              {_get(searchConfig, 'pageCopy.resultsLabel')}{' '}
              <span>“{query}”</span>
            </Typography>
          </Grid>
          {hasCrisisFlag ? (
            <CrisisBanner
              query={query}
              fromHeader={section === 'header'}
              onClose={onClickAway}
            />
          ) : (
            <Grid item xs className={classes.searchSuggestions}>
              <Typography
                color="textPrimary"
                className={classes.searchSuggestionsLabel}
              >
                {_get(searchConfig, 'pageCopy.otherLabel')}
              </Typography>
              {_get(searchConfig, 'pageCopy.search', [])
                .filter(
                  suggestion =>
                    suggestion.toLowerCase() !== query.toLowerCase(),
                )
                .slice(0, 4)
                .map(text => (
                  <SuggestionPill
                    key={`suggestion-${text}`}
                    text={text}
                    onClick={() => onChange(text)}
                  />
                ))}
            </Grid>
          )}
        </Grid>
        <Divider />
        <Grid container spacing={2} className={classes.resultsContainer}>
          {hasTopics && (
            <Grid item xs={12} sm={topicsCol}>
              <Typography className={classes.listHeader}>
                {_get(searchConfig, 'pageCopy.topicsLabel')}
              </Typography>
              <Grid container spacing={2}>
                {data.topics.map(item => (
                  <Grid
                    item
                    xs={12}
                    sm={topicsCol === 12 ? 3 : topicsCol === 9 ? 4 : 12}
                    key={item.url}
                  >
                    <TopicItem
                      data={item}
                      onClick={onResourceClick}
                      variantELocale={variantELocale}
                    />
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Link
                    to={`/topics?lang=${variantELocale ||
                      ctfLocaleFilter.locale}`}
                    className={classes.link}
                    onClick={() => onLinkClick('Topics')}
                  >
                    {_get(searchConfig, 'pageCopy.topicsViewAll')}{' '}
                    <ArrowRight />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          )}
          {hasAssessments && (
            <Grid item xs={12} sm={assessmentsCol}>
              <Typography className={classes.listHeader}>
                {_get(searchConfig, 'pageCopy.assessmentsLabel')}
              </Typography>
              <Grid container spacing={2}>
                {data.assessments.map(item => (
                  <Grid
                    item
                    xs={12}
                    sm={
                      assessmentsCol === 12 ? 3 : assessmentsCol === 6 ? 6 : 12
                    }
                    key={item.url}
                    className={classes.focusableItem}
                  >
                    <AssessmentItem
                      data={item}
                      onClick={onResourceClick}
                      variantELocale={variantELocale}
                      siteCopyInOtherLang={otherLanguage && siteCopyInOtherLang}
                    />
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Link
                    to={`/assessments?lang=${variantELocale ||
                      ctfLocaleFilter.locale}`}
                    className={classes.link}
                    onClick={() => onLinkClick('Assessments')}
                  >
                    {_get(searchConfig, 'pageCopy.assessmentsViewAll')}{' '}
                    <ArrowRight />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          )}
          {hasResources && (
            <Grid item xs={12} sm={resourcesCol}>
              <Typography className={classes.listHeader}>
                {_get(searchConfig, 'pageCopy.resourcesLabel')}
              </Typography>
              <Grid container spacing={2}>
                {data.resources.map(item => (
                  <Grid
                    item
                    xs={12}
                    sm={resourcesCol === 12 ? 3 : resourcesCol === 9 ? 4 : 6}
                    key={item.url}
                    className={classes.focusableItem}
                  >
                    <ResourceItem
                      data={item}
                      onClick={onResourceClick}
                      variantELocale={variantELocale}
                      siteCopyInOtherLang={otherLanguage && siteCopyInOtherLang}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
        <div className={classes.linkContainer}>
          <Link
            to={`/resources?query=${query}&presentation=${DEFAULT_PRESENTATION}${
              variantELocale ? `&lang=${variantELocale}` : ''
            }`}
            className={classes.link}
            onClick={() => onLinkClick('Resources')}
          >
            {_get(searchConfig, 'pageCopy.resourcesViewAll')} “{query}”{' '}
            <ArrowRight />
          </Link>
        </div>
      </>
    );
  }, [data]);

  return (
    <Popper
      open={focused}
      anchorEl={anchorEl}
      className={_classNames(
        isVariantE ? classes.variantEPopper : classes.searchPopover,
        classes.searchMobilePopover,
      )}
      ref={tooltipRef}
      role="presentation"
      placement="bottom"
      modifiers={{
        flip: {
          enabled: false,
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: 'window',
        },
      }}
    >
      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={onClickAway}>
        <div className={classes.paper}>
          {_isEmpty(data) ? searchSuggestionsContent : content}
        </div>
      </ClickAwayListener>
    </Popper>
  );
};

Overlay.propTypes = {
  anchorEl: PropTypes.element,
  data: PropTypes.object,
  focused: PropTypes.bool,
  offsetTop: PropTypes.number,
  onChange: PropTypes.func,
  onClickAway: PropTypes.func,
  onLinkClick: PropTypes.func,
  onResourceClick: PropTypes.func,
  onSuggestionPillClick: PropTypes.func,
  query: PropTypes.string,
  searchConfig: PropTypes.object,
  isEmbedded: PropTypes.bool,
  isVariantE: PropTypes.bool,
};

Overlay.defaultProps = {
  isVariantE: false,
};

export default Overlay;
