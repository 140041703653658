import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

const pathMapping = {
  'Online Programs': 'programs',
  FAQ: 'faqs',
  News: 'articles',
  Practice: 'practices',
};

export const getFavoriteResourceUrl = item => {
  const path =
    pathMapping[item.expandedType] ?? item.expandedType.toLowerCase();
  return `/${path}/${item.slug}`;
};

export const getFirstAvailableAssessment = ({
  navbarSiteCopy,
  resourceItemPageSiteCopy,
  assessments = [],
  excludedAssessments = [],
}) => {
  const allAssessments = _isEmpty(assessments) ? [] : assessments;
  // Get configured assessment details from site copy
  const configuredUrl = _get(
    navbarSiteCopy,
    ['pageCopy', 'profile-sidebar', 'assessmentsCTACardUrl'],
    '',
  );
  const configuredSlug = configuredUrl.split('/').pop();
  const configuredTitle = _get(navbarSiteCopy, [
    'pageCopy',
    'profile-sidebar',
    'assessmentsCTACardTitle',
  ]);
  const configuredSubtitle = _get(navbarSiteCopy, [
    'pageCopy',
    'profile-sidebar',
    'assessmentsCTACardSubtitle',
  ]);

  // Check if configured assessment is excluded
  const isConfiguredExcluded = excludedAssessments.some(
    excluded => _get(excluded, 'slug') === configuredSlug,
  );

  if (!isConfiguredExcluded) {
    return {
      url: configuredUrl,
      title: configuredTitle,
      subtitle: configuredSubtitle,
    };
  }

  // Fall back to first non-excluded assessment
  const firstAvailable = allAssessments.find(
    assessment =>
      !excludedAssessments.some(
        excluded => _get(excluded, 'slug') === _get(assessment, 'fields.slug'),
      ),
  );

  if (firstAvailable) {
    const duration = _get(firstAvailable, 'fields.time');

    return {
      url: `/assessments/${_get(firstAvailable, 'fields.slug')}`,
      title: _get(firstAvailable, 'fields.name'),
      subtitle: duration
        ? `${duration} ${_get(
            resourceItemPageSiteCopy,
            'pageCopy.durationLabelShortPlural',
          )}`.toUpperCase()
        : '',
    };
  }

  return null;
};
