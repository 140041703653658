import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ClassNames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import useSiteConfigSelector from 'components/useSiteConfigSelector';
import { makeStyles } from '@material-ui/core';
import useSiteCopySelector from 'components/useSiteCopySelector';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import useIsAuthPath from 'components/Hooks/useIsAuthPath';

const useStyles = makeStyles(() => ({
  inCrisisLink: {
    fontFamily: 'Mada !important',
    color: 'white !important',
    whiteSpace: 'nowrap',
    fontSize: '14px',
    textTransform: 'uppercase !important',
    borderRadius: '10px',
    padding: '0px 7px',
    backgroundColor: '#AD0000',
  },
}));

const useInCrisis = ({ buttonClassName } = {}) => {
  const classes = useStyles();

  const [navBarMenuPageCopy] = useSiteCopySelector(['navbar-menu']);
  const [siteConfig] = useSiteConfigSelector(['Main Menu']);
  const clientDetails = useSelector(makeSelectClientDetails());
  const isAuthPath = useIsAuthPath();

  const showInCrisisInMainNavigation = useMemo(
    () => _get(clientDetails, 'metadata.inCrisisMainNavigation', false),
    [clientDetails],
  );

  const clientMenu = useMemo(() => _get(clientDetails, 'metadata.menu', []), [
    clientDetails,
  ]);
  const configMenu = useMemo(() => _get(siteConfig, 'config.items', []), [
    siteConfig,
  ]);

  const finalMenu = !_isEmpty(clientMenu) ? clientMenu : configMenu;
  const hasInCrisis = finalMenu.some(slug => slug === 'in-crisis');
  const showInCrisisInHeader = !showInCrisisInMainNavigation && hasInCrisis;

  const renderInCrisisButton = useCallback(() => {
    if (isAuthPath || !showInCrisisInHeader) return null;

    return (
      <Link
        to={{ pathname: '/in-crisis', state: { section: 'header' } }}
        className={ClassNames(classes.inCrisisLink, buttonClassName)}
      >
        {_get(
          navBarMenuPageCopy,
          ['pageCopy', 'submenu-labels', 'in-crisis'],
          '',
        )}
      </Link>
    );
  }, [
    showInCrisisInHeader,
    buttonClassName,
    classes.inCrisisLink,
    navBarMenuPageCopy,
    isAuthPath,
  ]);

  return {
    renderInCrisisButton,
    hasInCrisis,
    showInCrisisInHeader,
  };
};

export default useInCrisis;
