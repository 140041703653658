import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { Link, useLocation, useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import { setLeavingModal } from 'containers/Main/actions';
import {
  makeSelectClientDetails,
  makeSelectLeavingModal,
} from 'containers/Main/selectors';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useSiteCopySelector from 'components/useSiteCopySelector';
import Button from '@material-ui/core/Button';
import Mixpanel from 'utils/mixpanelService';
import RichTextDescription from 'components/RichTextDescription';
import { getParentPosition } from 'utils/embedded';

const useStyles = makeStyles(theme => ({
  paper: {
    borderRadius: '5px !important',
    [theme.breakpoints.up('800')]: {
      width: 750,
      maxWidth: 750,
    },
    [theme.breakpoints.down('799')]: {
      margin: '12px',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '15px 15px 0px 0px',
  },
  contentRoot: {
    margin: 0,
    padding: '10px 50px 50px',
    [theme.breakpoints.down('800')]: {
      padding: '10px 24px 30px',
    },
    '& h4': {
      padding: '0 55px',
      [theme.breakpoints.down('800')]: {
        padding: 0,
        fontSize: '18px',
      },
    },
  },
  closeButton: {
    color: theme.palette.grey[500],
    padding: 0,
    '& span': {
      '& svg': {
        fontSize: 35,
        [theme.breakpoints.down('800')]: {
          fontSize: 26,
        },
      },
    },
  },
  button: {
    width: '50%',
    fontWeight: 600,
    marginTop: 30,
    padding: '20px 24px',
    borderRadius: 2,
    fontSize: 14,
    fontFamily: 'PoppinsRegular',
    [theme.breakpoints.down('800')]: {
      width: '100%',
      padding: '12px 16px',
      marginTop: '10px',
    },
  },
  articleButton: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    border: 1,
    fontWeight: 600,
    borderColor: theme.palette.primary.main,
    background: '#ffff',
    marginTop: 30,
    borderRadius: 2,
    fontSize: 14,
    fontFamily: 'PoppinsRegular',
    textAlign: 'center',
    padding: '16px 24px',
    borderStyle: 'solid',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('800')]: {
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      padding: '9px 16px',
      marginTop: '10px',
    },
    '&:hover': {
      background: '#fff',
      color: theme.palette.primary.main,
    },
  },
  pageHeaderText: {
    color: '#01619c',
  },
  buttonSpace: {
    columnGap: 10,
    [theme.breakpoints.down('800')]: {
      flexDirection: 'column',
    },
  },
  disclaimerWrapper: {
    paddingTop: 20,
    '& p': {
      fontSize: '0.85rem',
    },
  },
}));

export default function LeavingAppDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const {
    visible,
    title,
    url,
    organization,
    resourceType,
    articleURL,
    leavingModal,
    disclaimer,
    themes,
    topics,
  } = useSelector(makeSelectLeavingModal());

  const [siteCopy] = useSiteCopySelector(['leaving-app-modal']);
  const clientDetails = useSelector(makeSelectClientDetails());
  const showExternalRedirectPage =
    clientDetails?.metadata?.showExternalRedirectPage;

  const { pageHeader, callToAction, pageCopy } = siteCopy;
  const [embedParentPosition, setEmbedParentPosition] = useState({});

  useEffect(() => {
    let isMounted = true;

    const fetchAndSetParentPosition = async () => {
      const parentPosition = await getParentPosition();
      if (isMounted) {
        setEmbedParentPosition(parentPosition);
      }
    };
    if (visible) {
      fetchAndSetParentPosition();
    }

    return () => {
      isMounted = false;
    };
  }, [visible]);

  if (!visible) return null;

  const handleClose = () =>
    dispatch(
      setLeavingModal({
        visible: false,
        title: null,
        url: null,
      }),
    );

  const handleLinkClick = () => {
    if (showExternalRedirectPage) {
      history.replace('/redirect', {
        url,
        prevLocation: location.pathname,
        title,
        themes,
        topics,
      });
    } else {
      window.open(url, '_blank', 'noopener');
    }

    dispatch(
      setLeavingModal({
        visible: false,
        title: null,
        url: null,
      }),
    );

    const pageName = location.pathname.split('/')[1];
    const finalPageName = pageName ? `${pageName}Page` : 'homePage';

    Mixpanel.track('Leaving App Dialog - Clicked', {
      path: window.location.pathname,
      title,
      url,
      page: finalPageName,
      ...(themes && { themes }),
      ...(topics && { topics }),
    });
  };

  let header = <>{pageHeader}</>;
  if (
    resourceType === 'pure-url' &&
    _get(siteCopy, 'pageCopy.externalUrlHeader')
  ) {
    header = <>{_get(siteCopy, 'pageCopy.externalUrlHeader')}</>;
  } else if (resourceType === 'article' && !leavingModal) {
    header = (
      <div>
        {_get(pageCopy, 'articleLabel')}
        <span className={classes.pageHeaderText}> {organization}</span>.{' '}
        {_get(pageCopy, 'headerLabel')}
      </div>
    );
  }
  const embedPositionTop = _get(embedParentPosition, 'top', null);
  const dialogProps = {
    onClose: handleClose,
    'aria-labelledby': 'remind-me-later-dialog',
    open: true,
    classes: {
      paper: classes.paper,
    },
  };

  if (embedPositionTop) {
    dialogProps.PaperProps = {
      style: {
        position: 'fixed',
        top: `${embedPositionTop}px`,
      },
    };
  }
  return (
    <Dialog {...dialogProps}>
      <Box className={classes.header}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent classes={{ root: classes.contentRoot }}>
        {title && leavingModal && (
          <Typography
            variant="overline"
            component="p"
            align="center"
            color="primary"
            gutterBottom
          >
            {title}
          </Typography>
        )}

        <Typography variant="h4" color="textPrimary" align="center">
          {header}
        </Typography>
        {disclaimer && (
          <div className={classes.disclaimerWrapper}>
            <RichTextDescription data={disclaimer} />
          </div>
        )}
        <Box
          display="flex"
          flex="1"
          justifyContent="center"
          className={classes.buttonSpace}
        >
          <Button
            color="primary"
            role="link"
            variant="contained"
            aria-label={
              !showExternalRedirectPage && `Open ${url} link in a new tab`
            }
            className={classes.button}
            onClick={handleLinkClick}
          >
            {resourceType === 'article' && !leavingModal
              ? _get(pageCopy, 'articleButton')
              : callToAction}
          </Button>
          {resourceType === 'article' && !leavingModal && (
            <Link
              to={articleURL}
              color=""
              variant="contained"
              className={classes.articleButton}
              onClick={handleClose}
            >
              {_get(pageCopy, 'buttonLabel')}
            </Link>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
