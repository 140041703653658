import React from 'react';
import Rating from '@material-ui/lab/Rating';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import StarFull from './images/yellow-star-full.svg';
import StarEmpty from './images/star-empty.svg';
import BigStarFull from './images/big-star-full.svg';
import BigStarEmpty from './images/big-star-empty.svg';

const useStyles = makeStyles(() => ({
  iconWrapper: {
    display: 'flex !important',
  },
  smallIcon: {
    height: 10,
    width: 10,
  },
}));
function ItemRating({
  value = 0,
  name,
  precision = 0.1,
  readOnly = true,
  isBig = false,
  isSmall = false,
  onChange,
}) {
  const classes = useStyles();
  return (
    <Rating
      name={name}
      value={value}
      readOnly={readOnly}
      aria-hidden="true"
      icon={
        <img
          src={isBig ? BigStarFull : StarFull}
          className={isSmall ? classes.smallIcon : null}
          alt=""
        />
      }
      className={classes.iconWrapper}
      precision={precision}
      onChange={onChange}
      emptyIcon={
        <img
          src={isBig ? BigStarEmpty : StarEmpty}
          className={isSmall ? classes.smallIcon : null}
          alt=""
        />
      }
    />
  );
}

ItemRating.propTypes = {
  value: PropTypes.number,
  name: PropTypes.string,
  precision: PropTypes.number,
  readOnly: PropTypes.bool,
  isBig: PropTypes.bool,
  onChange: PropTypes.func,
};

export default ItemRating;
