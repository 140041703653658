import _get from 'lodash/get';
import AppActive from './images/Blue/Apps.svg';
import AppInactive from './images/Black/Apps.svg';
import App from './images/Custom/Apps';
import ArticleActive from './images/Blue/Articles.svg';
import ArticleInactive from './images/Black/Articles.svg';
import Article from './images/Custom/Articles';
import AssessmentActive from './images/Blue/Assessments.svg';
import AssessmentInactive from './images/Black/Assessments.svg';
import Assessment from './images/Custom/Assessments';
import BlogActive from './images/Blue/Blog.svg';
import BlogInactive from './images/Black/Blog.svg';
import Blog from './images/Custom/Blog';
import BookActive from './images/Blue/Books.svg';
import BookInactive from './images/Black/Books.svg';
import Book from './images/Custom/Books';
import CourseActive from './images/Blue/Courses.svg';
import CourseInactive from './images/Black/Courses.svg';
import Course from './images/Custom/Courses';
import FAQActive from './images/Blue/FAQ.svg';
import FAQInactive from './images/Black/FAQ.svg';
import FAQ from './images/Custom/FAQ';
// import EventActive from './images/Blue/event_blue.svg';
// import EventInactive from './images/Black/event_gray.svg';
import LinkActive from './images/Blue/Link.svg';
import LinkInactive from './images/Black/Link.svg';
import Link from './images/Custom/Link';
import ListActive from './images/Blue/Lists.svg';
import ListInactive from './images/Black/Lists.svg';
import List from './images/Custom/Lists';
import OrganizationActive from './images/Blue/Organizations.svg';
import OrganizationInactive from './images/Black/Organizations.svg';
import Organization from './images/Custom/Organizations';
import PersonActive from './images/Blue/People.svg';
import PersonInactive from './images/Black/People.svg';
import Person from './images/Custom/People';
import PodcastActive from './images/Blue/Podcasts.svg';
import PodcastInactive from './images/Black/Podcasts.svg';
import Podcast from './images/Custom/Podcasts';
import TopicsActive from './images/Blue/Topics.svg';
import TopicsInactive from './images/Black/Topics.svg';
import Topics from './images/Custom/Topics';
import VideoActive from './images/Blue/Videos.svg';
import VideoInactive from './images/Black/Videos.svg';
import Video from './images/Custom/Videos';
import NewsActive from './images/Blue/News.svg';
import NewsInactive from './images/Black/News.svg';
import News from './images/Custom/News';
import ProgramsActive from './images/Blue/Programs.svg';
import ProgramsInactive from './images/Black/Programs.svg';
import Programs from './images/Custom/Programs';
import ClientExclusiveActive from './images/Blue/ClientExclusive.svg';
import ClientExclusiveInactive from './images/Black/ClientExclusive.svg';
import ClientExclusive from './images/Custom/ClientExclusive';
import ServicesActive from './images/Blue/services.svg';
import ServicesInactive from './images/Black/services.svg';
import Services from './images/Custom/Services';
import SeriesActive from './images/Blue/Series.svg';
import SeriesInactive from './images/Black/Series.svg';
import Series from './images/Custom/Series';
import PracticesActive from './images/Blue/Practices.svg';
import PracticesInactive from './images/Black/Practices.svg';
import Practices from './images/Custom/Practices';

const Icons = {
  Apps: {
    true: AppActive,
    false: AppInactive,
    custom: App,
  },
  Assessments: {
    true: AssessmentActive,
    false: AssessmentInactive,
    custom: Assessment,
  },
  Books: {
    true: BookActive,
    false: BookInactive,
    custom: Book,
  },
  FAQ: {
    true: FAQActive,
    false: FAQInactive,
    custom: FAQ,
  },
  Links: {
    true: LinkActive,
    false: LinkInactive,
    custom: Link,
  },
  'Expert Lists': {
    true: ListActive,
    false: ListInactive,
    custom: List,
  },
  'Public Lists': {
    true: ListActive,
    false: ListInactive,
    custom: List,
  },
  Lists: {
    true: ListActive,
    false: ListInactive,
    custom: List,
  },
  'My Lists': {
    true: ListActive,
    false: ListInactive,
    custom: List,
  },
  Organizations: {
    true: OrganizationActive,
    false: OrganizationInactive,
    custom: Organization,
  },
  People: {
    true: PersonActive,
    false: PersonInactive,
    custom: Person,
  },
  Podcasts: {
    true: PodcastActive,
    false: PodcastInactive,
    custom: Podcast,
  },
  Topics: {
    true: TopicsActive,
    false: TopicsInactive,
    custom: Topics,
  },
  Articles: {
    true: ArticleActive,
    false: ArticleInactive,
    custom: Article,
  },
  Insights: {
    true: BlogActive,
    false: BlogInactive,
    custom: Blog,
  },
  Blogs: {
    custom: Blog,
  },
  Videos: {
    true: VideoActive,
    false: VideoInactive,
    custom: Video,
  },
  News: {
    true: NewsActive,
    false: NewsInactive,
    custom: News,
  },
  'Online Programs': {
    true: ProgramsActive,
    false: ProgramsInactive,
    custom: Programs,
  },
  'Best Lists': {
    true: ListActive,
    false: ListInactive,
    custom: List,
  },
  'Client Exclusive': {
    true: ClientExclusiveActive,
    false: ClientExclusiveInactive,
    custom: ClientExclusive,
  },
  Services: {
    true: ServicesActive,
    false: ServicesInactive,
    custom: Services,
  },
  Series: {
    true: SeriesActive,
    false: SeriesInactive,
    custom: Series,
  },
  Courses: {
    true: CourseActive,
    false: CourseInactive,
    custom: Course,
  },
  Practices: {
    true: PracticesActive,
    false: PracticesInactive,
    custom: Practices,
  },
};

export const getIcon = (type, isActive) =>
  Icons[type] ? Icons[type][isActive] : undefined;

export const getCustomIcon = (type, color) => Icons[type]?.custom?.(color);

const resourceTypeMapping = {
  'Client Exclusive': 17,
  Practices: 16,
  Services: 15,
  Topics: 14,
  Courses: 13,
  Assessments: 12,
  Series: 11,
  Articles: 10,
  Lists: 9,
  FAQ: 8,
  Videos: 7,
  Podcasts: 6,
  Books: 5,
  News: 4,
  Apps: 3,
  'Online Programs': 2,
  Organizations: 1,
  People: 0,
};

export const resourceTypeSortFn = (reverseMapping, typeMappingOverrides) => (
  a,
  b,
) => {
  const aValue = a.value || a;
  const bValue = b.value || b;
  const typeMapping = {
    ...resourceTypeMapping,
    ...typeMappingOverrides,
  };

  return (
    (typeMapping[reverseMapping[bValue]] || 0) -
    (typeMapping[reverseMapping[aValue]] || 0)
  );
};

export const facetFiltersSortFn = (a, b) => {
  const aValue = a.value || a;
  const bValue = b.value || b;

  if (aValue < bValue) return -1;
  if (aValue > bValue) return 1;
  return 0;
};

const availbleInFiltersMapping = {
  '7vDxuz2UAPgZfiPtKFuv4E': 3,
  '6u0EGp09XUKfhVQlqoHUCp': 2,
  '01uO1Tey6SU3SALlMA8mbs': 1,
};

export const availableInFilterSortFn = (a, b) => {
  if (
    _get(availbleInFiltersMapping, [b.id], 0) ===
    _get(availbleInFiltersMapping, [a.id], 0)
  ) {
    const aValue = a.value || a;
    const bValue = b.value || b;

    if (aValue < bValue) return -1;
    if (aValue > bValue) return 1;
    return 0;
  }
  return (
    _get(availbleInFiltersMapping, [b.id], 0) -
    _get(availbleInFiltersMapping, [a.id], 0)
  );
};
