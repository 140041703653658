import ctf from 'utils/contentfulService';
import _get from 'lodash/get';

export const topicRequiredFields = [
  'sys.id',
  'sys.updatedAt',
  'sys.contentType',
  'fields.slug',
  'fields.lists',
  'fields.title',
  'fields.description',
  'fields.phi',
  'fields.approach',
  'fields.childTopics',
  'fields.reviewStatus',
  'fields.readMore',
  'fields.editors',
  'fields.relatedTopics',
  'fields.image',
  'fields.quickTake',
  'fields.translationLink',
].join(',');

const getTopicBySlug = async (_key, slug, localeFilters) => {
  const response = await ctf.getEntries({
    content_type: 'topics',
    'fields.slug': slug,
    include: 2,
    select: topicRequiredFields,
    ...localeFilters,
  });
  return _get(response, 'items.0', false);
};

export default getTopicBySlug;
