import { gql } from '@apollo/client';

export const ClientFragment = gql`
  fragment ClientFields on Client {
    shortName
  }
`;

export const ClientGroupFragment = gql`
  fragment ClientGroupFields on ClientGroup {
    shortName
  }
`;

export const AppsFragment = gql`
  fragment ApplicationFields on Application {
    sys {
      id
    }
    slug
    name
    image {
      url
    }
    shortDescription
    imageUrl
    reviewStatus
    __typename
  }
`;

export const ActivityFragment = gql`
  fragment ActivityFields on Activity {
    sys {
      id
    }
    slug
    name
    image {
      url
    }
    url
    blog
    callToAction
    shortDescription
    imageUrl
    reviewStatus
    type
    __typename
  }
`;

export const AssessmentFragment = gql`
  fragment AssessmentFields on Assessments {
    sys {
      id
    }
    slug
    title
    image {
      url
    }
    color
    time
    name
    customNames
    __typename
    requiredTagsCollection {
      items {
        sys {
          id
        }
      }
    }
  }
`;

export const BookFragment = gql`
  fragment BookFields on Book {
    sys {
      id
    }
    slug
    title
    image {
      url
    }
    clientExcludeCollection {
      items {
        ...ClientFields
        ...ClientGroupFields
      }
    }
    clientIncludeCollection {
      items {
        ...ClientFields
        ...ClientGroupFields
      }
    }
    imageUrl
    __typename
  }
`;

export const ClientLandingFragment = gql`
  fragment ClientLandingFields on Landing {
    sys {
      id
    }
    slug
    introMedia {
      url
    }
    title
    __typename
  }
`;

export const ProgramFragment = gql`
  fragment ProgramFields on OnlineProgram {
    sys {
      id
    }
    slug
    title
    image {
      url
    }
    shortDescription
    imageUrl
    __typename
  }
`;

export const OrganizationFragment = gql`
  fragment OrganizationFields on Organization {
    sys {
      id
    }
    slug
    name
    image {
      url
    }
    imageUrl
    __typename
  }
`;

export const PersonFragment = gql`
  fragment PersonFields on Person {
    sys {
      id
    }
    slug
    name
    image {
      url
    }
    __typename
  }
`;

export const PodcastFragment = gql`
  fragment PodcastFields on Podcast {
    sys {
      id
    }
    slug
    title
    image {
      url
    }
    imageUrl
    shortDescription
    reviewStatus
    __typename
  }
`;

export const SourceFragment = gql`
  fragment SourceFields on Source {
    sys {
      id
    }
    slug
    article
    image {
      url
    }
    url
    imageUrl
    callToAction
    subtitle
    reviewStatus
    __typename
  }
`;

export const TopicFragment = gql`
  fragment TopicsFields on Topics {
    sys {
      id
    }
    slug
    title
    reviewStatus
    description
    image {
      url
    }
    __typename
  }
`;

export const VideoFragment = gql`
  fragment VideoFields on Video {
    sys {
      id
    }
    slug
    title
    image {
      url
    }
    imageUrl
    shortDescription
    reviewStatus
    __typename
  }
`;

export const LinkFragment = gql`
  fragment LinkFields on Link {
    sys {
      id
    }
    title
    subtitle
    url
    callToAction
    reviewStatus
    image {
      url
    }
    show
    __typename
  }
`;

export const ListFragment = gql`
  fragment ListFields on List {
    sys {
      id
    }
    shortDescription
    slug
    title
    reviewStatus
    image {
      url
    }
    __typename
  }
`;

export const SeriesFragment = gql`
  fragment SeriesFields on Series {
    sys {
      id
    }
    title
    name
    slug
    reviewStatus
    audioOnly
    cardColor
    image {
      url
    }
    __typename
  }
`;

export const PracticesFragment = gql`
  fragment PracticesFields on Practice {
    sys {
      id
    }
    title
    name
    slug
    reviewStatus
    image {
      url
    }
    __typename
  }
`;

export const CourseFragment = gql`
  fragment CoursesFields on Courses {
    sys {
      id
    }
    title
    slug
    reviewStatus
    image {
      url
    }
    __typename
  }
`;
