/**
 *
 * useClientResources
 *
 */

import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _uniqBy from 'lodash/uniqBy';
import { useQuery } from 'react-query';
import differenceInYears from 'date-fns/differenceInYears';
import { getResultsVisibility } from 'containers/Assessment/utils';
import getRegionBySlug from 'containers/Main/services/getRegionBySlug.gql';
import {
  makeSelectProfile,
  makeSelectProfileFilters,
} from 'containers/Auth/selectors';
import { makeSelectClientLanding } from 'containers/ClientLanding/selectors';
import {
  makeSelectClientDetails,
  makeSelectRegion,
} from 'containers/Main/selectors';
import useContentfulLocale from 'components/useContentfulLocale';
import { sortClientExcusives } from 'containers/TopicCenter/utils';
import { filterClientArticlesInResources } from 'components/Hooks/useExcludedArticleTypes';
import GetLinkResources from './services/getLinkResources';
import getLinkedResources from './services/getLinkedResources';

const stateSelector = createStructuredSelector({
  clientDetails: makeSelectClientDetails(),
  clientLanding: makeSelectClientLanding(),
  profile: makeSelectProfile(),
  profileFilters: makeSelectProfileFilters(),
  region: makeSelectRegion(),
});

function useClientResources(ignoreProfileState = false) {
  const ctfLocaleFilter = useContentfulLocale();
  const {
    clientDetails,
    clientLanding,
    profile,
    profileFilters,
    region,
  } = useSelector(stateSelector);

  const {
    insuranceOptions,
    insuranceTags,
    requiredTagsOptions,
    state,
  } = profileFilters;
  const clientId = _get(clientDetails, 'sys.id');
  const { data: linkedResources, status: linkedResourcesStatus } = useQuery(
    clientId && [
      'linked-client-resources',
      clientId,
      _get(clientDetails, 'clientGroup.sys.id'),
      ctfLocaleFilter,
    ],
    getLinkedResources,
  );
  const mappedLinkedResources = (linkedResources || []).map(item => ({
    ...item.fields,
    sys: item.sys,
  }));
  // fetch region
  const { data: regionData, status: regionStatus } = useQuery(
    region && ['region', region],
    getRegionBySlug,
  );

  const { data: linkResources = [] } = useQuery(
    clientId && [
      'home-page-links',
      {
        clientId,
        clientGroupId: _get(clientDetails, 'clientGroup.sys.id'),
        ctfLocaleFilter,
        profileTags: _get(profileFilters, 'tags'),
        requiredTagsOptions,
        insuranceTags,
        insuranceOptions,
      },
    ],
    GetLinkResources,
  );

  const hasMultipleLanding =
    _get(clientDetails, 'landingLinksCollection.items', []).length > 1;
  const landingClientResources = hasMultipleLanding
    ? _get(clientLanding, 'clientResourcesCollection.items', [])
    : [];
  const clientResources = filterClientArticlesInResources(
    _get(clientDetails, 'clientResourcesCollection.items', []),
    clientDetails,
  );
  const regionClientResources = _get(
    regionData,
    'clientResourcesCollection.items',
    [],
  );

  const finalClientResources = sortClientExcusives(
    _uniqBy(
      [
        ...clientResources,
        ...regionClientResources,
        ...landingClientResources,
        ...mappedLinkedResources,
        ...linkResources,
      ],
      'sys.id',
    )
      .filter(item => _get(item, 'reviewStatus') === 'Accepted')
      .filter(item => {
        if (_get(item, 'visibility')) {
          const parsedProfile = {};
          if (profile.insurance) {
            parsedProfile.userInsurance = profile.insurance;
          }
          if (profile.birthdayMonthYear) {
            parsedProfile.userAge = differenceInYears(
              Date.now(),
              new Date(
                profile.birthdayMonthYear.split('-')[0],
                profile.birthdayMonthYear.split('-')[1],
              ),
            );
          }
          if (profile.stateOfResidence) {
            parsedProfile.userStateOfResidence = profile.stateOfResidence;
          }
          if (profile.gender) {
            parsedProfile.profileGender = profile.gender;
          }
          if (!_isEmpty(_get(profile, 'samlData', {}))) {
            Object.keys(profile.samlData).forEach(key => {
              parsedProfile[`profile${key}`] = profile.samlData[key];
            });
          }
          return getResultsVisibility(_get(item, 'visibility'), parsedProfile);
        }
        return true;
      })
      .filter(item => {
        if (ignoreProfileState) return true;

        const hasStateSet = Boolean(state) && !_isEmpty(_get(item, 'state'));

        if (!hasStateSet) return true;

        return _get(item, 'state').includes(state);
      }),
    [
      r => {
        const order = _get(r, 'resourceOrder');
        return !order ? Number.NEGATIVE_INFINITY : order;
      },
      r => {
        const order = _get(r, 'homeOrder');
        return !order ? Number.NEGATIVE_INFINITY : order;
      },
    ],
    ['asc', 'desc'],
  );

  return {
    clientResources: finalClientResources,
    isLoading:
      linkedResourcesStatus === 'loading' || regionStatus === 'loading',
  };
}

export default useClientResources;
