import { ReadIcon } from './icons/Read';
import { ListenIcon } from './icons/Listen';
import { WatchIcon } from './icons/Watch';

export const GET_TAKEN_PRACTICES = 'app/Practices/GET_TAKEN_PRACTICES';
export const GET_TAKEN_PRACTICES_SUCCESS =
  'app/Practices/GET_TAKEN_PRACTICES_SUCCESS';
export const SET_PRACTICES_PROCESSING =
  'app/Practices/SET_PRACTICES_PROCESSING';
export const VIEW_PRACTICE = 'app/Practices/VIEW_PRACTICE';
export const GET_VIEWED_PRACTICE = 'app/Practices/GET_VIEWED_PRACTICES';
export const GET_VIEWED_PRACTICE_RESULT =
  'app/Practices/GET_VIEWED_PRACTICES_RESULT';

export const mapPracticeMethodToIcon = {
  Watch: WatchIcon,
  Listen: ListenIcon,
  'Watch/Read': ReadIcon,
};

const m3u8Type = [
  'application/x-mpegURL',
  'application/vnd.apple.mpegurl',
  'application/x-mpegurl',
];

export const practiceTypesMapping = {
  video: 'video',
  slides: 'pdf',
  audio: 'audio',
};

export const typesRegex = [
  {
    regex: new RegExp(`${m3u8Type.join('|')}|video`),
    type: practiceTypesMapping.video,
  },
  {
    regex: /audio/,
    type: practiceTypesMapping.audio,
  },
  {
    regex: /pdf/,
    type: practiceTypesMapping.slides,
  },
];
