import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Box, Grid, Hidden, makeStyles } from '@material-ui/core';
import _get from 'lodash/get';
import SearchBar from 'components/SearchBar';
import _classNames from 'classnames';
import useZoomLevel, { ZOOM_THRESHOLD } from 'components/Hooks/useZoomLevel';
import Logo from '../Logo';
import NavBarContext from '../../navBarContext';

const useStyles = makeStyles(() => ({
  helperText: {
    fontSize: '14px',
    textAlign: 'right',
    marginLeft: 'auto',
    fontWeight: 'bold',
    color: 'white',
    fontFamily: 'MadaBold',
    borderRadius: '10px',
    padding: '0px 7px',
    backgroundColor: '#AD0000',
  },
  helperContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  zoomed: {
    scale: '0.7',
    '& input': {
      fontSize: '12px !important',
    },
  },
  searchContainer: {
    width: '100%',
    paddingTop: '14px',
  },
}));

const MobileNavBar = () => {
  const classes = useStyles();
  const { siteCopy, clientDetails, menus } = useContext(NavBarContext);
  const { zoom } = useZoomLevel();

  const isSeparateLogoLayout =
    _get(clientDetails, 'metadata.separateLogoLayout', false) ||
    _get(clientDetails, 'logosCollection.items', []).length > 2;

  const inCrisisMenu = menus.find(({ slug }) => slug === 'in-crisis');

  return (
    <Hidden smUp>
      <Grid container>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Logo alternative={isSeparateLogoLayout} />
          </Grid>
          <Grid item xs={6}>
            {inCrisisMenu && (
              <Box
                className={_classNames(classes.helperContainer, {
                  [classes.zoomed]: zoom > ZOOM_THRESHOLD,
                })}
              >
                <Link
                  to={inCrisisMenu.link || '/in-crisis'}
                  className={classes.helperText}
                >
                  {_get(
                    siteCopy,
                    ['pageCopy', 'submenu-labels', inCrisisMenu.slug],
                    '',
                  )}
                </Link>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          className={_classNames(classes.searchContainer, {
            [classes.zoomed]: zoom > ZOOM_THRESHOLD,
          })}
          xs={12}
        >
          <SearchBar
            mobile
            id="mobile-nav-menu-search-input"
            section="header"
          />
        </Grid>
      </Grid>
    </Hidden>
  );
};

export default MobileNavBar;
