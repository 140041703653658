import React from 'react';
import PropTypes from 'prop-types';
import _classNames from 'classnames';
import _get from 'lodash/get';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useSiteCopySelector from 'components/useSiteCopySelector';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import { useSelector } from 'react-redux';
import CheckedIcon from './images/checkbox-checked.svg';
import UncheckedIcon from './images/checkbox-unchecked.svg';

const useStyles = makeStyles(theme => ({
  icon: {
    height: 22,
    width: 22,
  },
  smallIcon: {
    height: 16,
    width: 16,
  },
  label: {
    ...theme.typography.overline,
    color: theme.palette.text.primary,
  },
  smallLabel: {
    lineHeight: 'unset',
  },
  checked: {
    color: '#01619B !important',
    // fontFamily: 'MadaBold !important',
  },
  root: {
    width: '100%',
    marginLeft: 0,
    alignItems: 'flex-start',
    '& > span': {
      padding: 0,
      marginBottom: 4,
    },
    '& > span:first-child': {
      paddingRight: 8,
    },
  },
  normalLetterSpacing: {
    letterSpacing: 'normal',
  },
}));

function SubscriptionOptions({
  subscription,
  onChange,
  isModal,
  isSmall,
  useDisclaimerLabel,
  displayCustomConsent,
}) {
  const classes = useStyles();
  const clientDetails = useSelector(makeSelectClientDetails());
  const [subscriptionOption] = useSiteCopySelector(['account']);
  const options = _get(subscriptionOption, 'pageCopy.subscription');
  const longTextHelper = _get(
    subscriptionOption,
    'pageCopy.subscriptionsLongTextHelper',
  );
  const disclaimerLabel = _get(
    subscriptionOption,
    'pageCopy.subscriptionsDisclaimerLabel',
  );
  const customConsent = _get(
    clientDetails,
    'customAuthentication.customConsent.label',
  );
  return (
    <Grid
      container
      spacing={1}
      direction={isModal ? 'row' : 'column'}
      alignItems={isModal ? 'center' : 'flex-start'}
      justifyContent={isModal ? 'space-between' : 'flex-start'}
    >
      {displayCustomConsent && customConsent && (
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                focusRipple
                checked={subscription.includes('customconsent')}
                onChange={e => onChange(e.target.value)}
                value="customconsent"
                checkedIcon={
                  <img
                    src={CheckedIcon}
                    className={_classNames({
                      [classes.icon]: !isSmall,
                      [classes.smallIcon]: isSmall,
                    })}
                    alt="Checked"
                  />
                }
                icon={
                  <img
                    src={UncheckedIcon}
                    className={_classNames({
                      [classes.icon]: !isSmall,
                      [classes.smallIcon]: isSmall,
                    })}
                    alt="Unchecked"
                  />
                }
                inputProps={{ 'aria-label': customConsent }}
              />
            }
            classes={{
              label: _classNames({
                [classes.label]: true,
                [classes.smallLabel]: isSmall,
                [classes.checked]: subscription.includes('customconsent'),
              }),
              root: classes.root,
            }}
            label={customConsent}
          />
        </Grid>
      )}
      {options.map(item => (
        <Grid item key={item.value}>
          <FormControlLabel
            key={item.value}
            control={
              <Checkbox
                focusRipple
                checked={subscription.includes(item.value)}
                onChange={e => onChange(e.target.value)}
                value={item.value}
                checkedIcon={
                  <img
                    src={CheckedIcon}
                    className={_classNames({
                      [classes.icon]: !isSmall,
                      [classes.smallIcon]: isSmall,
                    })}
                    alt="Checked"
                  />
                }
                icon={
                  <img
                    src={UncheckedIcon}
                    className={_classNames({
                      [classes.icon]: !isSmall,
                      [classes.smallIcon]: isSmall,
                    })}
                    alt="Unchecked"
                  />
                }
                inputProps={{ 'aria-label': `Subscribe to ${item.value}` }}
              />
            }
            classes={{
              label: _classNames({
                [classes.label]: true,
                [classes.smallLabel]: isSmall,
                [classes.checked]: subscription.includes(item.value),
              }),
              root: classes.root,
            }}
            label={isSmall ? item.longText || item.text : item.text}
          />
        </Grid>
      ))}
      {isSmall && (
        <Grid item>
          <Typography
            variant="overline"
            color="textPrimary"
            className={_classNames({
              [classes.normalLetterSpacing]: useDisclaimerLabel,
            })}
          >
            {useDisclaimerLabel && <>{disclaimerLabel}&nbsp;</>}
            {longTextHelper}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

SubscriptionOptions.propTypes = {
  subscription: PropTypes.array,
  onChange: PropTypes.func,
  isModal: PropTypes.bool,
  isSmall: PropTypes.bool,
  useDisclaimerLabel: PropTypes.bool,
  displayCustomConsent: PropTypes.bool,
};

SubscriptionOptions.defaultProps = {
  isModal: false,
  isSmall: false,
};

export default SubscriptionOptions;
