import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import _get from 'lodash/get';
import { makeSelectProfile } from 'containers/Auth/selectors';
import { logout } from 'containers/Auth/actions';
import { isEmbedded } from 'utils/embedded';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import {
  getLocalData,
  removeLocalData,
  setLocalData,
} from 'utils/localDataStore';
import { isIOS } from 'utils/stringUtils';

/**
 * This hook is used to check if enough time has passed between last and current
 * sessions and force logout if it has. This hook is enabled by
 * "applyInactivityTimeoutOnSession" field and the timeout threshold is set by
 * "loggedInInactivityTimeoutInSec" field.
 */
const useSessionTimeout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const checkOnLoad = useRef(false);
  const profile = useSelector(makeSelectProfile());
  const clientDetails = useSelector(makeSelectClientDetails());

  const profileIsLoaded = profile.isLoaded;
  const profileIsEmpty = profile.isEmpty;
  const isAdmin =
    ['admin', 'contentAdmin'].includes(profile.role) && !isEmbedded();

  const shouldApplyInactivityTimeout = _get(
    clientDetails,
    'metadata.applyInactivityTimeoutOnSession',
    0,
  );
  const clientInactivityTimeout = shouldApplyInactivityTimeout
    ? _get(clientDetails, 'metadata.loggedInInactivityTimeoutInSec', 0)
    : 0;

  useEffect(() => {
    if (profileIsLoaded && profileIsEmpty) {
      removeLocalData('lastSessionEndTimestamp');
    } else if (
      !checkOnLoad.current &&
      profileIsLoaded &&
      !profileIsEmpty &&
      clientInactivityTimeout > 0 &&
      !isAdmin
    ) {
      checkOnLoad.current = true;

      const lastSessionEndTimestamp = getLocalData('lastSessionEndTimestamp');
      const now = Date.now();
      const diff = now - Number(lastSessionEndTimestamp);
      const sessionDiffInSeconds = moment
        .duration(diff, 'milliseconds')
        .asSeconds();

      let heartbeatInterval;
      if (sessionDiffInSeconds >= clientInactivityTimeout) {
        dispatch(
          logout({ force: true, action: 'session-inactivity-timed-out' }),
        );
        history.replace('/');
      } else if (isIOS()) {
        heartbeatInterval = setInterval(() => {
          setLocalData('lastSessionEndTimestamp', Date.now());
        }, 10000);
      }

      const cleanupFn = () => {
        if (profileIsLoaded && !profileIsEmpty) {
          setLocalData('lastSessionEndTimestamp', Date.now());
        }
      };
      const eventType = isIOS() ? 'pagehide' : 'beforeunload';
      window.addEventListener(eventType, cleanupFn);
      return () => {
        window.removeEventListener(eventType, cleanupFn);

        if (heartbeatInterval) {
          clearInterval(heartbeatInterval);
        }
      };
    }
    return () => {};
  }, [profileIsLoaded, profileIsEmpty]);
};

export default useSessionTimeout;
