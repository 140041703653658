import { withScope, captureMessage, captureException } from '@sentry/react';
import { isEmbedded, isWebView } from 'utils/embedded';

export const apiCatch = error => {
  captureMessage(`API Error | ${error}`);
};

export const trackCatch = error => {
  captureMessage(`Track Error | ${error}`);
};

export const logError = (error, context = {}) => {
  withScope(scope => {
    scope.setExtras({
      ...context,
      isEmbedded: isEmbedded(),
      isWebView: isWebView(),
    });
    captureException(error);
  });
};
