/**
 *
 * RichTextDescription
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import ReadMoreLess from 'components/ReadMoreLess';
import Grid from '@material-ui/core/Grid';
import _isEmpty from 'lodash/isEmpty';
import _isUndefined from 'lodash/isUndefined';
import _get from 'lodash/get';
import { isJson } from 'utils/stringUtils';
import { withRouter } from 'react-router-dom';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { getEntryDisplay } from './utils';

const styles = () => ({
  root: {
    '& video': {
      width: '100%',
    },
  },
});

function RichTextDescription(props) {
  const {
    data,
    limit,
    redirect,
    section,
    textAlign,
    name,
    contentType,
    hideReadText,
    classes,
    withButton,
    buttonHandler,
    expand,
    blog,
    subtype,
  } = props;
  if (_isEmpty(data)) return null;

  const options = {
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: node => getEntryDisplay(node),
      [BLOCKS.PARAGRAPH]: (node, next) => {
        const nodeHasValue = !_isEmpty(next(node.content));
        const content = `<p>${next(node.content)
          .replace(/(?:\r\n|\r|\n)/g, '<br>')
          .replace(/&lt;sup&gt;/g, '<sup>')
          .replace(/&lt;\/sup&gt;/g, '</sup>')
          .replace(/&lt;sub&gt;/g, '<sub>')
          .replace(/&lt;\/sub&gt;/g, '</sub>')}</p>`;
        const isLink = next(node.content).includes('href');
        const linkContent = `<p>${next(node.content)
          .replace(/&lt;sup&gt;/g, '<sup>')
          .replace(/&lt;\/sup&gt;/g, '</sup>')
          .replace(/&lt;sub&gt;/g, '<sub>')
          .replace(/&lt;\/sub&gt;/g, '</sub>')}</p>`;
        return nodeHasValue
          ? `${isLink ? linkContent : content}</br></br>`
          : null;
      },
      [INLINES.ASSET_HYPERLINK]: node => getEntryDisplay(node),
      [BLOCKS.EMBEDDED_ASSET]: node => getEntryDisplay(node),
      [BLOCKS.EMBEDDED_ENTRY]: node => getEntryDisplay(node),
      [INLINES.HYPERLINK]: (node, next) => {
        const nodeHasValue = !_isEmpty(_get(node, 'content'));
        if (!nodeHasValue) return null;
        const splittedUrl = node.data.uri.split(' ');
        let link;
        let target;
        if (splittedUrl.length > 1) {
          [, link] = splittedUrl[1].split('=');
          [, target] = splittedUrl[2].split('=');
        } else {
          [link] = splittedUrl;
        }

        if (_isUndefined(link)) {
          return false;
        }
        const cleanLink = link.replace(/^"(.*)"$/, '$1');
        const isCMLink =
          cleanLink.startsWith('/') ||
          cleanLink.startsWith('https://crediblemind.com');
        const cleanTarget =
          (target && target.replace('>', '').replace(/^"(.*)"$/, '$1')) ||
          (!isCMLink && '_blank') ||
          undefined;
        if (isCMLink) {
          return `<a href="${cleanLink
            .replace('https://crediblemind.com', '')
            .replace('https://www.crediblemind.com', '')}">${next(
            node.content,
          )}</a>`;
        }
        return `<a href="${cleanLink}" target="${
          !_isUndefined(cleanTarget) ? cleanTarget : ''
        }">${next(node.content)}</a>`;
        // return (
        //   <a
        //     href={cleanLink}
        //     target={cleanTarget}
        //     onClick={() => trackExitEvent(cleanLink)}
        //   >
        //     {next(node.content)}
        //   </a>
        // );
      },
      [INLINES.ASSET_HYPERLINK]: node => getEntryDisplay(node),
      [INLINES.ENTRY_HYPERLINK]: node => getEntryDisplay(node),
    },
  };
  /* eslint-disable */
  const parsedData =
    typeof data === 'object'
      ? documentToHtmlString(data, options)
      : isJson(data)
      ? documentToHtmlString(JSON.parse(data), options)
      : data;
  const moreLessOptions = section !== 'card' && {
    readMoreText: 'Read More',
    readLessText: 'Read Less',
    redirect,
    hideReadText,
  };
  const btnOptions = {
    withButton,
    buttonHandler,
    expand,
  };
  return (
    <Grid item xs={12} className={classes.root}>
      <ReadMoreLess
        className="read-more-content"
        charLimit={limit}
        name={name}
        contentType={contentType}
        blog={blog}
        subtype={subtype}
        textAlign={textAlign}
        {...moreLessOptions}
        {...btnOptions}
      >
        {parsedData}
      </ReadMoreLess>
    </Grid>
  );
}

RichTextDescription.propTypes = {
  data: PropTypes.any,
  limit: PropTypes.number,
  location: PropTypes.object,
  redirect: PropTypes.string,
  section: PropTypes.string,
  name: PropTypes.string,
  contentType: PropTypes.string,
  hideReadText: PropTypes.bool,
  classes: PropTypes.object,
  withButton: PropTypes.bool,
  buttonHandler: PropTypes.func,
  expand: PropTypes.bool,
  blog: PropTypes.bool,
  subtype: PropTypes.string,
};

export default compose(
  withStyles(styles),
  withRouter,
)(RichTextDescription);
