import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

export const formatPracticeContentfulEntry = entry => {
  if (_isEmpty(_get(entry, 'fields'))) return entry;

  return {
    ...entry,
    fields: {
      ...entry.fields,
      type: 'practice',
    },
  };
};

export const formatPracticeContentfulEntries = data => {
  const items = _get(data, 'items');
  if (_isEmpty(items)) return data;

  return {
    ...data,
    items: data.items.map(formatPracticeContentfulEntry),
  };
};
