import { select } from 'redux-saga/effects';
import {
  makeSelectAuth,
  makeSelectAnonymousId,
} from 'containers/Auth/selectors';
import { getLocalData } from 'utils/localDataStore';

export function* getUserId() {
  const auth = yield select(makeSelectAuth());
  const sessionAnonymousId = yield select(makeSelectAnonymousId());

  return auth.uid || (getLocalData('userIdTemp') || sessionAnonymousId);
}
