/*
 *
 * Resources reducer
 *
 */

import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import {
  processing,
  algoliaProcessing,
  algoliaNextPageProcessing,
  algoliaTopicsProcessing,
  getSavedResourcesResult,
  getAlgoliaResourcesResult,
  getAlgoliaTopicFacets,
  getAlgoliaTopicFacetsResult,
  saveResourceResult,
  rateResourceResult,
  reviewResourceResult,
  getResourceReview,
  getResourceReviewResult,
  voteHelpfulResource,
  voteHelpfulResourceResult,
  getHelpfulRatings,
  getHelpfulRatingsResult,
  getRecommendedResources,
  getRecommendedResourcesResult,
  getUserReviewsSuccess,
  getUserHistoryResult,
  fetchingUserReviews,
  submittingFeedback,
  getResourcesProgressSuccess,
  getResourcesProgressError,
  updateResourceProgress,
  getResourcesProgress,
} from './actions';

export const initialState = {
  processing: false,
  algoliaProcessing: false,
  algoliaNextPageProcessing: false,
  algoliaTopicsProcessing: false,
  algoliaTopicsQuery: '',
  savedResources: [],
  algoliaResources: {},
  algoliaFilters: {},
  saveResult: {},
  rateResult: {},
  reviewResult: {},
  helpfulResult: {},
  helpfulRatings: {},
  review: {},
  error: {},
  recommendedResources: [],
  relatedNews: [],
  userReviews: [],
  fetchingUserReviews: false,
  submittingFeedback: false,
  userHistory: [],
  resourcesProgress: {},
  resourcesProgressProcessing: false,
};

const resourcesReducer = handleActions(
  {
    [processing](state, action) {
      return update(state, { processing: { $set: action.payload } });
    },
    [submittingFeedback](state, action) {
      return update(state, { submittingFeedback: { $set: action.payload } });
    },
    [algoliaProcessing](state, action) {
      return update(state, { algoliaProcessing: { $set: action.payload } });
    },
    [algoliaNextPageProcessing](state, action) {
      return update(state, {
        algoliaNextPageProcessing: { $set: action.payload },
      });
    },
    [algoliaTopicsProcessing](state, action) {
      return update(state, {
        algoliaTopicsProcessing: { $set: action.payload },
      });
    },
    [getSavedResourcesResult](state, action) {
      return update(state, { savedResources: { $set: action.payload } });
    },
    [getAlgoliaResourcesResult](state, action) {
      const { page, data, filters, error } = action.payload;
      if (!error) {
        if (page === 0)
          return update(state, {
            algoliaResources: { $set: data },
            algoliaFilters: { $set: filters },
            algoliaProcessing: { $set: false },
          });

        return update(state, {
          algoliaResources: {
            hits: { $push: data.hits },
          },
          algoliaNextPageProcessing: { $set: false },
        });
      }

      return update(state, {
        algoliaResources: { $set: {} },
        algoliaFilters: { $set: {} },
        algoliaProcessing: { $set: false },
      });
    },
    [getAlgoliaTopicFacets](state, action) {
      return update(state, {
        algoliaTopicsQuery: { $set: action.payload.query },
      });
    },
    [getAlgoliaTopicFacetsResult](state, action) {
      return update(state, {
        algoliaFilters: {
          allTopicsFilterOptions: { $set: action.payload },
        },
      });
    },
    [saveResourceResult](state, action) {
      return update(state, { saveResult: { $set: action.payload } });
    },
    [rateResourceResult](state, action) {
      return update(state, {
        rateResult: { $set: action.payload },
        review: { $set: action.payload.review },
      });
    },
    [reviewResourceResult](state, action) {
      return update(state, {
        reviewResult: { $set: action.payload },
        review: { $set: action.payload.review },
      });
    },
    [getResourceReview](state) {
      return update(state, { review: { $set: {} } });
    },
    [getResourceReviewResult](state, action) {
      return update(state, { review: { $set: action.payload } });
    },
    [voteHelpfulResource](state) {
      return update(state, { helpfulResult: { $set: {} } });
    },
    [voteHelpfulResourceResult](state, action) {
      return update(state, { helpfulResult: { $set: action.payload } });
    },
    [getHelpfulRatings](state, action) {
      if (!action.payload.first) return state;

      return update(state, { helpfulRatings: { $set: {} } });
    },
    [getHelpfulRatingsResult](state, action) {
      return update(state, { helpfulRatings: { $set: action.payload } });
    },
    [getRecommendedResources](state) {
      return update(state, {
        recommendedResources: { $set: [] },
        relatedNews: { $set: [] },
      });
    },
    [getRecommendedResourcesResult](state, action) {
      return update(state, {
        recommendedResources: { $set: action.payload.hits },
        relatedNews: { $set: action.payload.news },
      });
    },
    [getUserReviewsSuccess](state, action) {
      return update(state, { userReviews: { $set: action.payload } });
    },
    [fetchingUserReviews](state, action) {
      return update(state, { fetchingUserReviews: { $set: action.payload } });
    },
    [getUserHistoryResult](state, action) {
      return update(state, { userHistory: { $set: action.payload } });
    },
    [getResourcesProgress](state) {
      return update(state, {
        resourcesProgressProcessing: { $set: true },
        resourcesProgress: { $set: {} },
        resourcesProgressError: { $set: null },
      });
    },
    [getResourcesProgressSuccess](state, action) {
      return update(state, {
        resourcesProgress: { $set: action.payload },
        resourcesProgressProcessing: { $set: false },
      });
    },
    [getResourcesProgressError](state) {
      return update(state, {
        resourcesProgress: { $set: {} },
        resourcesProgressProcessing: { $set: false },
      });
    },
    [updateResourceProgress](state, action) {
      const { resourceId, ...rest } = action.payload;
      return update(state, {
        resourcesProgress: {
          [resourceId]: { $set: rest },
        },
      });
    },
  },
  initialState,
);

export default resourcesReducer;
