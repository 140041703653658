import React from 'react';

export const ListenIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <g clipPath="url(#clip0_24325_69659)">
      <path
        d="M2.26179 10.6234V10.2234H1.86179H1.40706C0.87575 10.2234 0.442847 9.79045 0.442847 9.25915V6.43979C0.442847 4.99112 1.00617 3.63117 2.0305 2.60679C3.05488 1.58245 4.41482 1.01914 5.86348 1.01914C7.31217 1.01914 8.67209 1.58245 9.69647 2.6068C10.7208 3.63119 11.2841 4.99113 11.2841 6.43979V9.25915C11.2841 9.79045 10.8512 10.2234 10.3199 10.2234H9.86518H9.46518V10.6234C9.46518 11.1547 9.03227 11.5876 8.50097 11.5876C7.96962 11.5876 7.53676 11.1547 7.53676 10.6234V6.98547C7.53676 6.45413 7.96962 6.02125 8.50097 6.02125C9.03227 6.02125 9.46518 6.45415 9.46518 6.98547V7.38547H9.86518H10.3199C10.4326 7.38547 10.5404 7.40502 10.641 7.44067L11.1747 7.62984V7.06366V6.43979C11.1747 3.51083 8.79243 1.12861 5.86348 1.12861C2.93454 1.12861 0.55232 3.51083 0.55232 6.43979V7.06366V7.62987L1.08598 7.44066C1.18652 7.40502 1.29437 7.38547 1.40706 7.38547H1.86179H2.26179V6.98547C2.26179 6.45414 2.6947 6.02125 3.226 6.02125C3.75732 6.02125 4.19021 6.45414 4.19021 6.98547V10.6234C4.19021 11.1547 3.75732 11.5876 3.226 11.5876C2.6947 11.5876 2.26179 11.1547 2.26179 10.6234ZM2.26179 7.89494V7.49494H1.86179H1.40706C0.935395 7.49494 0.55232 7.87803 0.55232 8.34968V9.25915C0.55232 9.7308 0.935395 10.1139 1.40706 10.1139H1.86179H2.26179V9.71389V7.89494ZM9.46518 9.71389V10.1139H9.86518H10.3199C10.7916 10.1139 11.1747 9.7308 11.1747 9.25915V8.34968C11.1747 7.87803 10.7916 7.49494 10.3199 7.49494H9.86518H9.46518V7.89494V9.71389ZM3.226 6.13073C2.75434 6.13073 2.37127 6.51382 2.37127 6.98547V10.6234C2.37127 11.095 2.75434 11.4781 3.226 11.4781C3.69767 11.4781 4.08074 11.095 4.08074 10.6234V6.98547C4.08074 6.51382 3.69767 6.13073 3.226 6.13073ZM8.50097 11.4781C8.97262 11.4781 9.35571 11.095 9.35571 10.6234V6.98547C9.35571 6.51382 8.97262 6.13073 8.50097 6.13073C8.02932 6.13073 7.64623 6.51382 7.64623 6.98547V10.6234C7.64623 11.095 8.02932 11.4781 8.50097 11.4781Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.8"
      />
    </g>
    <defs>
      <clipPath id="clip0_24325_69659">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
