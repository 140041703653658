import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import _classNames from 'classnames';
import useZoomLevel, { ZOOM_THRESHOLD } from 'components/Hooks/useZoomLevel';

const useStyles = makeStyles({
  root: {
    position: 'sticky',
    top: 0,
    zIndex: 999,
    background: 'white',
    boxShadow: '0 0 7px rgba(0,0,0,.1)!important',
    '-moz-box-shadow': '0 0 7px rgba(0,0,0,.1)!important',
    '-webkit-box-shadow': '0 0 7px rgba(0,0,0,.1)!important',
    transition: 'all .4s ease-in-out',
  },
  zoomed: {
    position: 'static',
    '& img, svg': {
      scale: '0.7',
    },
  },
});

function StickyHeader({ children, isClientAdmin }) {
  const classes = useStyles();
  const { zoom } = useZoomLevel();

  return (
    <header
      className={_classNames(classes.root, {
        [classes.zoomed]: zoom > ZOOM_THRESHOLD,
      })}
      id={isClientAdmin ? 'client-admin-header' : 'app-header'}
    >
      {children}
    </header>
  );
}

export default StickyHeader;
