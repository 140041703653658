import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import axios from 'axios';
import { makeSelectAuth, makeSelectProfile } from 'containers/Auth/selectors';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import Config from 'utils/getEnvConfig';
import { getMFAConfig } from 'utils/mfaConfig';
import { set2FACompleted } from 'containers/Auth/actions';
import { isCurrentPath } from 'utils/history';

const BASE_URL = Config['2FA'].URL;

const createAxiosInstance = async auth => {
  const token = _get(auth, 'stsTokenManager.accessToken');

  if (!token) {
    throw new Error('No authentication token found');
  }

  return axios.create({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const use2FACheck = (redirectPath = '/') => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [lockoutInfo, setLockoutInfo] = useState(() => {
    const storedLockoutInfo = sessionStorage.getItem('2faLockoutInfo');
    return storedLockoutInfo ? JSON.parse(storedLockoutInfo) : null;
  });
  const auth = useSelector(makeSelectAuth());
  const profile = useSelector(makeSelectProfile());
  const clientDetails = useSelector(makeSelectClientDetails());
  const history = useHistory();
  const dispatch = useDispatch();
  const hasChecked = useRef(false);
  const isInitialSignupSession = useRef(false);

  const isMFARequired = _get(
    clientDetails,
    'metadata.authentication.mfaRequired',
    false,
  );
  const isAuthLoaded = auth.isLoaded && !auth.isEmpty;
  const authToken = _get(auth, 'stsTokenManager.accessToken');
  const isEmailVerified = _get(auth, 'emailVerified');
  const isPasswordProvider = auth.providerData?.[0]?.providerId === 'password';
  const isSignUp =
    Date.now() - auth.createdAt < 5 * 60 * 1000 &&
    !_get(profile, 'signUpCompleted');

  useEffect(() => {
    // Track if this is the initial signup session
    if (isSignUp && !isInitialSignupSession.current) {
      isInitialSignupSession.current = true;
      sessionStorage.setItem('isInitialSignupSession', 'true');
    }
  }, [isSignUp]);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    const check2FARequirement = async () => {
      if (hasChecked.current && isOnline) {
        return;
      }

      try {
        setIsLoading(true);
        setError(null);

        if (!isOnline) {
          // When offline, assume 2FA is required if it was previously required
          if (!isCurrentPath('/2fa')) {
            history.push('/2fa');
          }
          setIsLoading(false);
          return;
        }

        const api = await createAxiosInstance(auth);
        const response = await api.post(`${BASE_URL}/check`, {
          config: getMFAConfig(clientDetails),
          clientId: clientDetails?.shortName,
        });

        if (!response.data.required) {
          dispatch(set2FACompleted(true));
          if (isCurrentPath('/2fa')) {
            history.push(redirectPath);
          }
        } else {
          if (response.data.locked) {
            const newLockoutInfo = {
              locked: true,
              remainingTime: response?.data?.remainingTime,
              attempts: response?.data?.attempts,
              maxAttempts: response?.data?.maxAttempts,
            };
            setLockoutInfo(newLockoutInfo);
            sessionStorage.setItem(
              '2faLockoutInfo',
              JSON.stringify(newLockoutInfo),
            );
          } else {
            setLockoutInfo(null);
            sessionStorage.removeItem('2faLockoutInfo');
          }
          if (!isCurrentPath('/2fa')) {
            history.push('/2fa');
          }
        }
        hasChecked.current = true;
      } catch (apiError) {
        setError(apiError);
        if (apiError.message === 'No authentication token found') {
          history.push('/login');
        } else if (!isOnline && !isCurrentPath('/2fa')) {
          // If offline and error occurs, redirect to 2FA
          history.push('/2fa');
        }
      } finally {
        setIsLoading(false);
      }
    };

    // Reset check when auth token changes
    if (!authToken) {
      hasChecked.current = false;
      sessionStorage.removeItem('2faLockoutInfo');
      setLockoutInfo(null);
    }

    // Only run the check if:
    // 1. MFA is required
    // 2. Auth is loaded
    // 3. It's a password provider
    // 4. Not in signup flow
    // 5. Not the initial signup session
    if (
      isMFARequired &&
      isAuthLoaded &&
      isPasswordProvider &&
      !isSignUp &&
      !sessionStorage.getItem('isInitialSignupSession') &&
      !hasChecked.current &&
      isEmailVerified
    ) {
      check2FARequirement();
    } else {
      setIsLoading(false);
    }
  }, [
    authToken, // Only re-run when auth token changes
    clientDetails,
    isMFARequired,
    isAuthLoaded,
    isPasswordProvider,
    isSignUp,
    history,
    redirectPath,
    isEmailVerified,
  ]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    // Update remaining time
    if (lockoutInfo?.locked && lockoutInfo.remainingTime > 0) {
      const timer = setInterval(() => {
        const updatedLockoutInfo = {
          ...lockoutInfo,
          remainingTime: lockoutInfo.remainingTime - 1,
        };

        if (updatedLockoutInfo.remainingTime <= 0) {
          setLockoutInfo(null);
          sessionStorage.removeItem('2faLockoutInfo');
        } else {
          setLockoutInfo(updatedLockoutInfo);
          sessionStorage.setItem(
            '2faLockoutInfo',
            JSON.stringify(updatedLockoutInfo),
          );
        }
      }, 60000); // Update every minute

      return () => clearInterval(timer);
    }
  }, [lockoutInfo]);

  return {
    isLoading,
    error,
    lockoutInfo,
    isOnline,
  };
};

export default use2FACheck;
