import React from 'react';

export const ReadIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    stroke="currentColor"
  >
    <path
      d="M0.533936 5.94141C0.533936 5.94141 2.53394 1.94141 6.03395 1.94141C9.53395 1.94141 11.534 5.94141 11.534 5.94141C11.534 5.94141 9.53395 9.94142 6.03395 9.94142C2.53394 9.94142 0.533936 5.94141 0.533936 5.94141Z"
      strokeWidth="0.8"
    />
    <path
      d="M6.03407 7.44142C6.86247 7.44142 7.53407 6.76982 7.53407 5.94141C7.53407 5.11298 6.86247 4.44141 6.03407 4.44141C5.20562 4.44141 4.53406 5.11298 4.53406 5.94141C4.53406 6.76982 5.20562 7.44142 6.03407 7.44142Z"
      strokeWidth="0.8"
    />
  </svg>
);
