import React from 'react';
import PropTypes from 'prop-types';
import _isNaN from 'lodash/isNaN';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Mixpanel, { injectAssessmentResultsABData } from 'utils/mixpanelService';
import { createStructuredSelector } from 'reselect';
import { makeSelectAuth } from 'containers/Auth/selectors';
import { incrementNPSPlayCount } from 'containers/Main/actions';
import { connect } from 'react-redux';
import ClassNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LazyLoad from 'react-lazyload';
import { isSafari } from 'utils/stringUtils';
import { isEmbedded } from '../../utils/embedded';
import PlayIcon from './images/play.svg';
import PauseIcon from './images/pause.svg';

const styles = theme => ({
  root: {
    position: 'relative',
  },
  pagePlayer: {
    '& >img': {
      height: 'auto',
      maxHeight: 280,
    },
  },
  thumbnail: {
    maxWidth: '100%',
    objectFit: 'contain',
    height: 120,
    [theme.breakpoints.down('750')]: {
      marginTop: 30,
    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      margin: '0 auto',
    },
  },
  smallThumbnail: {
    maxWidth: '100%',
    objectFit: 'contain',
    height: 100,
  },
  audioIcon: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  seekBar: {
    height: 5,
    background: '#E9ECED',
  },
  fill: {
    height: 5,
    background: '#01619B',
    position: 'absolute',
    bottom: 0,
  },
  grayscale: {
    filter: 'grayscale(1)',
  },
});

class Player extends React.PureComponent {
  constructor(props) {
    super(props);
    this.audioEl = React.createRef();
    this.initialTime = this.props.initialTime || 0;
    this.incrementedNPSPlayCount = false;
    this.audioProgressPercentage = 0;
    this.state = {
      playing: false,
      progress: 0,
    };
    this.playStartTime = null;
  }

  componentDidMount() {
    const { autoPlayNext, setAutoPlayNext } = this.props;
    if (autoPlayNext) {
      this.audioEl.current.play();
      setAutoPlayNext(false);
    }

    this.audioEl.current.addEventListener('timeupdate', e => {
      const { currentTime, duration } = e.target;
      const progressTime = (currentTime / duration) * 100;
      this.audioProgressPercentage = progressTime;

      this.setState({
        progress: progressTime,
      });

      if (this.props.onProgressUpdate) {
        this.props.onProgressUpdate(currentTime, progressTime);
      }

      if (currentTime > 60 && !this.incrementedNPSPlayCount) {
        this.props.incrementNPSPlayCount();
        this.incrementedNPSPlayCount = true;
      }
    });
  }

  componentDidUpdate(prevProps) {
    const audio = this.audioEl?.current;

    if (this.props.audio !== prevProps.audio) {
      if (audio) {
        audio.pause();
        this.incrementedNPSPlayCount = false;
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ progress: 0, playing: false });
        audio.load();
      }

      if (this.props.autoPlayNext) {
        audio.play();
        this.props.setAutoPlayNext(false);
      }
    }

    if (this.props.autoPlayNext) {
      audio.pause();
      audio.load();
      audio.play();
      this.props.setAutoPlayNext(false);
    }
  }

  componentWillUnmount() {
    this.audioEl.current.removeEventListener('timeupdate', () => {});

    if (this.playStartTime) {
      const eventData = {
        'resource progress': `${Math.round(this.audioProgressPercentage)}%`,
        'play time': (Date.now() - this.playStartTime) / 1000,
      };
      this.trackEvent('Resource Card - Stopped', eventData);
    }
  }

  getTime = time => {
    if (!_isNaN(time)) {
      return `${Math.floor(time / 60)}:${`0${Math.floor(time % 60)}`.slice(
        -2,
      )}`;
    }
    return 0;
  };

  onEnded = () => {
    const { handleAutoPlay } = this.props;

    if (handleAutoPlay) handleAutoPlay();

    const eventData = {
      'resource progress': '100%',
      'play time': (Date.now() - this.playStartTime) / 1000,
    };
    this.trackEvent('Resource Card - Completed', eventData);
    this.playStartTime = null;
  };

  togglePlayPause = e => {
    e.stopPropagation();
    e.preventDefault();
    this.setState(
      prevState => ({
        playing: !prevState.playing,
      }),
      () => {
        if (this.state.playing) {
          this.audioEl.current.currentTime =
            this.audioEl.current.currentTime || this.initialTime;
          this.playStartTime = Date.now();

          this.audioEl.current.play();

          this.trackEvent(
            this.audioEl.current.currentTime > 0
              ? 'Resource Card - Play - Resume'
              : 'Resource Card - Play',
          );
        } else {
          this.audioEl.current.pause();
          const percentage = Math.round(this.audioProgressPercentage);
          const eventData = {
            'resource progress': `${percentage}%`,
            'play time': (Date.now() - this.playStartTime) / 1000,
          };

          this.trackEvent('Resource Card - Pause', eventData);
          if (this.state.progress > 0 && this.props.section === 'page') {
            this.props.toggleShowFloatingHelpful(true, true);
          }
          if (!this.audioEl.current.ended) {
            this.playStartTime = null;
          }
        }
      },
    );
  };

  trackEvent = (event, eventData) => {
    const {
      location,
      data,
      trackingProps,
      shouldABTest,
      isVariantB,
      listTitle,
    } = this.props;
    const pageName = location.pathname.split('/')[1];
    const finalPageName = pageName ? `${pageName}Page` : 'homePage';
    Mixpanel.track(
      event,
      injectAssessmentResultsABData(
        {
          name: data?.title,
          type: 'podcast',
          page: finalPageName,
          entryId: data?.id,
          list: listTitle,
          ...(eventData || {}),
          ...trackingProps,
        },
        shouldABTest,
        isVariantB,
      ),
    );
  };

  render() {
    const {
      grayscale,
      thumbnail,
      audio,
      classes,
      section,
      title,
      isSmallCard,
      customClasses,
    } = this.props;
    const { playing, progress } = this.state;

    const thumbnailImage =
      isEmbedded() && isSafari() ? (
        <LazyLoad height={100} offset={[100, 0]}>
          <img
            src={thumbnail}
            alt=""
            className={ClassNames({
              [classes.grayscale]: grayscale,
              [classes.thumbnail]: !isSmallCard,
              [classes.smallThumbnail]: isSmallCard,
            })}
          />
        </LazyLoad>
      ) : (
        <LazyLoadImage
          src={thumbnail}
          alt=""
          className={ClassNames({
            [classes.grayscale]: grayscale,
            [classes.thumbnail]: !isSmallCard,
            [classes.smallThumbnail]: isSmallCard,
          })}
        />
      );
    const actionIcon =
      isEmbedded() && isSafari() ? (
        <LazyLoad height={100} offset={[100, 0]}>
          <img src={playing ? PauseIcon : PlayIcon} alt="" />
        </LazyLoad>
      ) : (
        <LazyLoadImage src={playing ? PauseIcon : PlayIcon} alt="" />
      );
    return (
      <div
        className={ClassNames(
          classes.root,
          customClasses && customClasses.playerRoot,
          section === 'page' ? classes.pagePlayer : undefined,
        )}
      >
        <audio ref={this.audioEl} onEnded={this.onEnded}>
          <source src={audio} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
        {thumbnailImage}
        <IconButton
          className={classes.audioIcon}
          onClick={this.togglePlayPause}
          aria-label={`Play/Pause ${title}`}
        >
          {actionIcon}
        </IconButton>
        <div
          className={ClassNames(
            classes.seekBar,
            customClasses && customClasses.playerSeekBar,
          )}
        >
          <div className={classes.fill} style={{ width: `${progress}%` }} />
        </div>
      </div>
    );
  }
}

Player.propTypes = {
  classes: PropTypes.object,
  thumbnail: PropTypes.string,
  audio: PropTypes.string,
  location: PropTypes.object,
  data: PropTypes.object,
  section: PropTypes.string,
  isSmallCard: PropTypes.bool,
  grayscale: PropTypes.bool,
  incrementNPSPlayCount: PropTypes.func,
  toggleShowFloatingHelpful: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  auth: makeSelectAuth(),
});

function mapDispatchToProps(dispatch) {
  return {
    incrementNPSPlayCount: () => dispatch(incrementNPSPlayCount()),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(
  withRouter,
  withConnect,
  withStyles(styles),
)(Player);
