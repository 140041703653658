import React, { useEffect, useReducer, useRef } from 'react';
import update from 'immutability-helper';
import _capitalize from 'lodash/capitalize';
import _get from 'lodash/get';
import _has from 'lodash/has';
import { createStructuredSelector } from 'reselect';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFirebase } from 'react-redux-firebase';
import {
  makeSelectAnonymousId,
  makeSelectAuth,
  makeSelectProfile,
} from 'containers/Auth/selectors';
import {
  makeSelectClientDetails,
  makeSelectNPSVisibility,
} from 'containers/Main/selectors';
import { setNPSVisibility } from 'containers/Main/actions';
import useSiteCopySelector from 'components/useSiteCopySelector';
import useHideNPSSurvey from 'components/Hooks/useHideNPSSurvey';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { getLocalData, setLocalData } from 'utils/localDataStore';
import Mixpanel from 'utils/mixpanelService';
import { getReportingSamlData } from 'utils/stringUtils';
import useNPSPreConditions from './useNPSPreConditions';
import useNPSTriggers from './useNPSTriggers';
import NPS from './NPS';
import Survey from './Survey';
import { DEFAULT_SURVEY } from './constants';

const ON_RADIO_SELECT = 'ON_RADIO_SELECT';
const ON_CHECKBOX_SELECT = 'ON_CHECKBOX_SELECT';
const ON_TEXT_CHANGE = 'ON_TEXT_CHANGE';
const ON_NPS_CHANGE = 'ON_NPS_CHANGE';
const ON_NEXT = 'ON_NEXT';
const RESET = 'RESET';

const INITIAL_STATE = {
  step: 0,
  answers: {},
  readableAnswers: {},
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case ON_RADIO_SELECT:
      return {
        answers: update(state.answers, {
          $merge: { [payload.name]: payload.value },
        }),
        readableAnswers: update(state.readableAnswers, {
          $merge: { [payload.name]: payload.readableValue },
        }),
        step: state.step + payload.skip,
      };
    case ON_CHECKBOX_SELECT: {
      let action;

      let checkboxAnswers = state.answers[payload.name];
      if (!checkboxAnswers) checkboxAnswers = [];
      let newCheckboxAnswers;
      if (checkboxAnswers.includes(payload.value)) {
        action = 'remove';

        const index = checkboxAnswers.indexOf(payload.value);
        newCheckboxAnswers = update(checkboxAnswers, {
          $splice: [[index, 1]],
        });
      } else {
        action = 'add';

        newCheckboxAnswers = update(checkboxAnswers, {
          $push: [payload.value],
        });
      }

      let checkboxReadableAnswers = state.readableAnswers[payload.name];
      if (!checkboxReadableAnswers) checkboxReadableAnswers = [];
      let newCheckboxReadableAnswers;
      if (checkboxReadableAnswers.includes(payload.readableValue)) {
        const index = checkboxReadableAnswers.indexOf(payload.readableValue);
        newCheckboxReadableAnswers = update(checkboxReadableAnswers, {
          $splice: [[index, 1]],
        });
      } else {
        newCheckboxReadableAnswers = update(checkboxReadableAnswers, {
          $push: [payload.readableValue],
        });
      }

      if (action === 'remove' && payload.other) {
        return {
          ...state,
          answers: update(state.answers, {
            $merge: { [payload.name]: newCheckboxAnswers },
            $unset: [`${payload.name}-other`],
          }),
          readableAnswers: update(state.readableAnswers, {
            $merge: { [payload.name]: newCheckboxReadableAnswers },
            $unset: [`${payload.name}-other`],
          }),
        };
      }

      return {
        ...state,
        answers: update(state.answers, {
          $merge: { [payload.name]: newCheckboxAnswers },
        }),
        readableAnswers: update(state.readableAnswers, {
          $merge: { [payload.name]: newCheckboxReadableAnswers },
        }),
      };
    }
    case ON_NPS_CHANGE:
      return {
        ...state,
        answers: update(state.answers, {
          $merge: { [payload.name]: payload.value },
        }),
        readableAnswers: update(state.readableAnswers, {
          $merge: { [payload.name]: payload.readableValue },
        }),
      };
    case ON_TEXT_CHANGE:
      return {
        ...state,
        answers: update(state.answers, {
          $merge: { [payload.name]: payload.value },
        }),
        readableAnswers: update(state.readableAnswers, {
          $merge: { [payload.name]: payload.readableValue },
        }),
      };
    case ON_NEXT:
      return {
        ...state,
        step: state.step + _get(payload, 'skip', 1),
      };
    case RESET:
      return INITIAL_STATE;
    default:
      throw new Error();
  }
};
const stateSelector = createStructuredSelector({
  profile: makeSelectProfile(),
  auth: makeSelectAuth(),
  npsVisibility: makeSelectNPSVisibility(),
  clientDetails: makeSelectClientDetails(),
  sessionAnonymousId: makeSelectAnonymousId(),
});

function NPSSurvey() {
  const location = useLocation();
  const dispatch = useDispatch();
  const documentId = useRef();
  const {
    auth,
    clientDetails,
    npsVisibility,
    profile,
    sessionAnonymousId,
  } = useSelector(stateSelector);
  const [state, localDispatch] = useReducer(reducer, INITIAL_STATE);
  const firebase = useFirebase();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const firestore = firebase.firestore();

  useNPSPreConditions();
  useNPSTriggers();

  const [siteCopy] = useSiteCopySelector(['nps']);
  const npsSiteCopy = _get(siteCopy, 'pageCopy', {});
  const npsClient = _get(clientDetails, 'metadata.nps', {});
  const finalNPS = update(npsSiteCopy, {
    $merge: npsClient,
  });

  const survey = _get(finalNPS, 'survey', DEFAULT_SURVEY);
  const npsSurveyWeights = _get(finalNPS, 'npsSurveyWeights', 0.5);

  // const type = useRef('survey');
  const type = useRef(Math.random() < npsSurveyWeights ? 'nps' : 'survey');

  const { answers, readableAnswers, step } = state;

  useEffect(() => {
    if (npsVisibility.visibility) {
      const brand = getLocalData('brand') || 'none';
      const page = location.pathname;

      Mixpanel.track('NPS shown', {
        trigger: npsVisibility.reason,
        brand,
        page,
        type: type.current,
      });
    }
  }, [npsVisibility.visibility]);

  const handleClose = () => {
    dispatch(setNPSVisibility({ visibility: false, reason: 'user-close' }));

    const brand = getLocalData('brand') || 'none';
    const page = location.pathname;

    Mixpanel.track('NPS dismissed', {
      trigger: npsVisibility.reason,
      brand,
      page,
      type: type.current,
    });

    localDispatch({ type: RESET });
  };

  const handleRadioClick = name => (value, readableValue) => {
    let finished = false;
    let i = 1;

    while (!finished) {
      const question = survey[step + i];
      if (_has(question, 'visibleIf')) {
        const composedAnswers = {
          ...answers,
          [name]: value,
        };

        const [match] = question.visibleIf.match(/\[[^+\s,]+\]/g);
        const relatedAnswer =
          composedAnswers[match.substring(1, match.length - 1)];
        const expectedValue = Number(question.visibleIf.split(' = ')[1]);

        if (relatedAnswer !== expectedValue) i += 1;
        else finished = true;
      } else {
        finished = true;
      }
    }

    localDispatch({
      type: ON_RADIO_SELECT,
      payload: { name, readableValue, value, skip: i },
    });
  };

  const handleNPSClick = name => value => {
    const id = !profile.isEmpty
      ? auth.uid
      : getLocalData('userIdTemp') || sessionAnonymousId;

    const brand = getLocalData('brand') || 'none';
    const page = location.pathname;

    const profileSamlData = getReportingSamlData(profile);

    if (documentId.current) {
      firestore
        .collection('nps')
        .doc(documentId.current)
        .update({
          timestamp: Date.now(),
          answers: {
            [name]: value,
          },
          readableAnswers: {
            [name]: value,
          },
          brand,
          page,
          trigger: npsVisibility.reason,
          id,
          type: type.current,
          ...profileSamlData,
        });
    } else {
      firestore
        .collection('nps')
        .add({
          timestamp: Date.now(),
          answers: {
            [name]: value,
          },
          readableAnswers: {
            [name]: value,
          },
          brand,
          page,
          trigger: npsVisibility.reason,
          id,
          type: type.current,
          ...profileSamlData,
        })
        .then(doc => {
          documentId.current = doc.id;
        });
    }

    if (!profile.isEmpty)
      firebase.updateProfile({
        npsDate: Date.now(),
        npsPlayCount: 0,
        npsShareCount: 0,
      });
    else {
      setLocalData('npsDate', Date.now());
      setLocalData('npsPlayCount', 0);
      setLocalData('npsShareCount', 0);
    }

    const reportedAnswers = {
      npsScale: value,
    };

    Mixpanel.track('NPS rating clicked', {
      trigger: npsVisibility.reason,
      brand,
      page,
      type: type.current,
      ...reportedAnswers,
    });

    localDispatch({
      type: ON_NPS_CHANGE,
      payload: { name, readableValue: value, value },
    });
  };

  const handleCheckboxClick = name => (value, readableValue, other = false) =>
    localDispatch({
      type: ON_CHECKBOX_SELECT,
      payload: { name, readableValue, value, other },
    });

  const handleTextChange = name => e =>
    localDispatch({
      type: ON_TEXT_CHANGE,
      payload: { name, value: e.target.value, readableValue: e.target.value },
    });

  const handleNext = () => {
    let finished = false;
    let i = 1;

    while (!finished) {
      const question = survey[step + i];
      if (_has(question, 'visibleIf')) {
        const [match] = question.visibleIf.match(/\[[^+\s,]+\]/g);
        const relatedAnswer = answers[match.substring(1, match.length - 1)];
        const expectedValue = Number(question.visibleIf.split(' = ')[1]);

        if (relatedAnswer !== expectedValue) i += 1;
        else finished = true;
      } else {
        finished = true;
      }
    }

    localDispatch({ type: ON_NEXT, payload: { skip: i } });
  };

  const handleSubmit = () => {
    const id = !profile.isEmpty
      ? auth.uid
      : getLocalData('userIdTemp') || sessionAnonymousId;

    const brand = getLocalData('brand') || 'none';
    const page = location.pathname;

    if (type.current === 'nps') {
      firestore
        .collection('nps')
        .doc(documentId.current)
        .update({
          timestamp: Date.now(),
          answers,
          readableAnswers,
        });
    } else {
      firestore.collection('nps').add({
        timestamp: Date.now(),
        answers,
        readableAnswers,
        brand,
        page,
        trigger: npsVisibility.reason,
        id,
        type: type.current,
      });
    }

    if (!profile.isEmpty)
      firebase.updateProfile({
        npsDate: Date.now(),
        npsPlayCount: 0,
        npsShareCount: 0,
      });
    else {
      setLocalData('npsDate', Date.now());
      setLocalData('npsPlayCount', 0);
      setLocalData('npsShareCount', 0);
    }

    const reportedAnswers = {};
    if (type.current === 'nps') {
      reportedAnswers.npsScale = answers.npsScale;
    } else {
      survey
        .filter(question => question.type !== 'text')
        .forEach(question => {
          reportedAnswers[`nps${_capitalize(question.name)}`] =
            readableAnswers[question.name];
        });
    }

    Mixpanel.track('NPS submitted', {
      trigger: npsVisibility.reason,
      brand,
      page,
      type: type.current,
      ...reportedAnswers,
    });

    localDispatch({ type: ON_NEXT });
  };

  const hideNPS = useHideNPSSurvey();

  if (!npsVisibility.visibility || hideNPS) return null;

  if (isMobile && _get(clientDetails, 'metadata.hideMobileNPS')) return null;

  return type.current === 'nps' ? (
    <NPS
      answers={answers}
      handleClose={handleClose}
      handleNPSClick={handleNPSClick}
      handleSubmit={handleSubmit}
      handleTextChange={handleTextChange}
      labels={_get(siteCopy, 'pageCopy.nps')}
      step={step}
    />
  ) : (
    <Survey
      answers={answers}
      handleCheckboxClick={handleCheckboxClick}
      handleClose={handleClose}
      handleNext={handleNext}
      handleRadioClick={handleRadioClick}
      handleSubmit={handleSubmit}
      handleTextChange={handleTextChange}
      labels={_get(siteCopy, 'pageCopy.labels')}
      step={step}
      survey={survey}
    />
  );
}

export default NPSSurvey;
