import { useEffect, useRef } from 'react';

const useFocusTrap = ({ focusableElements, onEscape, shouldIgnoreEvent }) => {
  const tooltipRef = useRef(null);

  const handleFocusTrap = e => {
    if (shouldIgnoreEvent && shouldIgnoreEvent(tooltipRef, e)) {
      return;
    }

    let elements = focusableElements?.map(el => el.current).filter(Boolean);
    if (tooltipRef.current) {
      elements = [
        ...elements,
        ...Array.from(
          tooltipRef.current?.querySelectorAll(
            'button, a, input, [tabindex]:not([tabindex="-1"])',
          ),
        ),
      ];
    }

    if (elements.length === 0) return;

    const currentIndex = elements.indexOf(e.target);
    const firstElement = elements[0];
    const lastElement = elements[elements.length - 1];

    if (e.key === 'Tab') {
      e.preventDefault();

      if (currentIndex === -1) {
        firstElement.focus();
        return;
      }

      if (e.shiftKey) {
        if (e.target === firstElement) {
          lastElement.focus();
          return;
        }
        elements[currentIndex - 1].focus();
      } else {
        if (e.target === lastElement) {
          firstElement.focus();
          return;
        }
        elements[currentIndex + 1].focus();
      }
    }
  };

  const handleEscapeKey = e => {
    if (e.key === 'Escape' && onEscape) {
      onEscape();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKey);
    document.addEventListener('keydown', handleFocusTrap);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
      document.removeEventListener('keydown', handleFocusTrap);
    };
  }, [onEscape]);

  return tooltipRef;
};

export default useFocusTrap;
