/*
 *
 * Assessment reducer
 *
 */
import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import {
  processing,
  answersProcessing,
  getAssessment,
  getAssessmentSuccess,
  getAssessmentFail,
  getAnswers,
  getAnswersByAccessCode,
  getResultsByAdminCode,
  getAnswersSuccess,
  getAnswersFail,
  getAssessmentResult,
  getAssessmentResultSuccess,
  getAssessmentResultFail,
  getAssessmentReport,
  getAssessmentReportResult,
  sendAssessmentResultsByEmail,
  sendAssessmentResultsByEmailResult,
  setTakenInSession,
  moveAnonymousAssessments,
  moveAnonymousAssessmentsResult,
  setSMSDialog,
  setAccessCodeCopied,
  setAnswersSource,
  getSeriesAssessmentResultSuccess,
} from './actions';

export const initialState = {
  processing: false,
  answersProcessing: false,
  data: null,
  error: {},
  answers: null,
  latestAnswersAction: null,
  customAnswers: {},
  answersSource: null,
  answersError: null,
  putAssessmentResult: {},
  sendEmailResult: {},
  assessmentResult: null,
  seriesAssessmentResults: [],
  assessmentResultError: {},
  latestAssessmentResultAction: null,
  assessmentReport: null,
  takenInSession: [],
  moveAnonymousAssessmentsResult: {},
  showSMSDialog: false,
  accessCodeCopied: false,
};
const assessmentReducer = handleActions(
  {
    [processing](state, action) {
      return update(state, { processing: { $set: action.payload } });
    },
    [answersProcessing](state, action) {
      return update(state, { answersProcessing: { $set: action.payload } });
    },
    [getAssessment](state, action) {
      if (_get(state, 'data.items.0.fields.slug') === action.payload)
        return state;

      return update(state, {
        data: { $set: null },
        error: { $set: {} },
        answers: { $set: null },
        assessmentResult: { $set: null },
      }); // reset any error object on fetch/refetch
    },
    [getAssessmentSuccess](state, action) {
      return update(state, { data: { $set: action.payload } });
    },
    [getAssessmentFail](state, action) {
      return update(state, {
        data: { $set: {} },
        error: { $set: action.payload },
      });
    },
    [getAnswers](state) {
      return update(state, {
        answers: { $set: null },
        answersError: { $set: null },
        accessCodeCopied: { $set: false },
      }); // reset any error object on fetch/refetch
    },
    [getAnswersByAccessCode](state) {
      return update(state, {
        answers: { $set: null },
        answersError: { $set: null },
        accessCodeCopied: { $set: false },
      }); // reset any error object on fetch/refetch
    },
    [getAnswersSuccess](state, action) {
      return update(state, {
        answers: { $set: action.payload },
        answersProcessing: { $set: false },
      });
    },
    [getAnswersFail](state, action) {
      return update(state, {
        answers: { $set: {} },
        answersError: { $set: action.payload },
        answersProcessing: { $set: false },
      });
    },
    [getResultsByAdminCode](state) {
      return update(state, {
        assessmentResult: { $set: null },
        assessmentResultError: { $set: {} },
        customAnswers: { $set: {} },
      }); // reset any error object on fetch/refetch
    },
    [getAssessmentResult](state, action) {
      if (JSON.stringify(action) === state.latestAssessmentResultAction) {
        return state;
      }

      return update(state, {
        assessmentResult: { $set: null },
        assessmentResultError: { $set: {} },
      }); // reset any error object on fetch/refetch
    },
    [getAssessmentResultSuccess](state, action) {
      const { customAnswers, actionPayload, ...payload } = action.payload;

      const updateParam = {
        assessmentResult: { $set: payload },
        latestAssessmentResultAction: { $set: actionPayload },
      };
      if (!_isEmpty(customAnswers)) {
        updateParam.customAnswers = {
          $set: customAnswers,
        };
      }
      return update(state, updateParam);
    },
    [getAssessmentResultFail](state, action) {
      return update(state, {
        assessmentResult: { $set: null },
        assessmentResultError: { $set: action?.payload },
      });
    },
    [getAssessmentReport](state) {
      return update(state, { assessmentReport: { $set: null } });
    },
    [getAssessmentReportResult](state, action) {
      return update(state, {
        assessmentReport: { $set: action.payload },
      });
    },
    [sendAssessmentResultsByEmail](state) {
      return update(state, { sendEmailResult: { $set: {} } });
    },
    [sendAssessmentResultsByEmailResult](state, action) {
      return update(state, {
        sendEmailResult: { $set: action.payload },
      });
    },
    [setTakenInSession](state, action) {
      return update(state, {
        takenInSession: { $set: action.payload },
      });
    },
    [moveAnonymousAssessments](state) {
      return update(state, {
        moveAnonymousAssessmentsResult: { $set: null },
      });
    },
    [moveAnonymousAssessmentsResult](state, action) {
      return update(state, {
        moveAnonymousAssessmentsResult: { $set: action.payload },
      });
    },
    [setSMSDialog](state, action) {
      return update(state, {
        showSMSDialog: { $set: action.payload },
      });
    },
    [setAccessCodeCopied](state, action) {
      return update(state, {
        accessCodeCopied: { $set: action.payload },
      });
    },
    [setAnswersSource](state, action) {
      return update(state, {
        answersSource: { $set: action.payload },
      });
    },
    [getSeriesAssessmentResultSuccess](state, action) {
      const { assessmentResult, answers } = action.payload;

      return update(state, {
        assessmentResult: { $set: assessmentResult },
        answers: { $set: answers },
      });
    },
  },
  initialState,
);

export default assessmentReducer;
