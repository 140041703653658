import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _get from 'lodash/get';
import { makeSelectProfile } from 'containers/Auth/selectors';
import { makeSelectClientDetails } from 'containers/Main/selectors';
import { isEmbedded } from 'utils/embedded';
import {
  getLocalData,
  removeLocalData,
  setLocalData,
} from 'utils/localDataStore';
import { isIOS } from 'utils/stringUtils';

/**
 * This hook is used to logout user once current session ends. This hook
 * is enabled by "logoutOnSessionEnd" field.
 */
const useSessionEnd = () => {
  const profile = useSelector(makeSelectProfile());
  const clientDetails = useSelector(makeSelectClientDetails());

  const profileIsLoaded = profile.isLoaded;
  const profileIsEmpty = profile.isEmpty;
  const isAdmin =
    ['admin', 'contentAdmin'].includes(profile.role) && !isEmbedded();

  const logoutOnSessionEnd = _get(clientDetails, 'metadata.logoutOnSessionEnd');

  useEffect(() => {
    if (profileIsLoaded && profileIsEmpty) {
      removeLocalData('cmTabsCount', true);
    } else if (
      profileIsLoaded &&
      !profileIsEmpty &&
      logoutOnSessionEnd &&
      !isAdmin
    ) {
      setLocalData(
        'cmTabsCount',
        Number(localStorage.getItem('cmTabsCount') || 0) + 1,
        {},
        true,
      );

      const cleanupFn = e => {
        const isReload =
          _get(
            e.currentTarget.performance.getEntriesByType('navigation'),
            '0.type',
          ) === 'reload';
        const tabsCount = Number(getLocalData('cmTabsCount', true));
        setLocalData('cmTabsCount', tabsCount - 1, {}, true);

        if (!isReload && tabsCount < 2) {
          indexedDB.deleteDatabase('firebaseLocalStorageDb');
        }
      };
      const eventType = isIOS() ? 'pagehide' : 'beforeunload';
      window.addEventListener(eventType, cleanupFn);
      return () => {
        window.removeEventListener(eventType, cleanupFn);
      };
    }
    return () => {};
  }, [profileIsLoaded, profileIsEmpty, logoutOnSessionEnd, isAdmin]);
};

export default useSessionEnd;
