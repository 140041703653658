import { emitter } from '@marvelapp/react-ab-test';

// A/B Testing configuration
const abTestVariants = {
  'hero-variant': {
    variants: ['variantA', 'variantB', 'variantC', 'variantD'],
    weights: [25, 25, 25, 25],
    calculateActive: false,
  },
  'assessment-results-lists': {
    variants: ['grouped', 'tabbed'],
    weights: [50, 50],
    calculateActive: true,
  },
  'assessment-results-design': {
    variants: ['variantA', 'variantB'],
    weights: [50, 50],
    calculateActive: true,
  },
  'podcast-player': {
    variants: ['A-NoCTA', 'B-WithCTA'],
    weights: [50, 50],
    calculateActive: true,
  },
};

export const initializeABTests = () => {
  Object.entries(abTestVariants).forEach(([testName, config]) => {
    emitter.defineVariants(testName, config.variants, config.weights);
    if (config.calculateActive) {
      emitter.calculateActiveVariant(testName);
    }
  });
};

export const checkIfActiveVariant = (testName, variantName) => {
  try {
    return emitter.getActiveVariant(testName) === variantName;
  } catch (error) {
    console.error(`Error getting A/B test variant for ${testName}:`, error);
    return variantName === abTestVariants[testName].variants[0];
  }
};
