/*
 *
 * Resources constants
 *
 */

export const PROCESSING = 'app/Resources/PROCESSING';
export const ALGOLIA_PROCESSING = 'app/Resources/ALGOLIA_PROCESSING';
export const ALGOLIA_NEXT_PAGE_PROCESSING =
  'app/Resources/ALGOLIA_NEXT_PAGE_PROCESSING';
export const ALGOLIA_TOPICS_PROCESSING =
  'app/Resources/ALGOLIA_TOPICS_PROCESSING';
export const GET_SAVED_RESOURCES = 'app/Resources/GET_SAVED_RESOURCES';
export const GET_SAVED_RESOURCES_RESULT =
  'app/Resources/GET_SAVED_RESOURCES_RESULT';
export const GET_ALGOLIA_RESOURCES = 'app/Resources/GET_ALGOLIA_RESOURCES';
export const GET_ALGOLIA_RESOURCES_RESULT =
  'app/Resources/GET_ALGOLIA_RESOURCES_RESULT';
export const GET_ALGOLIA_TOPIC_FACETS =
  'app/Resources/GET_ALGOLIA_TOPIC_FACETS';
export const GET_ALGOLIA_TOPIC_FACETS_RESULT =
  'app/Resources/GET_ALGOLIA_TOPIC_FACETS_RESULT';
export const SAVE_RESOURCE = 'app/Resources/SAVE_RESOURCE';
export const SAVE_RESOURCE_RESULT = 'app/Resources/SAVE_RESOURCE_RESULT';
export const RATE_RESOURCE = 'app/Resources/RATE_RESOURCE';
export const RATE_RESOURCE_RESULT = 'app/Resources/RATE_RESOURCE_RESULT';
export const REVIEW_RESOURCE = 'app/Resources/REVIEW_RESOURCE';
export const REVIEW_RESOURCE_RESULT = 'app/Resources/REVIEW_RESOURCE_RESULT';
export const GET_RESOURCE_REVIEW = 'app/Resources/GET_RESOURCE_REVIEW';
export const GET_RESOURCE_REVIEW_RESULT =
  'app/Resources/GET_RESOURCE_REVIEW_RESULT';
export const CREATE_LIST = 'app/Resources/CREATE_LIST';
export const CREATE_LIST_RESULT = 'app/Resources/CREATE_LIST_RESULT';
export const GET_LISTS = 'app/Resources/GET_LISTS';
export const GET_LISTS_RESULT = 'app/Resources/GET_LISTS_RESULT';
export const TOGGLE_VISIBILITY_LIST = 'app/Resources/TOGGLE_VISIBILITY_LIST';
export const TOGGLE_VISIBILITY_LIST_SUCCESS =
  'app/Resources/TOGGLE_VISIBILITY_LIST_SUCCESS';
export const TOGGLE_VISIBILITY_LIST_FAIL =
  'app/Resources/TOGGLE_VISIBILITY_LIST_FAIL';
export const GET_EDITORS_LISTS = 'app/Resources/GET_EDITORS_LISTS';
export const GET_EDITORS_LISTS_RESULT =
  'app/Resources/GET_EDITORS_LISTS_RESULT';
export const VOTE_HELPFUL_RESOURCE = 'app/Resources/VOTE_HELPFUL_RESOURCE';
export const VOTE_HELPFUL_RESOURCE_RESULT =
  'app/Resources/VOTE_HELPFUL_RESOURCE_RESULT';
export const GET_HELPFUL_RATINGS = 'app/Resources/GET_HELPFUL_RATINGS';
export const GET_HELPFUL_RATINGS_RESULTS =
  'app/Resources/GET_HELPFUL_RATINGS_RESULTS';
export const GET_RECOMMENDED_RESOURCES =
  'app/Resources/GET_RECOMMENDED_RESOURCES';
export const GET_RECOMMENDED_RESOURCES_RESULT =
  'app/Resources/GET_RECOMMENDED_RESOURCES_RESULT';
export const GET_USER_REVIEWS = 'app/Resources/GET_USER_REVIEWS';
export const GET_USER_REVIEWS_SUCCESS =
  'app/Resources/GET_USER_REVIEWS_SUCCESS';
export const GET_USER_HISTORY = 'app/Resources/GET_USER_HISTORY';
export const GET_USER_HISTORY_RESULT = 'app/Resources/GET_USER_HISTORY_RESULT';
export const FETCHING_USER_REVIEWS = 'app/Resources/FETCHING_USER_REVIEWS';
export const SET_AUDIENCE_RELATIONS = 'app/Resources/SET_AUDIENCE_RELATIONS';
export const SUBMITTING_FEEDBACK = 'app/Resources/SUBMITTING_FEEDBACK';
export const UPDATE_RESOURCE_PROGRESS =
  'app/Resources/UPDATE_RESOURCE_PROGRESS';
export const UPDATE_RESOURCE_PROGRESS_SUCCESS =
  'app/Resources/UPDATE_RESOURCE_PROGRESS_SUCCESS';
export const UPDATE_RESOURCE_PROGRESS_ERROR =
  'app/Resources/UPDATE_RESOURCE_PROGRESS_ERROR';
export const GET_RESOURCE_PROGRESS = 'app/Resources/GET_RESOURCE_PROGRESS';
export const GET_RESOURCE_PROGRESS_SUCCESS =
  'app/Resources/GET_RESOURCE_PROGRESS_SUCCESS';
export const GET_RESOURCE_PROGRESS_ERROR =
  'app/Resources/GET_RESOURCE_PROGRESS_ERROR';
