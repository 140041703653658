/* eslint-disable import/no-cycle */
import {
  deleteCookie,
  getCookie,
  isCookieEnabled,
  setCookie,
} from 'utils/cookieStore';
import { isLocalhost } from 'utils/networkUtils';
import {
  getDomain,
  getSubdomain,
  isLocalStorageAllowed,
  isSessionStorageAllowed,
} from 'utils/stringUtils';
import _isNan from 'lodash/isNaN';
import { isEmbedded } from './embedded';

let defaultEngine;
let sessionEngine;

const DEFAULT_PRECEDENCE = ['cookie', 'localstorage', 'sessionstorage'];
const SESSION_PRECEDENCE = ['localstorage', 'cookie', 'sessionstorage'];

const initEngines = () => {
  if (!sessionEngine) {
    sessionEngine = parseStorageEngine(true);
  }
  if (!defaultEngine) {
    defaultEngine = parseStorageEngine(false);
  }
};

const parseStorageEngine = session => {
  let engine;

  const precedence = session ? SESSION_PRECEDENCE : DEFAULT_PRECEDENCE;

  let i = 0;
  while (i < precedence.length && engine === undefined) {
    if (precedence[i] === 'cookie' && isCookieEnabled()) {
      engine = 'cookie';
    } else if (precedence[i] === 'localstorage' && isLocalStorageAllowed()) {
      engine = 'localstorage';
    } else if (
      precedence[i] === 'sessionstorage' &&
      isSessionStorageAllowed()
    ) {
      engine = 'sessionstorage';
    }

    i += 1;
  }
  return engine;
};

export const getStorageEngine = session =>
  session ? sessionEngine : defaultEngine;

export const getLocalData = (name, session) => {
  initEngines();

  const engine = getStorageEngine(session);

  let item;
  if (engine === 'cookie') {
    item = getCookie(name);
  } else if (engine === 'localstorage') {
    item = localStorage.getItem(name);
  } else if (engine === 'sessionstorage') {
    item = sessionStorage.getItem(name);
  }

  if (name !== 'brand') {
    return item;
  }
  const subdomain = getSubdomain();
  // subdomain value is priority
  return subdomain && subdomain !== 'development' ? subdomain : item;
};

export const setLocalData = (name, value, options = {}, session) => {
  initEngines();

  const engine = getStorageEngine(session);

  if (engine === 'cookie') {
    const domain = getDomain();
    const isNetlify = domain.includes('netlify.app');
    setCookie(name, value, {
      secure: !isLocalhost,
      sameSite: isEmbedded()
        ? 'none'
        : isLocalhost || isNetlify
        ? 'lax'
        : 'strict',
      domain: isNetlify ? window.location.hostname : domain,
      ...options,
    });
  } else if (engine === 'localstorage') {
    localStorage.setItem(name, value);
  } else if (engine === 'sessionstorage') {
    sessionStorage.setItem(name, value);
  }
};

export const removeLocalData = (name, session) => {
  initEngines();

  const engine = getStorageEngine(session);

  if (engine === 'cookie') {
    deleteCookie(name);
  } else if (engine === 'localstorage') {
    localStorage.removeItem(name);
  } else if (engine === 'sessionstorage') {
    sessionStorage.removeItem(name);
  }
};

export const cleanAllCookies = () => {
  const cookies = document.cookie.split(';');

  cookies.forEach(cookie => {
    const [name] = cookie.split('=');
    const trimmedName = name.trim();
    if (trimmedName !== 'brand') {
      deleteCookie(trimmedName);
    }
  });
};

export const isPublicMode = () => getLocalData('mode') === 'public';
export const isCarouselViewList = () =>
  isEmbedded() && getLocalData('list-view') === 'carousel';
export const getListMaxNumCards = () => {
  const numCards = getLocalData('list-view-num-cards');
  const parsedNumber = parseInt(numCards, 10);
  return parsedNumber && !_isNan(parsedNumber) ? parsedNumber : 5;
};
