import { createBrowserHistory } from 'history';
import _get from 'lodash/get';
import Config from 'utils/getEnvConfig';
import { getLocalData } from 'utils/localDataStore';
import { getSubdomain } from 'utils/stringUtils';

export const getBasename = () => {
  const { PATH_PREFIX } = Config;
  const subdomain = getSubdomain() || getLocalData('brand');

  return _get(PATH_PREFIX, subdomain);
};

export const removeBasename = str => {
  const basename = getBasename();
  const formattedBaseName = basename.startsWith('/')
    ? basename.slice(1)
    : basename;

  return str.includes(formattedBaseName)
    ? str.replace(formattedBaseName, '')
    : str;
};

const getBasenameConfig = () => {
  const basename = getBasename();

  if (basename) {
    return {
      basename,
    };
  }

  return {};
};

const customHistory = createBrowserHistory({
  ...getBasenameConfig(),
});

const originalPush = customHistory.push;
const originalReplace = customHistory.replace;

// Check if embedded redirect value is new tab or not
export const isNotNewTabEmbeddedRedirect = () =>
  _get(window, 'xprops.redirect', '') !== 'new_tab';

export const isAssessmentPageTransition = pathname =>
  !pathname.includes('page') && pathname.includes('/assessments/');

// New function to determine if transition is from /assessments/any to /assessments/any/page1
export const isTransitionToPage = (currentPath, newPath) => {
  const basePathRegex = /^\/assessments\/[^/]+(?=\/|$)/;
  const currentPageMatch = currentPath.match(basePathRegex);
  const pathname =
    typeof newPath === 'string' ? newPath : _get(newPath, 'pathname', '');
  const newPageMatch = pathname.match(basePathRegex);

  // Check if both paths are assessment paths and newPath is a continuation of the currentPath
  return (
    currentPageMatch &&
    newPageMatch &&
    currentPath !== newPath &&
    newPath.startsWith(currentPath)
  );
};

customHistory.push = (path = '', state) => {
  if (isNotNewTabEmbeddedRedirect()) {
    if (isTransitionToPage(window.location.pathname, path)) {
      originalReplace.call(customHistory, path, state);
    } else {
      originalPush.call(customHistory, path, state);
    }
  } else {
    window.open(path, '_blank');
  }
};

customHistory.replace = (path, state) => {
  if (isNotNewTabEmbeddedRedirect()) {
    originalReplace.call(customHistory, path, state);
  }
};

const basename = getBasename();
const strippedBasename =
  basename && basename.endsWith('/')
    ? basename.substring(0, basename.length - 1)
    : '';
if (basename && !window.location.pathname.startsWith(strippedBasename)) {
  const currentPathname = window.location.pathname;
  const currentParams = window.location.search;
  customHistory.replace(currentPathname.replace(/\/\//g, '/') + currentParams);
}

export default customHistory;

export const isCurrentPath = path => {
  const normalizedPath = path.startsWith('/') ? path.slice(1) : path;
  const pathBasename = getBasename() || '';
  const currentPath = window.location.pathname;

  // Remove basename from current path if it exists
  const pathWithoutBase =
    pathBasename && currentPath.startsWith(pathBasename)
      ? currentPath.slice(pathBasename.length)
      : currentPath;

  const normalizedCurrentPath = pathWithoutBase.startsWith('/')
    ? pathWithoutBase.slice(1)
    : pathWithoutBase;

  // Handle empty path case
  if (normalizedPath === '') return true;

  // Split paths into segments and find the matching position
  const pathSegments = normalizedPath.split('/');
  const currentSegments = normalizedCurrentPath.split('/');

  // Try to find the path segments in the current path segments
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= currentSegments.length - pathSegments.length; i++) {
    if (
      pathSegments.every((segment, j) => segment === currentSegments[i + j])
    ) {
      return true;
    }
  }

  return false;
};
