import { useRef, useEffect, useState } from 'react';

export const ZOOM_THRESHOLD = 150;

const useZoomLevel = () => {
  const zoomRef = useRef(0);
  const [zoom, setZoom] = useState(0);

  const isMobile = () =>
    /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

  const checkZoomLevel = () => {
    if (isMobile()) {
      zoomRef.current = 0;
      setZoom(0);
      return;
    }

    const zoomLevel = Math.round((window.outerWidth / window.innerWidth) * 100);

    zoomRef.current = zoomLevel;
    setZoom(zoomRef.current);
  };

  useEffect(() => {
    checkZoomLevel();

    const handleResize = () => checkZoomLevel();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { zoom };
};

export default useZoomLevel;
