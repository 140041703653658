import _get from 'lodash/get';
import { getLocalData, setLocalData } from 'utils/localDataStore';
import { getSubdomain } from 'utils/stringUtils';
import { isAuthPath } from 'components/Hooks/useIsAuthPath';

export const pageTypes = {
  redirect: 'Redirect',
  reporting: 'Reporting',
};

const getCustomOnboardingCacheKey = modalName => {
  const brand = getSubdomain() || getLocalData('brand');

  return `${brand}_${modalName}_customOnboardingViewed`;
};

const getCustomOnboardingDismissedCacheKey = () => {
  const brand = getSubdomain() || getLocalData('brand');

  return `${brand}_customOnboardingDismissed`;
};

export const getCustomOnboardingLocalUtils = () => {
  const cacheKey = modalName => getCustomOnboardingCacheKey(modalName);

  const get = modalName => getLocalData(cacheKey(modalName));
  const set = (modalName, value) => setLocalData(cacheKey(modalName), value);
  const getDismissed = () =>
    getLocalData(getCustomOnboardingDismissedCacheKey());
  const setDismissed = () =>
    setLocalData(getCustomOnboardingDismissedCacheKey(), true);

  return {
    get,
    set,
    getDismissed,
    setDismissed,
  };
};

export const shouldSkipPathForOnboarding = pathname =>
  pathname === '/terms' ||
  pathname === '/privacy-policy' ||
  isAuthPath(pathname);

export const getCanDisplayModal = ({ trigger, pathname }) => {
  // don't show modal for terms or privacy policy or auth pages
  if (shouldSkipPathForOnboarding(pathname)) return false;

  const isAllTrigger = trigger?.includes('all');
  if (isAllTrigger) return true;

  const isHomeTrigger = trigger?.includes('home');
  if (isHomeTrigger) return pathname === '/';

  return false;
};

const getOnboardingModalQuestionType = modal => {
  const questionType = modal.optionsLayout;

  const mapping = {
    Checkboxes: 'checkbox',
    Dropdown: 'dropdown',
    Text: 'input',
  };

  return mapping[questionType] || 'dropdown';
};

const getOnboardingModalAttributes = modal => ({
  title: modal.modalTitle,
  type: getOnboardingModalQuestionType(modal),
  subtitle: modal.subtitle,
  options: modal.context?.options,
  placeholder: modal.placeholder,
  noOptionsText: modal.context?.noOptionsText,
  mixpanelFieldName: modal.context?.mixpanelFieldName,
  footer: _get(modal, 'footer.json'),
  trigger: modal.trigger,
  required: modal.required,
  disableMixpanel: modal.context?.disableMixpanel,
  firebaseFieldName: modal.context?.firebaseFieldName,
  buttonLabel: modal.context?.buttonLabel,
});

export const transformOnboardingModalItems = items => {
  // We can have multiple reporting modals, so we need to sort them by ascending order where 1 comes before 2
  const reportingModals = items
    ?.filter(item => item.type === pageTypes.reporting)
    .sort((a, b) => (a.order || 0) - (b.order || 0));
  const redirectModal = items?.find(item => item.type === pageTypes.redirect);

  return {
    ...(reportingModals?.length > 1
      ? { reportingModal: reportingModals.map(getOnboardingModalAttributes) }
      : reportingModals?.length === 1
      ? { reportingModal: getOnboardingModalAttributes(reportingModals[0]) }
      : {}),
    ...(redirectModal
      ? { redirectModal: getOnboardingModalAttributes(redirectModal) }
      : {}),
  };
};
